import { CheckIcon } from "@chakra-ui/icons";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Heading,
  List,
  ListIcon,
  ListItem,
  Text,
  useDisclosure,
  useColorModeValue,
  Grid,
  GridItem,
} from "@chakra-ui/react";
import { loadStripe } from "@stripe/stripe-js";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const API_URL = process.env.REACT_APP_API_URL;

const includedFeatures = [
  "Personalised mentorship",
  "Flexible scheduling",
  "Comprehensive support",
  "Secure and easy payments",
];

export default function Pricing({ project_id, user_id, membership_active, project_status }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const bg = useColorModeValue("white", "olenium-bg");
  const textColor = useColorModeValue("gray.900", "white");
  const [loading, setLoading] = useState(false);

  const handleStartMembership = async () => {
    setLoading(true);
    try {
      const response = await fetch(`${API_URL}/stripe/activate-membership/${project_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken()}`
        },
        body: JSON.stringify({ user_id })
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }

      const responseBody = await response.json();

      const stripe = await stripePromise;

      if (!responseBody.sessionId) {
        throw new Error('Session ID not found');
      }

      const { error } = await stripe.redirectToCheckout({
        sessionId: responseBody.sessionId
      });

      if (error) {
        console.error('Stripe Checkout error:', error);
        setLoading(false);
      }

    } catch (error) {
      console.error('Error:', error);
      setLoading(false);
    }
  };

  const manage_subscription_link = async () => {
    try {
      const response = await fetch(`${API_URL}/stripe/customer-portal/${project_id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${await getIdToken()}`
        }
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message);
      }

      const responseBody = await response.json();

      // Redirect to the Stripe Customer Portal
      window.location.href = responseBody.url;

      return responseBody.url;
    }
    catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Box bg={bg} id={project_id}>
      <Container maxW="7xl" px={{ base: 6, lg: 8 }}>
        <Grid
          templateColumns={{ base: "1fr", lg: "repeat(5, 1fr)" }}
          gap={8}
          mx="auto"
          my={16}
          maxW={{ base: "2xl", lg: "none" }}
          rounded="3xl"
          ring={2}
          ringColor="gray.200"
          py={4}
          px={4}
        >
          <GridItem colSpan={{ base: 1, lg: 3 }}>
            <Box p={{ base: 4, sm: 6 }}>
              <Heading as="h3" fontSize="2xl" fontWeight="bold" color={textColor}>
                Membership Plan
              </Heading>
              <Text mt={4} fontSize="base" lineHeight="7" color={textColor}>
                Unlock your business's potential with Unlocked Expertise. Our platform connects you with seasoned consultants and mentors who bring unparalleled expertise and tailored solutions to help you overcome challenges and achieve your goals.
              </Text>
              <Flex mt={4} align="center" gap={4}>
                <Heading as="h4" fontSize="sm" fontWeight="semibold" color="olenium-blue">
                  What's included
                </Heading>
                <Divider flex={1} orientation="horizontal" bg="gray.100" />
              </Flex>
              <List
                mt={8}
                display="grid"
                gridTemplateColumns={{ base: "1fr", sm: "repeat(2, 1fr)" }}
                gap={4}
                fontSize="sm"
                lineHeight="6"
                color={textColor}
              >
                {includedFeatures.map((feature) => (
                  <ListItem key={feature} display="flex" gap={3}>
                    <ListIcon as={CheckIcon} boxSize={5} color="olenium-blue" />
                    {feature}
                  </ListItem>
                ))}
              </List>
            </Box>
          </GridItem>

          <GridItem
            rounded="2xl"
            bg="oleniumBlack.700"
            py={{ base: 10, lg: 12 }}
            textAlign="center"
            ring={1}
            ringInset
            ringColor="gray.900/5"
            display="flex"
            flexDirection="column"
            justifyContent="center"
            alignItems="center"
            colSpan={{ base: 1, lg: 2 }}
          >
                <Box mx="auto" maxW="xs" px={8}>
                  <Text fontSize="base" fontWeight="semibold" color={textColor}>
                    Monthly Subscription
                  </Text>
                  <Flex mt={2} align="baseline" justify="center" gap={2}>
                    <Text fontSize="5xl" fontWeight="bold" tracking="tight" color={textColor}>
                      £150
                    </Text>
                    <Text fontSize="sm" fontWeight="semibold" leading="6" tracking="wide" color="#A0B100">
                      GBP
                    </Text>
                  </Flex>
                  <Text fontSize="sm" fontWeight="semibold" leading="6" tracking="wide">
                    Excluding VAT
                  </Text>
                  <Button
                    mt={4}
                    w="full"
                    variant="ExperienceBankGreen"
                    size="lg"
                    isDisabled={membership_active}
                    isLoading={loading}
                    onClick={handleStartMembership}
                  >
                    {membership_active ? "Current Plan" : "Start Membership"}
                  </Button>
                  {membership_active && (
                    <Button
                      mt={6}
                      w="full"
                      variant="link"
                      size="sm"
                      onClick={manage_subscription_link}
                    >
                      Manage Subscription
                    </Button>
                  )}
                </Box>
          </GridItem>
        </Grid>
      </Container>
    </Box>
  );
}
