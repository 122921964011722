import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  Spacer,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
import React, { useState } from "react";

import { changePassword } from "../../../../cognito/cognitoAuth";

const ChangePassword = () => {
  const bgHoverLinks = useColorModeValue("oleniumGray.100", "oleniumGray.600");
  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);

  const isPasswordValid = (password) => {
    const regex = /^(?=.*\d)(?=.*[a-zA-Z]).{8,}$/;
    return regex.test(password);
  };

  const handleSubmit = async () => {
    if (newPassword !== confirmNewPassword) {
      setErrorMessage("New passwords do not match.");
      return;
    }

    if (!isPasswordValid(newPassword)) {
      setErrorMessage(
        "Password must be at least 8 characters long, contain letters and numbers, and must not contain spaces, special characters, or emoji.",
      );
      return;
    }

    try {
      await changePassword(oldPassword, newPassword);
      setSuccessMessage("Password changed successfully.");
      setErrorMessage(null);
      setOldPassword("");
      setNewPassword("");
      setConfirmNewPassword("");
    } catch (error) {
      setErrorMessage(error.message);
    }
  };

  return (
    <Flex
      w={"100%"}
      my={5}
      alignSelf={{ lg: "flex-end" }}
      justifySelf={{ lg: "flex-end" }}
    >
      <Box bg={boxColor} w="100%" p={8} rounded="2xl" shadow="xl">
        <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor}>
          Change Password
        </Heading>
        <Divider my={4} />

        {errorMessage && (
          <Alert status="error" mb={4} borderRadius="lg">
            <AlertIcon />
            {errorMessage}
          </Alert>
        )}

        {successMessage && (
          <Alert status="success" mb={4} borderRadius="lg">
            <AlertIcon />
            {successMessage}
          </Alert>
        )}

        <Grid
          templateColumns={{ sm: "repeat(1, 1fr)", lg: "repeat(2, 1fr)" }}
          gap={6}
        >
          <GridItem colSpan={1}>
            <FormControl>
              <FormLabel fontWeight="semibold" fontSize="sm" my={3}>
                Old Password
              </FormLabel>
              <Input
                type="password"
                borderRadius="lg"
                fontSize="sm"
                value={oldPassword}
                onChange={(e) => setOldPassword(e.target.value)}
              />
              <FormLabel fontWeight="semibold" fontSize="sm" my={3}>
                New password
              </FormLabel>
              <Input
                type="password"
                borderRadius="lg"
                fontSize="sm"
                value={newPassword}
                onChange={(e) => setNewPassword(e.target.value)}
              />
              <FormLabel fontWeight="semibold" fontSize="sm" my={3}>
                Confirm new password
              </FormLabel>
              <Input
                type="password"
                borderRadius="lg"
                fontSize="sm"
                value={confirmNewPassword}
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1} mt={{ sm: 4, lg: 8 }} mb={{ sm: 4, lg: 0 }}>
            <Text fontSize="sm" color={textColor}>
              Your password must be at least 8 characters long, contain letters
              and numbers, and must not contain spaces, special characters, or
              emoji.
            </Text>
          </GridItem>
        </Grid>
        <Flex
          direction="row"
          align="center"
          justify="space-between"
          marginTop={2}
        >
          <Spacer />
          <Button variant="ExperienceBankGreen" size="sm" onClick={handleSubmit}>
            Save Changes
          </Button>
        </Flex>
      </Box>
    </Flex>
  );
};

export default ChangePassword;
