import {
    Alert,
    Box,
    Center,
    CheckboxGroup,
    Spinner,
    VStack,
    HStack,
    useColorModeValue,
    Image,
    Text,
    Tag,
    Hide,
    Divider,
    AlertIcon,
    AlertTitle,
    AlertDescription,
  } from "@chakra-ui/react";
  import axios from "axios";
  import { motion } from "framer-motion";
  import React, { useEffect, useState } from "react";
  import { getIdToken } from "../../../../cognito/cognitoAuth";
  import { useNavigate } from "react-router-dom";
  
  const API_URL = process.env.REACT_APP_API_URL;
  
  const ProjectService = ({
    selectedServices,
    setSelectedServices,
  }) => {
    const [alert, setAlert] = useState({ show: false, status: "", message: "" });
    const [services, setServices] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [servicesUnavailable, setServicesUnavailable] = useState(false);
    const navigate = useNavigate();
  
    useEffect(() => {
      const fetchServices = async () => {
        setIsLoading(true);
        try {
          const IdToken = await getIdToken();
          const response = await axios.get(API_URL + "/services", {
            headers: {
              Authorization: `Bearer ${IdToken}`,
              "Content-Type": "application/json",
            },
          });
  
          if (response.status === 200 && response.data.statusCode === 200) {
            let servicesData = JSON.parse(response.data.body);
            if (Array.isArray(servicesData) && servicesData.length > 0) {
              servicesData = servicesData.sort((a, b) => {
                if (a.is_internal === b.is_internal) return 0;
                if (a.is_internal) return -1;
                return 1;
              });
  
              setServices(servicesData);
              setIsLoading(false);
              setServicesUnavailable(false);
            } else {
              setIsLoading(false);
              setServicesUnavailable(true);
            }
          } else {
            throw new Error("Failed to fetch services.");
          }
        } catch (error) {
          setIsLoading(false);
          setServicesUnavailable(true);
          console.error("Error fetching services", error);
          setAlert({
            show: true,
            status: "error",
            message: error.message,
          });
        }
      };
  
      fetchServices();
    }, []);
  
    const handleServiceChange = (serviceId) => {
      const updatedSelectedServices = { ...selectedServices };
  
      if (updatedSelectedServices[serviceId]) {
        // If the service is already selected, remove it
        delete updatedSelectedServices[serviceId];
      } else {
        // Add the service with an empty array for sub-tags
        updatedSelectedServices[serviceId] = { subTags: [] };
      }
  
      setSelectedServices(updatedSelectedServices);
    };
  
    const handleSubTagChange = (serviceId, subTag) => {
      const updatedSelectedServices = { ...selectedServices };
  
      if (!updatedSelectedServices[serviceId]) {
        updatedSelectedServices[serviceId] = { subTags: [] };
      }
  
      const subTags = updatedSelectedServices[serviceId].subTags;
  
      if (subTags.includes(subTag)) {
        updatedSelectedServices[serviceId].subTags = subTags.filter((tag) => tag !== subTag);
      } else {
        updatedSelectedServices[serviceId].subTags = [...subTags, subTag];
      }
  
      setSelectedServices(updatedSelectedServices);
    };
  
    return (
      <Box>
        {isLoading ? (
          <Center mt={4} mb={4} height="100%" width="100%">
            <Spinner size="xl" />
          </Center>
        ) : (
          <>
            <Alert status="warning" mb={6} rounded="md" maxW={"520px"} mx="auto">
              <AlertIcon />
              <Box flex="1">
                <AlertTitle mb={1}>Important Notice</AlertTitle>
                <AlertDescription display="block" fontSize="sm">
                  Services can't be updated once submitted. Please ensure you've selected all the services you offer before submitting your application.
                </AlertDescription>
              </Box>
            </Alert>

          
            <Text fontSize="md" color="white" textAlign="center" pb={4} maxW={"500px"} mx="auto">
              Select the capabilities you offer. You can select multiple capabilities. <b>You can selecy more than one capability and sub-capability.</b>
            </Text>

            <CheckboxGroup value={Object.keys(selectedServices)} onChange={handleServiceChange} mx={4}>
              {services.map((element, key) => (
                <motion.div
                  key={key}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.1 * key }}
                >
                  <CapabilityCard
                    value={element.id}
                    title={element.name}
                    description={element.description}
                    icon={element.icon}
                    onChange={handleServiceChange}
                    selectedOptions={Object.keys(selectedServices)}
                    isDisabled={!element.is_active}
                    selectedSubTags={selectedServices[element.id]?.subTags || []}
                    setSelectedSubTags={(tag) => handleSubTagChange(element.id, tag)}
                    services={services}
                  />
                </motion.div>
              ))}
            </CheckboxGroup>
          </>
        )}
  
        {servicesUnavailable && (
          <Alert status="info" mt={4} rounded="xl" colorScheme="blue">
            We're getting ready to launch. Please check back later for new capabilities.
          </Alert>
        )}
      </Box>
    );
  };
  
  const CapabilityCard = ({
    value,
    title,
    description,
    icon,
    onChange,
    selectedOptions,
    isDisabled,
    selectedSubTags,
    setSelectedSubTags,
    services,
  }) => {
    const textColor = useColorModeValue("oleniumBlack.700", "white");
    const boxColor = useColorModeValue("white", "oleniumBlack.300");
    const borderColor = useColorModeValue("oleniumBlack.200", "oleniumBlack.600");
    const borderColorActive = useColorModeValue("#A0B100", "#A0B100");
  
    const disabledTextColour = useColorModeValue("gray.100", "white");
    const disabledBoxColour = useColorModeValue("oleniumBlack.300", "oleniumBlack.500");
  
    const handleClick = (e) => {
      if (!isDisabled) {
        onChange(value);
      }
    };
  
    const handleSubTagClick = (e, tag) => {
      e.stopPropagation();
      setSelectedSubTags(tag);
    };
  
    return (
      <motion.div transition={{ delay: 0.2 }}>
        <Box
          borderColor={selectedOptions.includes(value) && !isDisabled ? borderColorActive : borderColor}
          shadow={selectedOptions.includes(value) && !isDisabled ? "lg" : ""}
          borderRadius="2xl"
          p={{ base: 3, sm: 4, md: 6 }}
          pb={{ base: 4, sm: 6, md: 6 }}
          m={{ base: 0, md: 4 }}
          my={{ base: 2 }}
          bg={isDisabled ? disabledBoxColour : boxColor}
          borderWidth="2px"
          onClick={handleClick}
          cursor={!isDisabled ? "pointer" : "not-allowed"}
          _hover={{
            borderColor: !isDisabled ? borderColorActive : borderColor,
          }}
        >
          <Hide above="md">
            <HStack spacing={3} align="center">
              <Image
                src={icon}
                alt={title}
                width={{ base: "40px", md: "60px" }}
                height={{ base: "40px", md: "60px" }}
                aspectRatio={1}
                my={3}
                ml={3}
                opacity={0.5}
              />
              <VStack align="left" spacing={0}>
                <Text
                  color={isDisabled ? disabledTextColour : textColor}
                  fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                  fontWeight="bold"
                  align="left"
                  m={0}
                  p={0}
                >
                  {title}
                </Text>
                {isDisabled && (
                  <Tag size="sm" w="fit-content" bg={"#A0B100"} color="white" fontWeight={"bold"} mt={1}>
                    Coming Soon
                  </Tag>
                )}
              </VStack>
            </HStack>
          </Hide>
          <HStack spacing={{ base: 3, md: 2 }}>
            <Hide below="md">
              <Image
                src={icon}
                alt={title}
                width={{ base: "40px", md: "50px" }}
                height={{ base: "40px", md: "50px" }}
                aspectRatio={1}
                borderRadius={"xl"}
                shadow={"lg"}
              />
            </Hide>
  
            <Box>
              <Hide below="md">
                <HStack>
                  <Text
                    color={isDisabled ? disabledTextColour : textColor}
                    fontSize={{ base: "sm", sm: "sm", md: "lg", lg: "lg" }}
                    fontWeight="bold"
                    ml={3}
                    mb={1}
                    align="left"
                  >
                    {title}
                  </Text>
                  {isDisabled && (
                    <Tag size="sm" w="fit-content" bg={"#A0B100"} color="white" fontWeight={"bold"} mb={1} ml={2}>
                      Coming Soon
                    </Tag>
                  )}
                </HStack>
              </Hide>
              <Text
                color={isDisabled ? disabledTextColour : textColor}
                fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "md" }}
                ml={3}
                align="left"
              >
                {description}
              </Text>
              {selectedOptions.includes(value) && (
                <motion.div
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ delay: 0.2 }}
                >
                  <Divider m={3} />
                  <Text fontSize="sm" color={textColor} ml={3} mt={2} fontWeight="bold" textAlign={"left"}>
                    Choose a sub section of {title}:
                  </Text>
                  <VStack align="start" spacing={1} ml={3}>
                    <HStack wrap={"wrap"} mt={4}>
                      {services
                        .find((service) => service.id === value)
                        ?.sub_tags.map((tag, index) => (
                          <Tag
                            key={index}
                            size="md"
                            w="fit-content"
                            bg={selectedSubTags.includes(tag) ? "#A0B100" : "oleniumBlack.500"}
                            color={"white"}
                            fontWeight={"bold"}
                            cursor="pointer"
                            onClick={(e) => handleSubTagClick(e, tag)} // Update selectedSubTags on click
                          >
                            {tag}
                          </Tag>
                        ))}
                    </HStack>
                  </VStack>
                </motion.div>
              )}
            </Box>
          </HStack>
        </Box>
      </motion.div>
    );
  };
  
  export default ProjectService;
  