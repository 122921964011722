import { useColorMode } from "@chakra-ui/color-mode";
import { motion } from "framer-motion";
import React, { useMemo } from "react";

const OleniumLogo = () => {
  const getRandomDuration = (min, max) => {
    return Math.random() * (max - min) + min;
  };

  const [isOpen, setIsOpen] = React.useState(true);

  const { colorMode } = useColorMode();

  const lightModeColor = "rgba(255, 255, 255, 1)"; // Black for light mode
  const darkModeColor = "rgba(255, 255, 255, 1)"; // White for dark mode
  const transparentColor = "rgba(0, 0, 0, 0)"; // Transparent for light mode

  const icon = useMemo(() => ({
    hidden: {
      pathLength: 0,
      fill: colorMode === "light" ? transparentColor : transparentColor,
      stroke: colorMode === "light" ? lightModeColor : darkModeColor,
      strokeWidth: 0.5,
      strokeOpacity: 1,
    },
    visible: {
      pathLength: 1,
      fill: colorMode === "light" ? lightModeColor : darkModeColor,
      stroke: colorMode === "light" ? lightModeColor : darkModeColor,
      strokeWidth: 0.5,
      strokeOpacity: 1,
    },
    transition: {
      pathLength: { duration: getRandomDuration(0.5, 2), delay: 0 },
      fill: { delay: 1, duration: 2 },
      strokeOpacity: [
        { duration: 0.5, delay: 0, from: 1, to: 0 },
        { duration: 0, delay: 0, to: 1 },
      ],
    },
  }));

  return (
    <svg
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 134 32"
              width="121px"
              height="32px"
            >

              <motion.path
                key="754356067"
                d="M23.08 14.2678C23.08 10.8609 20.2678 8.10916 16.8844 8.10916H14.951L13.1495 12.7827H16.8844C17.7193 12.7827 18.3784 13.4379 18.3784 14.2678C18.3784 15.0977 17.7193 15.7529 16.8844 15.7529H11.9191L10.1176 20.4265H16.8405C17.6753 20.4265 18.3345 21.0816 18.3345 21.9115C18.3345 22.7414 17.6753 23.3966 16.8405 23.3966H8.97514L7.17358 28.0702H16.8405C20.2678 28.0702 23.0361 25.3184 23.0361 21.9115C23.0361 20.5138 22.5527 19.1598 21.63 18.0678C22.5527 17.0196 23.0361 15.6655 23.08 14.2678Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M7.99166 20.5405L9.76279 15.8043H6.04775C5.22698 15.8043 4.57901 15.1403 4.57901 14.2993C4.57901 13.4583 5.22698 12.7943 6.04775 12.7943H10.9291L12.7003 8.05809H6.09094C5.27018 8.05809 4.62221 7.39413 4.62221 6.55311C4.62221 5.7121 5.27018 5.04814 6.09094 5.04814H13.8234L15.5945 0.31189H6.09094C2.72149 0.31189 0 3.10052 0 6.55311C0 7.96956 0.475179 9.34174 1.38234 10.4483C0.518378 11.5549 0.0431982 12.9271 0 14.3436C0 17.7962 2.72149 20.5848 6.09094 20.5848L7.99166 20.5405Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M23.08 14.2678C23.08 10.8609 20.2678 8.10916 16.8844 8.10916H14.951L13.1495 12.7827H16.8844C17.7193 12.7827 18.3784 13.4379 18.3784 14.2678C18.3784 15.0977 17.7193 15.7529 16.8844 15.7529H11.9191L10.1176 20.4265H16.8405C17.6753 20.4265 18.3345 21.0816 18.3345 21.9115C18.3345 22.7414 17.6753 23.3966 16.8405 23.3966H8.97514L7.17358 28.0702H16.8405C20.2678 28.0702 23.0361 25.3184 23.0361 21.9115C23.0361 20.5138 22.5527 19.1598 21.63 18.0678C22.5527 17.0196 23.0361 15.6655 23.08 14.2678Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M33.0605 0.374271H41.2945V2.61989H35.6492V5.58285H40.1717V7.79727H35.6492V10.8538H41.2945V13.0994H33.0605V0.374271Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M44.5379 3.74269L46.035 6.64327L47.5633 3.74269H50.2455L47.5944 8.32749L50.2143 13.0994H47.5321L46.0038 10.23L44.5067 13.0994H41.8245L44.382 8.38986L41.8556 3.74269H44.5379Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M51.1501 3.74269H53.6453V4.24171C54.3002 3.83625 55.0176 3.58674 55.7661 3.52436C58.2301 3.52436 59.4464 4.86549 59.4464 8.35867C59.4464 12.3509 58.0741 13.3489 55.2047 13.3489C54.6745 13.3489 54.1755 13.2865 53.6453 13.193V17.0604H51.1501V3.74269ZM53.6765 6.14425V11.0409C54.1443 11.1033 54.5809 11.1345 55.0488 11.1657C56.5459 11.1657 56.9513 10.3548 56.9513 8.35867C56.9513 6.45614 56.3275 5.76998 55.3295 5.76998C54.7369 5.80117 54.1755 5.92592 53.6765 6.14425Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M68.3042 10.9474L68.3354 12.7875C67.0566 13.0994 65.7467 13.2554 64.4367 13.3177C61.5985 13.3177 60.4133 11.9142 60.4133 8.54581C60.4133 5.20857 61.8168 3.52436 64.5615 3.52436C67.3061 3.52436 68.6473 4.92787 68.6473 7.76608L68.4601 9.51267H62.9708C63.002 10.6355 63.5011 11.1345 64.8734 11.1345C66.3704 11.1345 68.3042 10.9474 68.3042 10.9474ZM66.1521 7.57894C66.1521 6.05068 65.7155 5.58284 64.5303 5.58284C63.3451 5.58284 62.9085 6.17544 62.9085 7.57894H66.1521Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M69.9573 3.74269H72.4524V4.74074C73.3881 4.17933 74.3861 3.77388 75.4466 3.52436V6.05068C74.4485 6.23781 73.4505 6.51852 72.4836 6.83041V13.0994H69.9885L69.9573 3.74269Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M76.4446 0H78.9397V2.5575H76.4446V0ZM76.4446 3.74269H78.9397V13.0994H76.4446V3.74269Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M88.2339 10.9474L88.265 12.7875C86.9863 13.0994 85.6763 13.2554 84.3664 13.3177C81.5282 13.3177 80.343 11.9142 80.343 8.54581C80.343 5.20857 81.7465 3.52436 84.4912 3.52436C87.2358 3.52436 88.5769 4.92787 88.5769 7.76608L88.3898 9.51267H82.9005C82.9317 10.6355 83.4307 11.1345 84.8031 11.1345C86.3001 11.1345 88.2339 10.9474 88.2339 10.9474ZM86.0818 7.57894C86.0818 6.05068 85.6452 5.58284 84.46 5.58284C83.2748 5.58284 82.8381 6.17544 82.8381 7.57894H86.0818Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M89.887 13.0994V3.74269H92.3821V4.2729C93.0994 3.86744 93.9104 3.58674 94.7213 3.52436C97.3412 3.52436 98.0273 5.17738 98.0273 8.04678V13.0994H95.5322V8.10916C95.5322 6.67446 95.345 5.76998 94.1287 5.76998C93.5361 5.76998 92.9747 5.89473 92.4133 6.11306V13.0994H89.887Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M106.261 3.92982L106.199 5.92592C106.199 5.92592 104.733 5.76998 104.016 5.76998C102.207 5.76998 101.895 6.45614 101.895 8.32748C101.895 10.4172 102.238 11.0721 104.047 11.0721C104.764 11.0721 106.23 10.9162 106.23 10.9162L106.292 12.9123C105.294 13.0994 104.265 13.2554 103.236 13.3177C100.46 13.3177 99.3372 11.8518 99.3372 8.32748C99.3372 5.02144 100.491 3.52436 103.298 3.52436C104.296 3.58674 105.294 3.7115 106.261 3.92982Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M114.994 10.9474L115.026 12.7875C113.747 13.0994 112.437 13.2554 111.127 13.3177C108.289 13.3177 107.104 11.9142 107.104 8.54581C107.104 5.20857 108.507 3.52436 111.252 3.52436C113.996 3.52436 115.337 4.92787 115.337 7.76608L115.15 9.51267H109.661C109.692 10.6355 110.191 11.1345 111.564 11.1345C113.061 11.1345 114.994 10.9474 114.994 10.9474ZM112.873 7.57894C112.873 6.05068 112.437 5.58284 111.252 5.58284C110.066 5.58284 109.63 6.17544 109.63 7.57894H112.873Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M38.082 15.3138C40.6395 15.3138 42.043 16.2495 42.043 18.6823C42.043 20.117 41.6375 20.8655 40.6395 21.4893C41.7311 21.9571 42.2925 22.7368 42.2925 24.3899C42.2925 26.9474 40.7019 28.039 38.1444 28.039H33.0605V15.3138H38.082ZM35.6492 17.5283V20.5536H37.9572C38.9553 20.5536 39.4231 20.0234 39.4231 19.0253C39.4231 17.9649 38.9241 17.5283 37.9572 17.5283H35.6492ZM35.6492 22.7368V25.8558H38.0196C39.08 25.8558 39.6726 25.5439 39.6726 24.2963C39.6726 23.2359 39.0489 22.768 38.0196 22.768L35.6492 22.7368Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M50.9631 25.5127C50.9943 26.0741 51.0879 26.2924 51.5869 26.386L51.5245 28.2885C50.2769 28.2885 49.622 28.1637 48.8422 27.6335C47.969 28.039 47.0333 28.2573 46.0976 28.2885C44.1327 28.2885 43.197 27.1969 43.197 25.232C43.197 23.1111 44.4134 22.5185 46.4719 22.3626L48.468 22.2066V21.614C48.468 20.8031 48.1249 20.616 47.3764 20.616C46.1288 20.616 43.852 20.7719 43.852 20.7719L43.7896 19.0253C45.0372 18.7134 46.3159 18.5263 47.5947 18.4951C50.0274 18.4951 50.9943 19.462 50.9943 21.6452L50.9631 25.5127ZM46.7526 24.078C46.0976 24.1403 45.6921 24.4522 45.6921 25.1384C45.6921 25.7934 45.9417 26.23 46.5966 26.23C47.2204 26.1988 47.8442 26.1053 48.4368 25.9181V23.922L46.7526 24.078Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M52.8967 28.039V18.6823H55.3919V19.2125C56.1092 18.807 56.9201 18.5263 57.731 18.4639C60.3509 18.4639 61.0371 20.117 61.0371 22.9864V28.0702H58.5419V23.0799C58.5419 21.6452 58.3548 20.7407 57.1384 20.7407C56.5458 20.7407 55.9844 20.8655 55.423 21.0838V28.0702L52.8967 28.039Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M62.7524 28.039V14.9396H65.2476V22.3938L66.2144 22.2378L68.0546 18.7135H70.8616L68.4289 23.1423L70.9864 28.0702H68.1482L66.2456 24.4211L65.2164 24.577V28.0702H62.7524V28.039Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M81.1229 21.2398H84.8344V27.8518C83.3997 28.0702 81.9338 28.2261 80.4991 28.2885C76.351 28.2885 75.2905 25.9805 75.2905 21.7076C75.2905 17.0604 76.6317 15.1267 80.4055 15.1267C81.9026 15.1579 83.3685 15.345 84.8344 15.6569L84.772 17.653C84.772 17.653 82.5264 17.4035 80.811 17.4035C78.5966 17.4035 77.9416 18.1209 77.9416 21.7076C77.9416 25.1072 78.4094 26.0429 80.7174 26.0429C81.4972 26.0429 82.2769 25.9805 82.2769 25.9805V23.5166H81.1229V21.2398Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M86.5496 18.6823H89.0447V19.6803C89.9804 19.1189 90.9784 18.7135 92.0388 18.4639V20.9903C91.0408 21.1774 90.0427 21.4581 89.0759 21.77V28.039H86.5808L86.5496 18.6823Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M101.177 23.3606C101.177 26.23 100.117 28.2885 96.8732 28.2885C93.6295 28.2885 92.5691 26.2612 92.5691 23.3606C92.5691 20.46 93.6295 18.4639 96.8732 18.4639C100.117 18.4639 101.177 20.46 101.177 23.3606ZM98.6198 23.3606C98.6198 21.5517 98.2455 20.616 96.8732 20.616C95.5009 20.616 95.1266 21.5517 95.1266 23.3606C95.1266 25.1696 95.5009 26.1365 96.8732 26.1365C98.2455 26.1365 98.6198 25.1696 98.6198 23.3606Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M110.565 18.6823V28.039H108.07V27.5088C107.353 27.9142 106.542 28.1637 105.731 28.2573C102.986 28.2573 102.425 26.729 102.425 23.5789V18.6823H104.92V23.6101C104.92 25.2632 104.983 26.0117 106.23 26.0117C106.854 26.0117 107.446 25.8869 108.039 25.6686V18.6823H110.565Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
              <motion.path
                key="754356067"
                d="M112.437 18.6823H114.932V19.1813C115.587 18.7758 116.304 18.5263 117.053 18.4639C119.517 18.4639 120.733 19.8051 120.733 23.2982C120.733 27.2904 119.361 28.2885 116.491 28.2885C115.961 28.2885 115.462 28.2261 114.932 28.1326V32H112.437V18.6823ZM114.932 21.0838V25.9805C115.4 26.0429 115.836 26.0741 116.304 26.1053C117.801 26.1053 118.207 25.2943 118.207 23.2982C118.207 21.3957 117.583 20.7096 116.585 20.7096C116.024 20.7407 115.462 20.8655 114.932 21.0838Z"
                variants={icon}
                initial="hidden"
                animate={isOpen ? "visible" : "hidden"}
                transition={icon.transition}
              />
            </svg>
  );
};

export default OleniumLogo;
