import {
  Box,
  Grid,
  GridItem,
  HStack,
  Icon,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import React from "react";

import {
  BsChatText,
  BsFolderCheck,
  BsHeart,
  BsUiChecksGrid,
} from "react-icons/bs";
import { CgLivePhoto } from "react-icons/cg";
import { IoSparklesOutline } from "react-icons/io5";

const FeatureSection = () => {
  const renderFeature = (icon, title) => (
    <HStack spacing={4} align="center">
      <Icon as={icon} boxSize={4} />
      <Text fontSize="lg">{title}</Text>
    </HStack>
  );

  return (
    <Box w="100%" my={48} alignItems={"center"} justifyContent={"center"} px={{ base: 2, md: 6 }}>
      <VStack
        align="center"
        maxW="1000px"
        borderWidth={2}
        borderColor="#A0B100"
        bg="oleniumBlack.800"
        p={{ base: 8, md: 16 }}
        rounded="2xl"
        mx={{ base: 4, md: "auto" }}
      >
        <Text
          fontSize={{ base: "2xl", md: "4xl" }}
          fontWeight="bold"
          pb={{ base: 4, md: 2 }}
          textAlign="center"
        >
          What Makes Us Different?
        </Text>
        <Text
          fontSize={{ base: "md", md: "xl" }}
          pb={{ base: 10, md: 16 }}
          textAlign="center"
        >
          Empowering Consultants with Cutting-Edge Features
        </Text>
        <Grid
          templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
          gap={8}
          maxW="800px"
          mx="auto"
          justifyItems="left"
        >
          <GridItem>
            {renderFeature(IoSparklesOutline, "Bespoke Mentor Matching")}
          </GridItem>
          <GridItem>
            {renderFeature(BsChatText, "Integrated Communication Tools")}
          </GridItem>
          <GridItem>
            {renderFeature(CgLivePhoto, "Video Conferencing & Recording")}
          </GridItem>
          <GridItem>
            {renderFeature(BsHeart, "Mentor Community & Support")}
          </GridItem>
        </Grid>
      </VStack>
    </Box>
  );
};

export default FeatureSection;
