import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spacer,
  Spinner,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signIn } from "../../cognito/cognitoAuth";

import { FaGoogle, FaMicrosoft } from "react-icons/fa";

const Login = () => {
  const API_URL = process.env.REACT_APP_API_URL;
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [show, setShow] = React.useState(false);
  const handleClick = () => setShow(!show);

  const toast = useToast();

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    try {
      await signIn(email, password);
      navigate("/");
    } catch (err) {
      console.log("Sign in error", err);
      setError(err.message);
    } finally {
      setIsLoading(false);
    }
  };

  const [isFormValid, setIsFormValid] = useState(false);

  const handleFormChange = () => {
    setIsFormValid(email !== "" && password !== "");
  };

  return (
    <motion.div
      as="form"
      onSubmit={handleSubmit}
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        as="form"
        onSubmit={handleSubmit}
        px={10}
        py={8}
        w={{ base: "90%", sm: "500px", md: "600px", lg: "700px" }}
        maxW="400px"
        borderRadius="2xl"
        bg="blackAlpha.800"
        backdropFilter="blur(10px)"
        mx="auto" // Centers the box horizontally
      >
        <VStack spacing={8} alignItems="start" mb={8}>
          <Box>
            <Text fontSize="4xl" fontWeight="bold" color="white">
              Sign in
            </Text>

            <HStack mt={1}>
              <Text fontSize="md" color="white" fontWeight="bold">
                New here?{" "}
              </Text>
              <Button
                variant="link"
                size={"md"}
                color="#A0B100"
                onClick={() => navigate("/")}
              >
                Create an account
              </Button>
            </HStack>
          </Box>

          <FormControl id="email" isRequired>
            <FormLabel color="white">Email</FormLabel>
            <Input
              type="email"
              value={email}
              placeholder="Enter your email"
              _placeholder={{ color: "oleniumGray.300" }}
              onChange={(e) => {
                setEmail(e.target.value);
                handleFormChange();
              }}
            />
            <FormErrorMessage>{error && error}</FormErrorMessage>
          </FormControl>

          <FormControl id="password" isRequired>
            <FormLabel color="white">Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={show ? "text" : "password"}
                value={password}
                onChange={(e) => {
                  setPassword(e.target.value);
                  handleFormChange();
                }}
                placeholder="Enter your password"
                _placeholder={{ color: "oleniumGray.300" }}
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClick}
                  background="whiteAlpha.400"
                  color="white"
                  _hover={{ background: "whiteAlpha.500" }}
                >
                  {show ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{error && error}</FormErrorMessage>
          </FormControl>

          {error && (
            <Alert status="error" mt={4} mb={4} borderRadius="xl">
              <AlertIcon />
              {error}
            </Alert>
          )}
          <HStack width="100%">
            <Spacer />
            <Button
              color="#A0B100"
              variant={"link"}
              size={"md"}
              onClick={() => navigate("/forgot-password")}
              alignContent={"right"}
            >
              Forgot Password?
            </Button>
          </HStack>

          <Button
            type="submit"
            variant="ExperienceBankGreen"
            width="100%"
            size={"md"}
            disabled={!isFormValid || isLoading}
          >
            {isLoading ? <Spinner /> : "Login"}
          </Button>
        </VStack>
      </Box>
    </motion.div>
  );
};

export default Login;
