// ProviderProfile.jsx
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  VStack,
  Spinner,
  useToast,
  Heading,
  Divider,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  HStack,
  Textarea,
  Input,
  InputGroup,
  InputLeftAddon,
  Icon,
  FormErrorMessage,
  Stack,
  StackDivider,
  Container,
  useDisclosure,
  Select,
  Checkbox,
} from "@chakra-ui/react";
import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import { AiOutlineEnvironment } from "react-icons/ai";
import { UserContext } from "../../../../UserContext";
import Countries from "../../../Countries";

const months = [
  { value: "1", label: "January" },
  { value: "2", label: "February" },
  { value: "3", label: "March" },
  { value: "4", label: "April" },
  { value: "5", label: "May" },
  { value: "6", label: "June" },
  { value: "7", label: "July" },
  { value: "8", label: "August" },
  { value: "9", label: "September" },
  { value: "10", label: "October" },
  { value: "11", label: "November" },
  { value: "12", label: "December" },
];

const currentYear = new Date().getFullYear();
const years = Array.from(new Array(50), (val, index) => currentYear - index);

const API_URL = process.env.REACT_APP_API_URL;

const ProviderProfile = () => {
  // Access UserContext at the top level
  const { userDetails } = useContext(UserContext);
  const providerId = userDetails.id; // Defined providerId correctly

  // State management
  const [profile, setProfile] = useState({
    bio: "",
    workExperience: [], // New state for work experiences
    application_id: "", // Ensure application_id is part of the state
  });

  const [initialProfile, setInitialProfile] = useState(null); // New state to store initial profile
  const [loading, setLoading] = useState(true);
  const [saving, setSaving] = useState(false);
  const [formValidWork, setFormValidWork] = useState(false);
  const toast = useToast();

  // Validation state
  const [bioError, setBioError] = useState("");

  // Modal state for Work Experience
  const {
    isOpen: isWorkModalOpen,
    onOpen: onWorkModalOpen,
    onClose: onWorkModalClose,
  } = useDisclosure();

  // Define fixed colors for dark mode
  const boxBg = "oleniumBlack.600"; // Dark background for boxes
  const textColor = "oleniumBlack.50"; // Light text for readability
  const workModalBg = "oleniumBlack.700"; // Dark background for modals
  const subTextColor = "oleniumBlack.300"; // Lighter shade for subtext

  // Fetch provider profile on mount
  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const IdToken = await getIdToken(); // Function to retrieve the JWT token
        // Corrected axios.get syntax by adding a comma between URL and config
        const response = await axios.get(
          `${API_URL}/provider/${providerId}`, // Corrected API URL
          {
            headers: {
              Authorization: `Bearer ${IdToken}`,
            },
          }
        );

        console.log("Profile response:", response);

        if (response.status === 200) {
          let parsedData;
          try {
            // Parse the body string into a JSON object
            parsedData =
              typeof response.data.body === "string"
                ? JSON.parse(response.data.body)
                : response.data.body;
          } catch (parseError) {
            throw new Error("Failed to parse profile data.");
          }

          console.log("Parsed Profile Data:", parsedData);

          // Extract necessary fields from the parsed data
          const fetchedProfile = {
            bio: parsedData.profile?.bio || "",
            workExperience: parsedData.experience
              ? parsedData.experience.map((item, index) => ({
                  ...item,
                  id: item.id || index, // Ensure each workExperience item has an id
                }))
              : [], // Correctly map experience to workExperience
            application_id: parsedData.application_id || "", // Ensure application_id is set
          };

          setProfile(fetchedProfile);
          setInitialProfile(fetchedProfile); // Set initial profile
        } else {
          throw new Error("Failed to fetch profile data.");
        }
      } catch (error) {
        console.error("Error fetching profile:", error);
        toast({
          title: "Error",
          description: "Failed to fetch profile data.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchProfile();
  }, [providerId, toast]); // Added providerId to dependencies

  // Validate form based on work experience
  useEffect(() => {
    if (profile.workExperience.length > 0) {
      setFormValidWork(true);
    } else {
      setFormValidWork(false);
    }
  }, [profile.workExperience]);

  // State to track if the form has been touched
  const [formTouched, setFormTouched] = useState(false);

  // Handle bio changes
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProfile((prev) => ({
      ...prev,
      [name]: value,
    }));

    // If the bio field is being updated, validate it
    if (name === "bio") {
      validateBio(value);
      setFormTouched(true); // Mark the form as touched when bio changes
    }
  };

  // Validation functions
  const validateBio = (bio) => {
    if (formTouched) { // Only validate if the form has been touched
      if (!bio.trim()) {
        setBioError("Bio is required.");
      } else if (bio.length < 100) {
        setBioError("Bio must be at least 100 characters.");
      } else if (bio.length > 1000) {
        setBioError("Bio cannot exceed 1000 characters.");
      } else {
        setBioError("");
      }
    }
  };

  // Initial validation on mount
  useEffect(() => {
    validateBio(profile.bio);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // State to track if there are unsaved changes
  const [hasChanges, setHasChanges] = useState(false);

  // Helper function to strip 'id' fields from workExperience
  const stripIds = (workExperience) =>
    workExperience.map(({ id, ...rest }) => rest);

  // Compare current profile with initial profile to detect changes
  useEffect(() => {
    if (!initialProfile) return;

    const isBioChanged = profile.bio !== initialProfile.bio;

    const isWorkExperienceChanged =
      JSON.stringify(stripIds(profile.workExperience)) !==
      JSON.stringify(stripIds(initialProfile.workExperience));

    setHasChanges(isBioChanged || isWorkExperienceChanged);
  }, [profile, initialProfile]);

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    // Perform final validation before submission
    validateBio(profile.bio);

    // If there are any validation errors, do not proceed
    if (bioError) {
      toast({
        title: "Validation Error",
        description: "Please fix the errors in the form before submitting.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Check if there are changes to save
    if (!hasChanges) {
      toast({
        title: "No Changes",
        description: "No changes detected to save.",
        status: "info",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    setSaving(true);
    try {
      const IdToken = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/update`, // Corrected API URL
        {
          bio: profile.bio,
          experience: profile.workExperience.map(({ id, ...rest }) => rest), // Exclude 'id' when sending to backend
          application_id: profile.application_id, // Include application_id
        },
        {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        toast({
          title: "Success",
          description: "Profile updated successfully.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        setInitialProfile(profile); // Update initial profile after saving
        setHasChanges(false); // Reset change tracking
      } else {
        throw new Error("Failed to update profile.");
      }
    } catch (error) {
      console.error("Error updating profile:", error);
      toast({
        title: "Error",
        description: "Failed to update profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setSaving(false);
    }
  };

  // Early return while loading
  if (loading) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        w="100%"
        h="100vh"
      >
        <Spinner size="xl" color="oleniumBlack.600" />
      </Flex>
    );
  }

  return (
    <Flex
      direction="column"
      align="center"
      justify="center"
      w="100%"
    >
      <Box
        bg={boxBg}
        p={8}
        rounded="2xl"
        maxW="800px"
        w="100%"
        shadow="lg"
        border="1px"
        borderColor="oleniumBlack.500"
      >
        <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor}>
          Mentor Profile
        </Heading>
        <Divider my={4} borderColor="oleniumBlack.600" />
        <form onSubmit={handleSubmit}>
          <VStack spacing={6} align="stretch">
            {/* Bio */}
            <FormControl id="bio" isRequired isInvalid={bioError}>
              <FormLabel fontSize="lg" fontWeight="bold" color="white">
                Tell us about your experience and career
              </FormLabel>
              <Text fontSize="sm" color="white" mb={4}>
                Please provide a brief summary of your professional background, areas of expertise, and key accomplishments in your career. This will help us match you with mentees looking for guidance in your field. (100-1000 characters)
              </Text>
              <Textarea
                name="bio"
                value={profile.bio}
                onChange={handleInputChange}
                placeholder="Tell us about yourself"
                required
                bg="oleniumBlack.600"
                color={textColor}
                borderColor="oleniumBlack.500"
                _hover={{ borderColor: "oleniumBlack.400" }}
                onBlur={(e) => validateBio(e.target.value)}
              />
              {bioError && <FormErrorMessage>{bioError}</FormErrorMessage>}
            </FormControl>

            {/* Work Experience */}
            <FormControl id="workExperience">
              <FormLabel fontSize="lg" fontWeight="bold" color="white">
                Work Experience
              </FormLabel>
              <Button onClick={onWorkModalOpen}>
                Manage Work Experience
              </Button>
            </FormControl>

            <Flex justify="flex-end" w="100%">
              {/* Submit Button */}
              <Button
                type="submit"
                variant={"ExperienceBankGreen"}
                maxW={"150px"}
                size="sm"
                isLoading={saving} // Show loading spinner when saving
              >
                Save Changes
              </Button>
            </Flex>
          </VStack>
        </form>
      </Box>

      {/* Work Experience Modal */}
      <Modal isOpen={isWorkModalOpen} onClose={onWorkModalClose} size="4xl" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" /> {/* Darker overlay */}
        <ModalContent bg={workModalBg} borderRadius="2xl" border="1px" borderColor="oleniumBlack.600">
          <ModalHeader color={textColor}>
            {profile.workExperience.length > 0 ? "Edit Work Experience" : "Add Work Experience"}
          </ModalHeader>
          <ModalCloseButton color={textColor} />
          <ModalBody>
            <WorkExperience
              setFormValidExperience={setFormValidWork}
              workExperience={profile.workExperience}
              setWorkExperience={(newWorkExperience) =>
                setProfile((prev) => ({ ...prev, workExperience: newWorkExperience }))
              }
            />
          </ModalBody>
          <ModalFooter>
            <Button
              variant="ExperienceBankGreen"
              onClick={onWorkModalClose}
              isDisabled={!formValidWork}
            >
              Done
            </Button>
            <Button
              variant="ghost"
              color={textColor}
              _hover={{ bg: "oleniumBlack.600", color: "oleniumBlack.50" }}
              onClick={onWorkModalClose}
            >
              Cancel
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Flex>
  );
};

export default ProviderProfile;

// WorkExperienceItem Component
const WorkExperienceItem = ({ work, onDelete, onEdit, canDelete }) => {
  const boxBg = "oleniumBlack.700"; // Dark background for each work item
  const textColor = "oleniumBlack.50";
  const subTextColor = "white";

  return (
    <Box
      p={4}
      bg={boxBg}
      borderRadius="md"
      w="100%"
      border="1px"
      borderColor="oleniumBlack.600"
    >
      <HStack justify="space-between" align="start">
        <Box>
          <Text fontWeight="bold" fontSize="lg" color={textColor}>
            {work.title} at {work.company}
          </Text>
          <Text fontSize="sm" color={subTextColor}>
            {formatDate(work.startDate)} - {work.endDate ? formatDate(work.endDate) : "Present"}
          </Text>
          <Text mt={2} color={textColor}>
            {work.description}
          </Text>
          <Text fontSize="sm" color={subTextColor}>
            Location: {work.location}, {work.country}
          </Text>
        </Box>
        <VStack spacing={2}>
          <Button
            size="sm"
            bg="oleniumBlack.600"
            color="oleniumBlack.50"
            _hover={{ bg: "oleniumBlack.700" }}
            onClick={() => onEdit(work.id)}
          >
            Edit
          </Button>
          {canDelete && (
            <Button
              size="sm"
              bg="red.500"
              color="white"
              _hover={{ bg: "red.600" }}
              onClick={() => onDelete(work.id)}
            >
              Delete
            </Button>
          )}
        </VStack>
      </HStack>
    </Box>
  );
};

// Helper function to format date from "YYYY-MM" to "Month YYYY"
const formatDate = (dateString) => {
  if (!dateString) return "";
  const [year, month] = dateString.split("-");
  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const monthIndex = parseInt(month, 10) - 1;
  return `${monthNames[monthIndex]} ${year}`;
};

// WorkExperienceForm Component
const WorkExperienceForm = ({
  initialValues,
  onSubmit,
  formErrors,
  setFormErrors,
  setFormTouched,
  formTouched,
}) => {
  const [formValues, setFormValues] = useState({
    title: initialValues?.title || "",
    company: initialValues?.company || "",
    location: initialValues?.location || "",
    country: initialValues?.country || "",
    startMonth: initialValues?.startDate ? initialValues.startDate.split("-")[1] : "",
    startYear: initialValues?.startDate ? initialValues.startDate.split("-")[0] : "",
    endMonth: initialValues?.endDate ? initialValues.endDate.split("-")[1] : "",
    endYear: initialValues?.endDate ? initialValues.endDate.split("-")[0] : "",
    description: initialValues?.description || "",
    present: initialValues?.endDate ? false : true,
  });

  const validate = () => {
    const errors = {};
    const today = new Date();
    const selectedStartDate =
      formValues.startMonth && formValues.startYear
        ? new Date(formValues.startYear, formValues.startMonth - 1)
        : null;
    const selectedEndDate = formValues.present
      ? null
      : formValues.endMonth && formValues.endYear
      ? new Date(formValues.endYear, formValues.endMonth - 1)
      : null;

    if (!formValues.title) errors.title = "Title is required";
    if (formValues.title.length > 50) errors.title = "Title cannot exceed 50 characters";
    if (!formValues.company) errors.company = "Company is required";
    if (formValues.company.length > 50) errors.company = "Company cannot exceed 50 characters";
    if (!formValues.startMonth || !formValues.startYear) {
      errors.startDate = "Start date is required";
    } else if (selectedStartDate > today) {
      errors.startDate = "Start date cannot be in the future";
    }
    if (!formValues.present) {
      if (!formValues.endMonth || !formValues.endYear) {
        errors.endDate = "End date is required";
      } else if (
        selectedEndDate &&
        selectedStartDate &&
        selectedEndDate < selectedStartDate
      ) {
        errors.endDate = "End date cannot be before start date";
      }
    }
    if (formValues.description.length > 400)
      errors.description = "Description cannot exceed 400 characters";

    setFormErrors(errors);
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormTouched(true);
    validate();
    if (Object.keys(formErrors).length === 0) {
      const formattedWork = {
        title: formValues.title,
        company: formValues.company,
        location: formValues.location,
        country: formValues.country,
        startDate:
          formValues.startMonth && formValues.startYear
            ? `${formValues.startYear}-${formValues.startMonth.padStart(2, "0")}`
            : "",
        endDate: formValues.present
          ? null
          : formValues.endMonth && formValues.endYear
          ? `${formValues.endYear}-${formValues.endMonth.padStart(2, "0")}`
          : "",
        description: formValues.description,
      };
      onSubmit(formattedWork);
    }
  };

  return (
    <form id="work-experience-form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isInvalid={formTouched && formErrors.title}>
          <FormLabel color="oleniumBlack.50">Job Title</FormLabel>
          <Input
            type="text"
            placeholder="Title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
            bg="oleniumBlack.600"
            color="oleniumBlack.50"
            borderColor="oleniumBlack.500"
            _hover={{ borderColor: "oleniumBlack.400" }}
          />
          <FormErrorMessage>{formErrors.title}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.company}>
          <FormLabel color="oleniumBlack.50">Company</FormLabel>
          <Input
            type="text"
            placeholder="Company"
            name="company"
            value={formValues.company}
            onChange={handleChange}
            bg="oleniumBlack.600"
            color="oleniumBlack.50"
            borderColor="oleniumBlack.500"
            _hover={{ borderColor: "oleniumBlack.400" }}
          />
          <FormErrorMessage>{formErrors.company}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.description}>
          <FormLabel color="oleniumBlack.50">Description</FormLabel>
          <Textarea
            placeholder="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
            bg="oleniumBlack.600"
            color="oleniumBlack.50"
            borderColor="oleniumBlack.500"
            _hover={{ borderColor: "oleniumBlack.400" }}
          />
          <FormErrorMessage>{formErrors.description}</FormErrorMessage>
        </FormControl>
        <HStack width="100%" spacing={4}>
          <FormControl width="50%">
            <FormLabel color="oleniumBlack.50">Location</FormLabel>
            <InputGroup>
              <InputLeftAddon bg="oleniumBlack.600" borderColor="oleniumBlack.500">
                <Icon as={AiOutlineEnvironment} color="oleniumBlack.50" />
              </InputLeftAddon>
              <Input
                type="text"
                placeholder="Location"
                name="location"
                value={formValues.location}
                onChange={handleChange}
                bg="oleniumBlack.600"
                color="oleniumBlack.50"
                borderColor="oleniumBlack.500"
                _hover={{ borderColor: "oleniumBlack.400" }}
              />
            </InputGroup>
          </FormControl>
          <FormControl width="50%">
            <FormLabel color="oleniumBlack.50">Country</FormLabel>
            <Select
              placeholder="Select Country"
              name="country"
              value={formValues.country}
              onChange={handleChange}
              bg="oleniumBlack.600"
              color="oleniumBlack.50"
              borderColor="oleniumBlack.500"
              _hover={{ borderColor: "oleniumBlack.400" }}
            >
              {Countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={4}>
          <FormControl isInvalid={formTouched && formErrors.startDate} width="50%">
            <FormLabel color="oleniumBlack.50">Start Date</FormLabel>
            <HStack>
              <Select
                placeholder="Month"
                name="startMonth"
                value={formValues.startMonth}
                onChange={handleChange}
                bg="oleniumBlack.600"
                color="oleniumBlack.50"
                borderColor="oleniumBlack.500"
                _hover={{ borderColor: "oleniumBlack.400" }}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Year"
                name="startYear"
                value={formValues.startYear}
                onChange={handleChange}
                bg="oleniumBlack.600"
                color="oleniumBlack.50"
                borderColor="oleniumBlack.500"
                _hover={{ borderColor: "oleniumBlack.400" }}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </HStack>
            <FormErrorMessage>{formErrors.startDate}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formTouched && formErrors.endDate} width="50%">
            <Flex justify="space-between" align="center">
              <FormLabel mb={0} color="oleniumBlack.50">
                End Date
              </FormLabel>
              <Checkbox
                name="present"
                isChecked={formValues.present}
                onChange={handleChange}
                colorScheme="gray"
                color="oleniumBlack.50"
              >
                Present
              </Checkbox>
            </Flex>
            {!formValues.present && (
              <HStack mt={2}>
                <Select
                  placeholder="Month"
                  name="endMonth"
                  value={formValues.endMonth}
                  onChange={handleChange}
                  bg="oleniumBlack.600"
                  color="oleniumBlack.50"
                  borderColor="oleniumBlack.500"
                  _hover={{ borderColor: "oleniumBlack.400" }}
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Year"
                  name="endYear"
                  value={formValues.endYear}
                  onChange={handleChange}
                  bg="oleniumBlack.600"
                  color="oleniumBlack.50"
                  borderColor="oleniumBlack.500"
                  _hover={{ borderColor: "oleniumBlack.400" }}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
            {!formValues.present && (
              <FormErrorMessage>{formErrors.endDate}</FormErrorMessage>
            )}
          </FormControl>
        </HStack>
      </VStack>
    </form>
  );
};

// WorkExperience Component
const WorkExperience = ({ setFormValidExperience, workExperience, setWorkExperience }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editingWork, setEditingWork] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    setFormValidExperience(workExperience && workExperience.length > 0);
  }, [workExperience, setFormValidExperience]);

  const closeWorkModal = () => {
    onClose();
    setEditingWork(null);
    setFormErrors({});
    setFormTouched(false);
  };

  const addOrUpdateWorkExperience = (work) => {
    if (editingWork) {
      setWorkExperience(
        workExperience.map((item) =>
          item.id === editingWork.id ? { ...work, id: editingWork.id } : item
        )
      );
    } else {
      setWorkExperience([...workExperience, { ...work, id: Date.now() }]);
    }
    closeWorkModal();
  };

  const deleteWorkExperience = (id) => {
    setWorkExperience(workExperience.filter((item) => item.id !== id));
  };

  const editWorkExperience = (id) => {
    setEditingWork(workExperience.find((item) => item.id === id));
    onOpen();
  };

  // Define modal background color
  const workModalBg = "oleniumBlack.700";

  return (
    <Box as="section">
      <Text fontSize="md" mb={4} textAlign="center" mx="auto" color="oleniumBlack.50">
        Add your work experience to showcase your skills and expertise
      </Text>
      <Container maxW="3xl">
        <Box
          bg="oleniumBlack.600"
          boxShadow="sm"
          borderRadius="lg"
          p={{ base: "4", md: "6" }}
          border="1px"
          borderColor="oleniumBlack.600"
        >
          <Stack
            spacing="5"
            divider={<StackDivider borderColor="oleniumBlack.500" />}
            justifyContent={{ base: "center" }}
            alignContent={{ base: "center" }}
          >
            <Button
              variant="ExperienceBankGreen"
              onClick={onOpen}
              alignSelf="flex-start"
            >
              Add Work Experience
            </Button>
            {workExperience &&
              workExperience.length > 0 &&
              workExperience.map((work) => (
                <WorkExperienceItem
                  key={work.id}
                  work={work}
                  onDelete={deleteWorkExperience}
                  onEdit={editWorkExperience}
                  canDelete={workExperience.length > 1} // Pass canDelete prop
                />
              ))}
          </Stack>
        </Box>
      </Container>

      <Modal isOpen={isOpen} onClose={closeWorkModal} size="4xl" isCentered>
        <ModalOverlay bg="rgba(0, 0, 0, 0.8)" />
        <ModalContent
          bg={workModalBg}
          borderRadius="2xl"
          border="1px"
          borderColor="oleniumBlack.600"
        >
          <ModalHeader borderBottomWidth="1px" py={4} color="oleniumBlack.50">
            {editingWork ? "Edit Work Experience" : "Add Work Experience"}
          </ModalHeader>
          <ModalCloseButton color="oleniumBlack.50" />
          <ModalBody>
            <WorkExperienceForm
              initialValues={editingWork}
              onSubmit={addOrUpdateWorkExperience}
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              setFormTouched={setFormTouched}
              formTouched={formTouched}
            />
          </ModalBody>
          <ModalFooter>
            <Button
              mr={3}
              onClick={closeWorkModal}
              bg="oleniumBlack.600"
              color="oleniumBlack.50"
              _hover={{ bg: "oleniumBlack.700" }}
            >
              Close
            </Button>
            <Button
              type="submit"
              form="work-experience-form"
              variant={"ExperienceBankGreen"}
            >
              {editingWork ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};
