import {
  Alert,
  AlertIcon,
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Icon,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
  Spinner,
  Text,
  VStack,
  useToast,
  Flex,
} from "@chakra-ui/react";
import { FaGoogle, FaMicrosoft } from "react-icons/fa";
import { motion } from "framer-motion";
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { signUp } from "../../cognito/cognitoAuth";


const ProviderRegistration = () => {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  const toast = useToast();
  const navigate = useNavigate();

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleClickPassword = () => setShowPassword(!showPassword);
  const handleClickConfirmPassword = () =>
    setShowConfirmPassword(!showConfirmPassword);

  // read email from url
  // useEffect(() => {
  //   const urlParams = new URLSearchParams(window.location.search);
  //   const email = urlParams.get("email");
  //   if (email) {
  //     setEmail(email);
  //   }

  //   const loadGoogleScript = () => {
  //     const script = document.createElement('script');
  //     script.src = 'https://apis.google.com/js/api.js';
  //     script.onload = () => {
  //       window.gapi.load('client:auth2', initGoogleClient);
  //     };
  //     document.body.appendChild(script);
  //   };

  //   // Initialize the Google auth client
  //   const initGoogleClient = () => {
  //     window.gapi.client.init({
  //       clientId: process.env.REACT_APP_GOOGLE_CLIENT_ID,
  //       scope: 'email',
  //     });
  //   };

  //   loadGoogleScript();
  // }, []);


  // const handleGoogleSignUp = async () => {
  //   const GoogleAuth = window.gapi.auth2.getAuthInstance();
  //   try {
  //     const GoogleUser = await GoogleAuth.signIn();
  //     const profile = GoogleUser.getBasicProfile();
  //     const id_token = GoogleUser.getAuthResponse().id_token;

  //     // Use the ID token to sign up the user in your system
  //     // This might involve sending the token to your backend
  //     // and handling user creation in AWS Cognito
  //     await signUpWithGoogle(id_token);
  //   } catch (error) {
  //     console.error("Google Sign-In error", error);
  //   }
  // };

  // const signUpWithGoogle = async (googleIdToken) => {
  //   // Implement the logic to sign up the user with the Google ID token
  //   // This should interact with your backend/Cognito setup
  //   // If successful, navigate the user to the home page or dashboard
  //   // If there's an error, display it using the toast

  //   // Example:
  //   try {
  //     const response = await fetch(`${API_URL}/google-signup`, {
  //       method: 'POST',
  //       headers: {
  //         'Content-Type': 'application/json',
  //       },
  //       body: JSON.stringify({ token: googleIdToken }),
  //     });

  //     const data = await response.json();

  //     if (data.success) {
  //       navigate('/');
  //     } else {
  //       throw new Error(data.message || "Failed to sign up with Google");
  //     }
  //   } catch (error) {
  //     toast({
  //       title: "Sign Up Failed",
  //       description: error.message,
  //       status: "error",
  //       duration: 5000,
  //       isClosable: true,
  //     });
  //   }
  // };

  const passwordStrength = (password) => {
    let strength = 0;

    if (password.length >= 8) {
      strength++;
    }
    if (/[A-Z]/.test(password)) {
      strength++;
    }
    if (/[0-9]/.test(password)) {
      strength++;
    }
    if (/[^A-Za-z0-9]/.test(password)) {
      strength++;
    }

    let color;
    if (strength === 0) {
      color = "gray";
    } else if (strength === 1) {
      color = "red";
    } else if (strength === 2) {
      color = "orange";
    } else if (strength === 3) {
      color = "yellow";
    } else {
      color = "green";
    }

    return { strength, color };
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (password !== confirmPassword) {
      setError("Passwords do not match");
      return;
    }

    setIsLoading(true);

    try {
      const result = await signUp(
        firstName,
        lastName,
        email,
        password,
        navigate,
      );
    } catch (err) {
      setError(err.message);
    } finally {
      setIsLoading(false);

      // set a variable in local storage to indicate to the app that the user has signed up
      localStorage.setItem("providerRegistered", true);
    }
  };

  const { strength, color } = passwordStrength(password);

  return (
    <motion.div
      as="form"
      onSubmit={handleSubmit}
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        as="form"
        px={10}
        py={8}
        borderRadius="2xl"
        bg="blackAlpha.800"
        backdropFilter="blur(10px)"
      >
        <Box>
          <Text fontSize="4xl" fontWeight="bold" color="white">
            Become a Mentor
          </Text>
          <HStack mt={1} mb={8}>
            <Text fontSize="md" color="white" fontWeight="bold">
              Already have an account?
            </Text>
            <Button
              variant="link"
              size={"md"}
              color="#A0B100"
              onClick={() => navigate("/login")}
            >
              Sign in
            </Button>
          </HStack>
        </Box>
        <VStack spacing={6} mt={8} mb={4}>
          {/* <HStack width="100%" spacing={4}>

            <IconButton
              aria-label="Sign in with Google"
              icon={<Icon as={FaGoogle} />}
              width={"100%"}
              onClick={handleGoogleSignUp}
            />

            <IconButton aria-label="Sign in with Microsoft" icon={<Icon as={FaMicrosoft} />} width={"100%"} />

          </HStack> */}
          <Flex
            width="100%"
            justifyContent="space-between"
            flexDirection={["column", "row"]}
          >
            <FormControl
              id="firstName"
              isRequired
              width="100%"
              mb={[4, 0]}
              mr={[0, 4]}
            >
              <FormLabel color="white">First Name</FormLabel>
              <Input
                type="text"
                value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                placeholder="Enter your name"
              />
            </FormControl>
            <FormControl id="lastName" isRequired width="100%">
              <FormLabel color="white">Last Name</FormLabel>
              <Input
                type="text"
                value={lastName}
                onChange={(e) => setLastName(e.target.value)}
                placeholder="Your last name"
              />
            </FormControl>
          </Flex>
          <FormControl
            id="email"
            isRequired
            isInvalid={!!error && error.includes("email")}
          >
            <FormLabel color="white">Email</FormLabel>
            <Input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder="Enter your email"
            />
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
          <FormControl
            id="password"
            isRequired
            isInvalid={!!error && error.includes("password")}
          >
            <FormLabel color="white">Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showPassword ? "text" : "password"}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                placeholder="Enter your password"
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClickPassword}
                  background="whiteAlpha.400"
                  color="white"
                  _hover={{ background: "whiteAlpha.500" }}
                >
                  {showPassword ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
          </FormControl>
          <FormControl
            id="confirmPassword"
            isRequired
            isInvalid={!!error && error.includes("confirm")}
          >
            <FormLabel color="white">Confirm Password</FormLabel>
            <InputGroup size="md">
              <Input
                pr="4.5rem"
                type={showConfirmPassword ? "text" : "password"}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                placeholder="Confirm your password"
              />
              <InputRightElement width="4.5rem">
                <Button
                  h="1.75rem"
                  size="sm"
                  onClick={handleClickConfirmPassword}
                  background="whiteAlpha.400"
                  color="white"
                  _hover={{ background: "whiteAlpha.500" }}
                >
                  {showConfirmPassword ? "Hide" : "Show"}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormErrorMessage>{error}</FormErrorMessage>
          </FormControl>
          <FormErrorMessage>{error}</FormErrorMessage>
          {error && (
            <Alert status="error" rounded={6}>
              <AlertIcon />
              {error}
            </Alert>
          )}
          {isLoading ? (
            <Spinner />
          ) : (
            <HStack width="100%" justifyContent="flex-end">
              <Button
                type="submit"
                width="100%"
                variant="ExperienceBankGreen"
                maxW={200}
              >
                Sign Up
              </Button>
            </HStack>
          )}
        </VStack>
      </Box>
    </motion.div>
  );
};

export default ProviderRegistration;
