import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  confirmForgotPassword,
  forgotPassword,
} from "../../cognito/cognitoAuth";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [confirmationCode, setConfirmationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [stage, setStage] = useState("requestReset");
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.7)",
    "rgba(1, 1, 1, 0.7)",
  );

  const validateEmail = (email) => {
    const emailPattern = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
    return emailPattern.test(email);
  };

  const handleSubmitReset = async () => {
    if (!validateEmail(email)) {
      setErrors({ ...errors, email: "Invalid email address" });
      return;
    }

    try {
      await forgotPassword(email);
      setStage("submitNewPassword");
      setErrors({ ...errors, email: null });
    } catch (error) {
      console.error("Error requesting password reset:", error);
      setErrors({ ...errors, email: error.message });
    }
  };

  const handleConfirmReset = async () => {
    if (!validateEmail(email)) {
      setErrors({ ...errors, email: "Invalid email address" });
      return;
    }

    if (!confirmationCode) {
      setErrors({
        ...errors,
        confirmationCode: "Confirmation code is required",
      });
      return;
    }

    if (!newPassword) {
      setErrors({ ...errors, newPassword: "New password is required" });
      return;
    }

    try {
      await confirmForgotPassword(email, confirmationCode, newPassword);
      navigate("/");
    } catch (error) {
      console.error("Error confirming password reset:", error);
      setErrors({ ...errors, newPassword: error.message });
    }
  };

  return (
    <motion.div
      as="form"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        as="form"
        px={10}
        py={8}
        borderRadius="2xl"
        bg="blackAlpha.800"
        backdropFilter="blur(10px)"
      >
        <Box>
          <Box>
            <Text fontSize="4xl" fontWeight="bold">
              {stage === "requestReset" ? "Forgot Password" : "Reset Password"}
            </Text>
            <HStack mt={1} mb={8}>
              <Text fontSize="md" color="white" fontWeight="bold">
                Remembered your password?
              </Text>
              <Button
                variant="link"
                size={"md"}
                color="#A0B100"
                onClick={() => navigate("/login")}
              >
                Login
              </Button>
            </HStack>
          </Box>
          <VStack spacing={6}>
            <FormControl id="email" isRequired isInvalid={errors.email}>
              <FormLabel>
                {stage === "requestReset"
                  ? "Enter your email"
                  : "Enter the confirmation code and your new password"}
              </FormLabel>
              <Input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                placeholder="Enter your email"
              />
              <FormErrorMessage>{errors.email}</FormErrorMessage>
            </FormControl>

            {stage === "submitNewPassword" && (
              <>
                <FormControl
                  id="confirmationCode"
                  isRequired
                  isInvalid={errors.confirmationCode}
                >
                  <FormLabel>Confirmation Code</FormLabel>
                  <Input
                    type="text"
                    value={confirmationCode}
                    onChange={(e) => setConfirmationCode(e.target.value)}
                  />
                  <FormErrorMessage>{errors.confirmationCode}</FormErrorMessage>
                </FormControl>
                <FormControl
                  id="newPassword"
                  isRequired
                  isInvalid={errors.newPassword}
                >
                  <FormLabel>New Password</FormLabel>
                  <Input
                    type="password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <FormErrorMessage>{errors.newPassword}</FormErrorMessage>
                </FormControl>
              </>
            )}

            <HStack spacing={6}>
              {stage === "requestReset" ? (
                <Button variant="ExperienceBankGreen" onClick={handleSubmitReset}>
                  Request Reset
                </Button>
              ) : (
                <Button variant="ExperienceBankGreen" onClick={handleConfirmReset}>
                  Reset Password
                </Button>
              )}
            </HStack>
          </VStack>
        </Box>
      </Box>
    </motion.div>
  );
};

export default ForgotPassword;
