import {
  Alert,
  AlertDescription,
  AlertIcon,
  Avatar,
  Button,
  Center,
  HStack,
  Icon,
  IconButton,
  Input,
  Spinner,
  Stack,
  Text,
  Tooltip,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useRef, useState } from "react";

import { BsChatDots } from "react-icons/bs";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import { FaArrowDown } from "react-icons/fa";

const REACT_APP_WEBSOCKET_API_URL = process.env.REACT_APP_WEBSOCKET_API_URL;

const TeamChat = ({ project_id, userId, provider}) => {
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [ws, setWs] = useState(null);
  const [error, setError] = useState(null);
  const [userProfiles, setUserProfiles] = useState({});
  const [loading, setLoading] = useState(true); // Added a loading state
  const token = useRef(null);
  let errorMsg = null;

  const API_URL = process.env.REACT_APP_API_URL;

  const reloadComponent = () => {
    // Reload the component without refreshing the page
    window.location.reload(false);
  };

  const fetchMessages = async () => {
    // Fetch chat messages
    try {
      setLoading(true); // Set loading to true
      const response = await fetch(`${API_URL}/chat/project/${project_id}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token.current}`,
        },
      });
      const data = await response.json();

      if (data.messages) {
        // Check if 'messages' exists in the response data
        const sortedMessages = data.messages.sort(
          (a, b) => new Date(a.timestamp) - new Date(b.timestamp),
        );
        const fetchedMessages = sortedMessages.map((msg, index) => ({
          index: index,
          sender: msg.user_id,
          content: msg.message,
          timestamp: msg.timestamp,
        }));

        setMessages(fetchedMessages || []);
      } else {
        throw new Error("'messages' is undefined in the response data");
      }

      // Fetch user profile information for each unique user in the messages
      const uniqueUserIds = [
        ...new Set(data.messages.map((msg) => msg.user_id)),
      ];

      for (const id of uniqueUserIds) {
        try {
          const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.current}`,
            },
          });
          setUserProfiles((profiles) => ({
            ...profiles,
            [id]: userResponse.data.body,
          })); // Access userResponse.data.body instead of userResponse.data
        } catch (error) {
          console.error("Failed to fetch user profile:", error);
          errorMsg = "Failed to fetch user profiles. Please try again.";
        }
      }
      setLoading(false);
    } catch (error) {
      setLoading(false); // Set loading to false
      console.error("Failed to fetch chat messages:", error);
      errorMsg = "Failed to fetch chat messages. Please try again.";
    }
  };

  const groupMessagesBySender = (messages) => {
    const groupedMessages = [];
    let currentGroup = null;

    for (const message of messages) {
      if (currentGroup === null || currentGroup.sender !== message.sender) {
        // Create a new group
        currentGroup = {
          sender: message.sender,
          messages: [message],
        };
        groupedMessages.push(currentGroup);
      } else {
        // Add message to the current group
        currentGroup.messages.push(message);
      }
    }

    return groupedMessages;
  };

  useEffect(() => {
    let websocket = null;

    const initializeWebSocket = async () => {
      token.current = await getIdToken();

      // Fetch chat messages
      await fetchMessages();

      // If there was an error, set the error state
      if (errorMsg) {
        setError(errorMsg);
        return;
      }

      // Set up WebSocket connection
      if (ws && ws.readyState === WebSocket.OPEN) {
        return;
      }

      websocket = new WebSocket(
        REACT_APP_WEBSOCKET_API_URL + "?token=" +
        token.current +
        "&projectId=" +
        project_id +
        "&userId=" +
        userId,
      );

      websocket.onopen = () => {
        console.log("WebSocket connected: " + websocket.readyState);
      };

      websocket.onmessage = async (evt) => {
        const message = JSON.parse(evt.data);
        const parsedMessage = {
          sender: message.sender,
          content: message.content,
          timestamp: message.timestamp,
        };

        // Fetch user profile information for new users
        if (!userProfiles[message.sender]) {
          try {
            const userResponse = await axios.get(
              `${API_URL}/users/info/${message.sender}`,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `Bearer ${token.current}`,
                },
              },
            );
            setUserProfiles((profiles) => ({
              ...profiles,
              [message.sender]: userResponse.data.body,
            }));
          } catch (error) {
            console.error("Failed to fetch user profile:", error);
          }
        }

        setMessages((messages) => [...messages, parsedMessage]);
      };

      websocket.onerror = (error) => {
        console.error("WebSocket error:", error);
        setError("Live chat has encountered an error. Please refresh the page.");
      };

      websocket.onclose = () => {
        setError("Live chat has been disconnected. Please refresh the page.");
      };

      setWs(websocket);
    };

    initializeWebSocket();
    setLoading(false); // Set loading to false
  }, []); // Removed the dependency on 'messages'

  useEffect(() => {
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []); // Clean up the WebSocket connection when the component unmounts

  const handleSend = async () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = {
        action: "chat",
        user_id: userId,
        project_id: project_id,
        message: newMessage,
      };

      // Fetch user profile information for new users
      if (!userProfiles[userId]) {
        const userResponse = await axios.get(
          `${API_URL}/users/info/${userId}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.current}`,
            },
          },
        );
        setUserProfiles((profiles) => ({
          ...profiles,
          [userId]: userResponse.data.body,
        })); // Access userResponse.data.body instead of userResponse.data
      }

      ws.send(JSON.stringify(message));
      setNewMessage("");
      console.log("Message sent", message);

      const sentMessage = {
        sender: userId,
        content: newMessage,
        timestamp: new Date().toISOString(), // Use current time for now
      };

      setMessages((prevMessages) => [...prevMessages, sentMessage]);
    } else {
      console.log("WebSocket is not open: " + ws?.readyState);
      setError("WebSocket is not open. Please try again.");
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      handleSend();
    }
  };

  const sendHelloMessage = async () => {
    if (ws && ws.readyState === WebSocket.OPEN) {
      const message = {
        action: "chat",
        user_id: userId,
        project_id: project_id,
        message: "Hello!",
      };

      ws.send(JSON.stringify(message));
      // delay of 1 second
      setTimeout(() => {
        reloadComponent();
      }, 1000);

      window.location.reload(true);
    } else {
      console.log("WebSocket is not open: " + ws?.readyState);
      setError("WebSocket is not open. Please try again.");
    }
  };

  const convertTimestampToTime = (timestamp) => {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  const convertTimestampToDateAndTime = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = String(date.getMinutes()).padStart(2, "0");

    return `${day}/${month}/${year} at ${hours}:${minutes}`;
  };

  // Add a ref for auto-scrolling to the latest message
  const messagesEndRef = useRef(null);

  const scrollMessagesToBottom = () => {
    // Scroll to the latest message when the component renders but not the entire page
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  // Auto-scroll to the latest message whenever the messages state changes
  useEffect(() => {
    scrollMessagesToBottom();
  }, [messages, loading]);

  return (
    <>
      <Stack
        direction="column"
        justify={"space-between"}
        width={"100%"}
      >
        <VStack
          align="start"
          w={"100%"}
          pt={5}
          maxHeight="500px" // Adjust this value as needed
          overflowY="auto" // Enable vertical scrolling
        >

          {loading && (
            <Center w={"100%"} h={"100%"}>
              <Spinner size="xl" />
            </Center>
          )}

          {messages.length === 0 && !loading && (
            <Center w={"100%"}minH={"400px"}>
              <VStack>
                <Icon as={BsChatDots} w={10} h={10} mb={6} />
                <Text
                  color={textColor}
                  fontSize="md"
                  fontWeight="bold"
                  mb={2}
                  maxW={"300px"}
                  textAlign={"center"}
                >
                  Chat allows you to communicate in real-time.
                </Text>
                {/* <Button
                  variant={"ExperienceBankGreen"}
                  onClick={sendHelloMessage}
                  isLoading={loading}
                >
                  Say Hello 👋
                </Button> */}
              </VStack>
            </Center>
          )}

          {!loading && (
            <>
              {groupMessagesBySender(messages).map((group, groupIndex) => (
                <>
                  <HStack key={groupIndex} align="flex-start" mt={4}>
                    <Avatar
                      size={"sm"}
                      name={`${userProfiles[group.sender]?.given_name || "Unknown"
                        } ${userProfiles[group.sender]?.family_name || "User"}`}
                      src={userProfiles[group.sender]?.profile_picture_url}
                    />
                    <VStack align="flex-start" ml={2} spacing={0}>
                      <HStack spacing={0} mb={1}>
                        <Tooltip
                          label={convertTimestampToDateAndTime(
                            group.messages[0].timestamp,
                          )}
                          placement={"top"}
                          openDelay={500}
                          hasArrow
                        >
                          <Text fontSize={"sm"} fontWeight={"bold"}>
                            {`${userProfiles[group.sender]?.given_name ||
                              "Unknown"
                              } ${userProfiles[group.sender]?.family_name || "User"
                              }`}
                          </Text>
                        </Tooltip>
                        <Text fontSize={"sm"} color={"oleniumGray.500"} ml={2}>
                          {convertTimestampToTime(group.messages[0].timestamp)}
                        </Text>
                      </HStack>
                      {group.messages.map((message, index) => (
                        <Text key={index}>{message.content}</Text>
                      ))}
                      <div ref={messagesEndRef} />
                    </VStack>
                  </HStack>
                </>
              ))}
            </>
          )}
        </VStack>
      </Stack>

      <HStack mt={5}>
        <Input
          variant="filled"
          placeholder="Type a message"
          value={newMessage}
          onChange={(event) => setNewMessage(event.target.value)}
          onKeyPress={handleKeyPress}
        />
        <Button
          colorScheme="blue"
          onClick={handleSend}
          isDisabled={!ws || ws.readyState !== WebSocket.OPEN}
        >
          Send
        </Button>
        <IconButton
          // bottom of the page
          aria-label="Scroll to the bottom"
          icon={<FaArrowDown />}
          onClick={scrollMessagesToBottom}
          isDisabled={loading}
        />

      </HStack>

      {error && (
        <Alert status="error" mt={4} borderRadius="6">
          <AlertIcon />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
    </>
  );
};

export default React.memo(TeamChat);
