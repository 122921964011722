import React, { useState } from 'react';
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Text,
  VStack,
  Flex,
  Icon,
  useColorModeValue,
  HStack,
  Tag,
} from '@chakra-ui/react';
import moment from 'moment';
import { BsCalendarX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';

const Overlay = ({ isActive, project_id, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    onClose();
    navigate(`/project/${project_id}/membership`);
  };

  return isActive ? (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.5)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backdropFilter="blur(3px)"
      zIndex="10"
      color="white"
      fontSize="2xl"
      fontWeight="bold"
    >
      <VStack spacing={4}>
        <Text
          fontSize="xl"
          my={4}
          color="white"
          textAlign="center"
          fontWeight="bold"
          maxW={"400px"}
        >
          Enroll in a membership to book meetings or purchase additional hours
        </Text>
        <Button
          variant={"ExperienceBankGreen"}
          mt={2}
          onClick={handleNavigate}
        >
          Enroll in Membership
        </Button>
      </VStack>
    </Box>
  ) : null;
};

const AvailableTimesModal = ({
  isOpen,
  onClose,
  availableTimes,
  membership_active,
  project_id,
  handleTimeSlotClick,
  handleBookSlot,
  selectedSlot,
}) => {
  const textColor = useColorModeValue("black", "white");
  const [currentWeekStart, setCurrentWeekStart] = useState(moment().startOf('isoWeek'));

  const convertToLocalTime = (utcTime) => {
    return new Date(utcTime).toLocaleString('en-US', { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone });
  };

  const groupSlotsByDay = (slots) => {
    const groupedSlots = slots.reduce((acc, slot) => {
      const date = moment(slot.date_time).startOf('day').format('MMMM D, YYYY');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(slot);
      return acc;
    }, {});

    const sortedDates = Object.keys(groupedSlots).sort((a, b) => new Date(a) - new Date(b));

    return sortedDates.map(date => ({
      date,
      slots: groupedSlots[date].sort((a, b) => new Date(a.date_time) - new Date(b.date_time))
    }));
  };

  const getCurrentWeekSlots = () => {
    const start = currentWeekStart.startOf('day');
    const end = moment(currentWeekStart).endOf('isoWeek');
    return availableTimes.filter(slot => {
      const slotDate = moment(slot.date_time);
      return slotDate.isBetween(start, end, null, '[]');
    });
  };

  const handleNextWeek = () => {
    setCurrentWeekStart(prev => moment(prev).add(1, 'week').startOf('isoWeek'));
  };

  const handlePreviousWeek = () => {
    setCurrentWeekStart(prev => moment(prev).subtract(1, 'week').startOf('isoWeek'));
  };

  const currentWeekSlots = getCurrentWeekSlots();
  const groupedSlots = groupSlotsByDay(currentWeekSlots);
  const isPreviousWeekDisabled = currentWeekStart.isSameOrBefore(moment().startOf('isoWeek'));

  const isNextWeekDisabled = () => {
    const nextWeekStart = moment(currentWeekStart).add(1, 'week').startOf('isoWeek');
    const nextWeekSlots = availableTimes.filter(slot => {
      const slotDate = moment(slot.date_time);
      return slotDate.isBetween(nextWeekStart, moment(nextWeekStart).endOf('isoWeek'), null, '[]');
    });

    return nextWeekSlots.length === 0;
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} isCentered>
      <ModalOverlay />
      <ModalContent bg="oleniumBlack.700" borderRadius={8} p={4} pb={8} position="relative">
        <Overlay isActive={!membership_active} onClose={onClose} project_id={project_id} />
        <ModalHeader flex={1} display="flex" align="center" justify="space-between">
          Available Times
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {currentWeekSlots.length === 0 ? (
            <Flex display="flex" flexDirection="column" align="center" justify="center" w="100%" h="200px">
                <Flex
                display="flex"
                flexDirection="column"
                align="center"
                justify="center"
                w="100%"
                h="200px"
                >
                <Icon boxSize={8} color={textColor} as={BsCalendarX} mb={4} />
                <Tag color={textColor} textAlign="center">No available time slots for this week</Tag>

                </Flex>
                <HStack spacing={4} mt={4}>
                    <Button onClick={handlePreviousWeek} variant="outline" isDisabled={isPreviousWeekDisabled}>Previous Week</Button>
                    <Button onClick={handleNextWeek} variant="outline" isDisabled={isNextWeekDisabled()}>Next Week</Button>
                </HStack>
            </Flex>
          ) : (
            <>
              <Box display="flex" flexDirection="column" gap={4} my={8} minH={300}>
                {groupedSlots.map(({ date, slots }) => (
                  <Box key={date}>
                    <Text fontSize="lg" color={textColor} fontWeight="bold" mb={2} my={4} textAlign={"center"}>
                      {date}
                    </Text>
                    <Box display="flex" flexWrap="wrap" gap={4}>
                      {slots.map((slot) => (
                        <Button
                          key={slot.slot_id}
                          variant={selectedSlot && selectedSlot.slot_id === slot.slot_id ? "ExperienceBankGreen" : "outline"}
                          onClick={() => handleTimeSlotClick(slot)}
                          isActive={selectedSlot && selectedSlot.slot_id === slot.slot_id}
                        >
                          {new Date(slot.date_time).toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })}
                        </Button>
                      ))}
                    </Box>
                  </Box>
                ))}
              </Box>
              <Flex justifyContent="space-between" mt={4}>
                <Button onClick={handlePreviousWeek} variant="outline" isDisabled={isPreviousWeekDisabled}>Previous Week</Button>
                <Button onClick={handleNextWeek} variant="outline" isDisabled={isNextWeekDisabled()}>Next Week</Button>
              </Flex>
              <Button
                mt={8}
                variant={"ExperienceBankGreen"}
                onClick={handleBookSlot}
                w={"100%"}
                isDisabled={!selectedSlot}
              >
                Book Selected Slot
              </Button>
            </>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default AvailableTimesModal;
