import React, { useContext } from "react";
import {
  Box,
  Text,
  HStack,
  VStack,
  Heading,
  Tag,
  Divider,
  useColorModeValue,
  Button,
  Icon,
  Flex,
} from "@chakra-ui/react";
import { FiAlertCircle } from "react-icons/fi";
import { UserContext } from "../../../../UserContext";

const BusinessProfileDisplay = ({ business_profile }) => {
  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const buttonColorScheme = useColorModeValue("blue", "cyan");
  const { idToken } = useContext(UserContext);

  if (!business_profile) {
    return (
      <Flex
        direction="column"
        align="center"
        justify="center"
        h={300}
        w="100%"
        p={8}
      >
        <Icon as={FiAlertCircle} w={12} h={12} color="white" />
        <Text color={textColor} fontSize="lg" fontWeight="bold" mt={4}>
          No Business Profile Found
        </Text>
        <Text color={textColor} fontSize="md" textAlign="center" mt={2}>
          It seems like the client has not provided any business profile information.
        </Text>
      </Flex>
    );
  }

  const {
    businessName,
    businessDescription,
    website,
    companyNumber,
    businessSize,
    vatRegistered,
  } = business_profile;

  const handleVatRegistration = () => {
    // Vat Registered:  {BOOL: false}
    if (vatRegistered.BOOL === false) {
      return (
        <Tag colorScheme="yellow" size="sm">
          Not VAT Registered
        </Tag>
      );
    } else {
      return (
        <Tag colorScheme="green" size="sm">
          VAT Registered
        </Tag>
      );
    }
  }

  return (
    <Box pt={8} mb={4}>
      <Box>
        <Heading as="h3" size="md" color={textColor} mb={2}>
          Business Profile
        </Heading>
        <Divider />
        <VStack spacing={4} mt={4} align="start">
          <Text color={textColor} fontSize="lg" fontWeight="bold">
            {businessName}
          </Text>
          <Text color={textColor} fontSize="md">
            {businessDescription}
          </Text>
          <HStack spacing={4}>
            <Tag colorScheme={buttonColorScheme} size="sm">
              {businessSize + " Business"}
            </Tag>
            {handleVatRegistration()}
          </HStack>
          <HStack spacing={4}>
            <Text color={textColor} fontSize="md" fontWeight="bold">
              Company Number:
            </Text>
            <Text color={textColor} fontSize="md">
              {companyNumber}
            </Text>
          </HStack>
          <HStack spacing={4}>
            <Text color={textColor} fontSize="md" fontWeight="bold">
              Website:
            </Text>
            <Text color={textColor} fontSize="md">
              {website}
            </Text>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default BusinessProfileDisplay;
