import React, { useEffect, useState, useContext } from "react";
import {
  Box,
  Button,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  VStack,
  useToast,
  Avatar,
  AvatarGroup,
  Text,
  Spinner,
  Tag,
  Tooltip,
  Alert,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useDisclosure,
  Flex,
} from "@chakra-ui/react";
import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import { motion } from "framer-motion";
import { UserContext } from "../../../../UserContext";
import {
  StreamVideoClient,
  StreamCall,
  StreamVideo,
  useCall,
  useCallStateHooks,
  CallingState,
  StreamTheme,
  ParticipantView,
  CancelCallButton,
  SpeakingWhileMutedNotification,
  ToggleAudioPublishingButton,
  ToggleVideoPublishingButton,
  ScreenShareButton,
  RecordCallButton,
} from '@stream-io/video-react-sdk';

import '@stream-io/video-react-sdk/dist/css/styles.css';
import './meeting.css';

const MotionBox = motion(Box);

const MeetingModal = ({ isOpen, onClose, slot_id, date_time }) => {
  const [meetingDetails, setMeetingDetails] = useState({});
  const [clientProfile, setClientProfile] = useState({});
  const [providerProfile, setProviderProfile] = useState({});
  const [loading, setLoading] = useState(false);
  const [call, setCall] = useState(null);
  const [client, setClient] = useState(null); // Store the client instance
  const [inMeeting, setInMeeting] = useState(false);
  const [isLeaving, setIsLeaving] = useState(false);
  const toast = useToast();

  const { userDetails } = useContext(UserContext);

  useEffect(() => {
    if (isOpen && slot_id) {
      initializeMeeting();
    }
  }, [isOpen, slot_id]);

  const initializeMeeting = async () => {
    setLoading(true);
    try {
      const token = await getIdToken();
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/slots/join/${slot_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const meetingData = response.data.body;
      setMeetingDetails(meetingData);

      const clientProfileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/info/${meetingData.client_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      const providerProfileResponse = await axios.get(`${process.env.REACT_APP_API_URL}/users/info/${meetingData.provider_id}`, {
        headers: { Authorization: `Bearer ${token}` },
      });

      setClientProfile(clientProfileResponse.data.body);
      setProviderProfile(providerProfileResponse.data.body);

      const clientInstance = new StreamVideoClient({
        apiKey: process.env.REACT_APP_STREAM_API_KEY,
        user: {
          id: userDetails.id,
          name: userDetails.name,
          email: userDetails.email,
        },
        token: meetingData.user_token,
      });

      const callInstance = clientInstance.call('default', meetingData.slot_id);
      setClient(clientInstance); // Store the client instance
      setCall(callInstance);
    } catch (error) {
      const errorMessage = error.response ? error.response.data.message : error.message;
      toast({
        title: "Error initializing meeting",
        description: `Unable to get the meeting details. Error: ${errorMessage}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmLeaveMeeting = () => {
    setIsLeaving(true);
  };

  const leaveMeeting = async () => {
    try {
      if (call) {
        await call.endCall();  // Properly end the call
      }
      // Clean up references
      setCall(null);
      setClient(null);  // Clear the client state
      setMeetingDetails({});
      setInMeeting(false);
      setIsLeaving(false);
      onClose();
    } catch (error) {
      const errorMessage = error?.message || 'An unknown error occurred';
      toast({
        title: "Error leaving meeting",
        description: `Unable to leave the meeting. Error: ${errorMessage}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  
  

  const joinMeeting = async () => {
    try {
      if (call) {
        await call.join({ create: true });
        setInMeeting(true);
      }
    } catch (error) {
      toast({
        title: "Error joining meeting",
        description: `Unable to join the meeting. Error: ${error.message || error}`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const convertDate = (date) => {
    const dateObj = new Date(date);
    const currentDate = new Date();

    const resetTime = (d) => new Date(d.getFullYear(), d.getMonth(), d.getDate());
    const currentDateOnly = resetTime(currentDate);
    const dateOnly = resetTime(dateObj);

    const diffTime = dateOnly - currentDateOnly;
    const diffDays = Math.round(diffTime / (1000 * 60 * 60 * 24));

    const options = { hour: "numeric", minute: "numeric" };

    if (diffDays === 0) {
      return `Today at ${dateObj.toLocaleTimeString("en-US", options)}`;
    }

    if (diffDays === 1) {
      return `Tomorrow at ${dateObj.toLocaleTimeString("en-US", options)}`;
    }

    if (diffDays === -1) {
      return `Yesterday at ${dateObj.toLocaleTimeString("en-US", options)}`;
    }

    const dayOptions = { weekday: "long", year: "numeric", month: "long", day: "numeric" };
    return `${dateObj.toLocaleTimeString("en-US", options)} on ${dateObj.toLocaleDateString("en-US", dayOptions)}`;
  };

  const isJoinButtonEnabled = () => {
    const meetingDate = new Date(date_time);
    const currentDate = new Date();
    return meetingDate.toDateString() === currentDate.toDateString() || meetingDate < currentDate;
  };

  return (
    <>
      <Modal isOpen={isOpen} onClose={confirmLeaveMeeting} isCentered size="xl" scrollBehavior="inside" >
        <ModalOverlay />
        <ModalContent p={0} bg="oleniumBlack.600" color="white" width="100%" maxW="100%" h={"100vh"} maxH={"100vh"}>
          <ModalCloseButton />
          <ModalBody my={3} width={"100%"} height={"100vh"} display={"flex"} flexDirection={"column"} justifyContent={"center"} alignItems={"center"}>
            {loading ? (
              <Spinner size="xl" color="white" />
            ) : inMeeting ? (
              <MainCallArea call={call} />
            ) : (
              <Lobby
                clientProfile={clientProfile}
                providerProfile={providerProfile}
                date_time={date_time}
                userDetails={userDetails}
                joinMeeting={joinMeeting}
                convertDate={convertDate}
                isJoinButtonEnabled={isJoinButtonEnabled}
              />
            )}
          </ModalBody>

          {!inMeeting && (
            <ModalFooter mb={4} display={"flex"} justifyContent={"center"} alignItems={"center"}>
              <VStack spacing={4} w={"100%"}>
                <Tooltip label={`You can join the meeting on ${convertDate(date_time)}`} placement="top" hasArrow bg="oleniumBlack.800" color="white" fontSize="md" p={2} borderRadius={8} textAlign={"center"}>
                  <Button
                    variant={"ExperienceBankGreen"}
                    w={200}
                    onClick={joinMeeting}
                    // isDisabled={!isJoinButtonEnabled() && process.env.REACT_APP_ENV === "production"}
                  >
                    Join Meeting
                  </Button>
                </Tooltip>
                <Button onClick={confirmLeaveMeeting} variant="link" w={200}>
                  Close
                </Button>
              </VStack>
            </ModalFooter>
          )}
        </ModalContent>
      </Modal>


      <AlertDialog
        isOpen={isLeaving}
        leastDestructiveRef={undefined}
        onClose={() => setIsLeaving(false)}
      >
        <AlertDialogOverlay>
          <AlertDialogContent bg="oleniumBlack.600" color="white" isCentered>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Leave Meeting
            </AlertDialogHeader>

            <AlertDialogBody>
              Are you sure you want to leave the meeting? This will end the call.
            </AlertDialogBody>

            <AlertDialogFooter>
              <Button onClick={() => setIsLeaving(false)}>
                Cancel
              </Button>
              <Button onClick={leaveMeeting} ml={3}>
                Leave
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const Lobby = ({ clientProfile, providerProfile, date_time, userDetails, joinMeeting, convertDate, isJoinButtonEnabled }) => {
  return (
    <MotionBox
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
      display={"flex"}
      flexDirection={"column"}
      maxW={"390px"}
    >
      <AvatarGroup size="2xl" max={2} display={"flex"} justifyContent={"center"} alignItems={"center"}>
        <Avatar name={`${clientProfile.given_name} ${clientProfile.family_name}`} src={clientProfile.profile_picture_url} border="2px solid white" />
        <Avatar name={`${providerProfile.given_name} ${providerProfile.family_name}`} src={providerProfile.profile_picture_url} border="2px solid white" />
      </AvatarGroup>

      <Text
        mt={8}
        fontSize="xl"
        fontWeight="bold"
        textAlign="center"
      >
        Meeting between {clientProfile.given_name} and {providerProfile.given_name}
      </Text>

      <Tag variant="outline" colorScheme="green" mt={4} fontSize="lg" flexDirection="row" justifyContent="center" alignItems="center" p={2} px={4}>
        {convertDate(date_time)}
      </Tag>

    </MotionBox>
  );
};

const MainCallArea = ({ call }) => {
  const { onOpen, isOpen, onClose } = useDisclosure();
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  useEffect(() => {
    if (callingState === CallingState.ENDED) {
      onOpen();
    }
  }, [callingState, onOpen]);

  return (
    <>
      <StreamVideo client={call.client}>
        <StreamTheme as="main" style={{ width: '100%', height: '100vh' }}>
          <StreamCall call={call} style={{ width: '100%', height: '100vh' }}>
            <MyUILayout />
            {/* Custom call controls positioned at the bottom right */}
            <Box position="fixed" bottom="20px" right="20px">
              <CustomCallControls onLeave={onClose} />
            </Box>
          </StreamCall>
        </StreamTheme>
      </StreamVideo>

      <AlertDialog
        isOpen={isOpen}
        onClose={onClose}
        leastDestructiveRef={undefined}
      >
        <AlertDialogOverlay>
          <AlertDialogContent>
            <AlertDialogHeader fontSize="lg" fontWeight="bold">
              Call Ended
            </AlertDialogHeader>
            <AlertDialogBody>
              The other user has ended the call.
            </AlertDialogBody>
            <AlertDialogFooter>
              <Button onClick={onClose}>
                Close
              </Button>
            </AlertDialogFooter>
          </AlertDialogContent>
        </AlertDialogOverlay>
      </AlertDialog>
    </>
  );
};

const MyUILayout = () => {
  const call = useCall();
  const { useCallCallingState, useLocalParticipant, useRemoteParticipants, useScreenShareState } = useCallStateHooks();
  const callingState = useCallCallingState();
  const localParticipant = useLocalParticipant();
  const remoteParticipants = useRemoteParticipants();
  const { status, screenShareParticipant } = useScreenShareState();

  if (callingState !== CallingState.JOINED) {
    return (
      <Flex width="100%" height="100%" justifyContent="center" alignItems="center" flexDirection="column">
        <Alert status="info" borderRadius={8} flexDirection={"column"} justifyContent={"center"} alignItems={"center"} maxW={400} textAlign={"center"}>
          The call has ended.
        </Alert>
      </Flex>
    );
  }

  return (
    <Flex width="100%" height="95vh" justifyContent="center" alignItems="center" flexDirection="column">
      {/* Render screen share if active */}
      {status === 'enabled' && screenShareParticipant && (
        <Box width="100%" height="100%" bg="black">
          <ParticipantView participant={screenShareParticipant} style={{ width: '100%', height: '100%' }} />
        </Box>
      )}
      
      {/* Render other participants if screen share is not active */}
      {status !== 'enabled' && (
        <MyParticipantList participants={remoteParticipants} />
      )}
      
      <MyFloatingLocalParticipant participant={localParticipant} />
    </Flex>
  );
};





export const CustomCallControls = ({ onLeave }) => {
  const call = useCall();

  return (
    <div className="str-video__call-controls">
      <SpeakingWhileMutedNotification>
        <ToggleAudioPublishingButton />
      </SpeakingWhileMutedNotification>
      <ToggleVideoPublishingButton />
      {/* <ScreenShareButton /> */}
      {/* <RecordCallButton /> */}
    </div>
  );
};


const MyParticipantList = ({ participants }) => {
  return (
    <Flex width="100%" height="100%" justifyContent="center" alignItems="center" flexWrap="wrap">
      {participants.map((participant) => (
        <ParticipantView participant={participant} key={participant.sessionId} style={{ flex: '1 1 auto', minWidth: '300px', height: '100%' }} />
      ))}
    </Flex>
  );
};


const MyFloatingLocalParticipant = ({ participant }) => {
  return (
    <Box
      position="absolute"
      top="15px"
      left="15px"
      width={{ base: '120px', md: '240px' }}
      height={{ base: '67.5px', md: '135px' }}
      boxShadow="rgba(0, 0, 0, 0.1) 0px 0px 10px 3px"
      borderRadius="12px"
    >
      <ParticipantView participant={participant} style={{ width: '100%', height: '100%' }} />
    </Box>
  );
};



export default MeetingModal;
