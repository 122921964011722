import {
  Button,
  Center,
  Flex,
  Grid,
  Heading,
  Hide,
  Icon,
  Modal,
  Select,
  Skeleton,
  Spacer,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";

import axios from "axios";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { BsPersonWorkspace } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";
import WorkCard from "./WorkCard";
import Slots from "./Slots";

function Work() {
  const textColor = useColorModeValue("gray.700", "white");
  const borderColor = useColorModeValue("transparent", "#4A5568");
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });
  const [showSkeleton, setShowSkeleton] = useState(false);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    const fetchProjects = async () => {
      const authentication = await getIdToken();
      try {
        const response = await fetch(`${API_URL}/projects/provider`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        });
        const data = await response.json();

        if (data.statusCode === 200) {
          const projectsData = JSON.parse(data.body);
          if (Array.isArray(projectsData)) {
            const sortedProjects = projectsData.sort((a, b) => {
              const dateA = new Date(a.created_at);
              const dateB = new Date(b.created_at);
              return dateB - dateA;
            });

            const uniqueUserIds = [
              ...new Set(projectsData.map((project) => project.user_id)),
            ];

            for (const id of uniqueUserIds) {
              try {
                const userResponse = await axios.get(
                  `${API_URL}/users/info/${id}`,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `Bearer ${authentication}`,
                    },
                  },
                );


                if (userResponse.data.statusCode === 200) {
                  // update projects with user profile info for the provider (profile_picture, first_name, last_name)
                  const user = userResponse.data.body;
                  const { profile_picture_url, given_name, family_name } = user;

                  const project = sortedProjects.find(
                    (project) => project.user_id === id,
                  );
                  project.profile_picture = profile_picture_url;
                  project.first_name = given_name;
                  project.last_name = family_name;
                } else {
                  throw new Error("Failed to fetch user info.");
                }
              } catch (error) {
                console.error("Error fetching user info:", error);
                setAlert({
                  show: true,
                  status: "error",
                  message: error.message,
                });
              }
            }
            setProjects(sortedProjects);
            setLoading(false);
          } else {
            throw new Error("Failed to fetch projects.");
          }
        } else {
          throw new Error("Failed to fetch projects.");
        }
      } catch (error) {
        console.error("Error fetching projects:", error);
        setAlert({
          show: true,
          status: "error",
          message: error.message,
        });
        setProjects([]);
      } finally {
        setLoading(false);
      }
    };

    // Add setTimeout to setShowSkeleton after 2 seconds
    setTimeout(() => {
      setShowSkeleton(true);
    }, 2000);

    fetchProjects();
  }, []);


  const [selectedTab, setSelectedTab] = useState("all");
  const [selectedDateFilter, setSelectedDateFilter] = useState("all");
  const [isSlotsModalOpen, setIsSlotsModalOpen] = useState(false);

  const handleTabChange = (tabIndex) => {
    switch (tabIndex) {
      case 0:
        setSelectedTab("all");
        break;
      case 1:
        setSelectedTab("active");
        break;
      case 2:
        setSelectedTab("completed");
        break;
      default:
        setSelectedTab("all");
    }
  };

  const handleFilterChange = (event) => {
    setSelectedDateFilter(event.target.value);
  };

  const filteredProjects = projects.filter((project) => {
    const tabFilter =
      selectedTab === "all"
        ? true
        : selectedTab === "active"
        ? project.status === "IN_PROGRESS"
        : selectedTab === "completed"
        ? project.status === "COMPLETE"
        : false;

    const dateFilter = isDateInRange(project.created_at, selectedDateFilter);

    return tabFilter && dateFilter;
  });

  const openSlotsModal = () => setIsSlotsModalOpen(true);
  const closeSlotsModal = () => setIsSlotsModalOpen(false);

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" ml={3} mb={5} mt={5}>
        <Heading as="h1" size="lg">
          My Clients
        </Heading>
        <Button variant="ExperienceBankGreen" size="sm" onClick={openSlotsModal}>
          Open time slots
        </Button>
      </Flex>
      <Tabs
        onChange={(index) => handleTabChange(index)}
        colorScheme="gray"
        border={borderColor}
        mb={5}
      >
        <TabList>
          <Tab>All</Tab>
          <Tab>Active</Tab>
          <Tab>Completed</Tab>
          <Spacer />
          <Hide below="lg">
            <Select
              onChange={handleFilterChange}
              width="150px"
              mb={3}
              rounded="xl"
            >
              <option value="all">All Dates</option>
              <option value="month">This Month</option>
              <option value="week">This Week</option>
              <option value="today">Today</option>
            </Select>
          </Hide>
        </TabList>
        <TabPanels>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
            {projects.length === 0 && !loading && (
              <Center flexDirection="column" height="50vh">
                <Icon as={BsPersonWorkspace} boxSize={12} mx="auto" mt={5} />
                <Text mt={5} textAlign="center">
                  You have no assigned clients.
                </Text>
              </Center>
            )}
          </TabPanel>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
            {projects.length === 0 && !loading && (
              <Center flexDirection="column" height="50vh">
                <Icon as={BsPersonWorkspace} boxSize={12} mx="auto" mt={5} />
                <Text mt={5} textAlign="center">
                  You have no active clients.
                </Text>
              </Center>
            )}
          </TabPanel>
          <TabPanel p={{ base: 0, md: 4 }}>
            {loading && showSkeleton ? (
              <SkeletonGrid />
            ) : (
              <ProjectsGrid projects={filteredProjects} loading={loading} />
            )}
            {projects.length === 0 && !loading && (
              <Center flexDirection="column" height="50vh">
                <Icon as={BsPersonWorkspace} boxSize={12} mx="auto" mt={5} />
                <Text mt={5} textAlign="center">
                  You have no completed clients.
                </Text>
              </Center>
            )}
          </TabPanel>
        </TabPanels>
      </Tabs>

      <Slots isOpen={isSlotsModalOpen} onClose={closeSlotsModal} />
    </Flex>
  );
}

const SkeletonGrid = () => {
  const variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(3, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {[...Array(6)].map((_, index) => (
          <motion.div
            key={index}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <Skeleton
              height="200px"
              borderRadius="2xl"
              startColor="oleniumBlack.300"
              endColor="oleniumBlack.500"
            />
          </motion.div>
        ))}
      </Grid>
    </Flex>
  );
};

const ProjectsGrid = ({ projects, loading }) => {
  const borderColor = useColorModeValue("oleniumGray.300", "oleniumGray.700");

  const handleDeleteSuccess = (id) => {
    console.log(`Deleted project with id ${id}`);
  };

  const handleEditClick = () => {
    console.log("Edit project clicked");
  };

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(3, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {projects.map((project, index) => (
          <motion.div
            key={project.id}
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: index * 0.1, duration: 0.5 },
            }}
            exit={{ opacity: 0, y: -50 }}
          >
            <WorkCard
              id={project.id}
              brief={project.brief}
              service_name={project.service_name}
              problem_statement={project.problem_statement}
              service_icon={project.service_icons && project.service_icons[0]}
              provider_id={project.provider_id}
              status={project.status}
              created_at={project.created_at}
              start_date={project.start_date ? project.start_date : null}
              deadline={project.deadline}
              profile_picture={project.profile_picture}
              provider_first_name={project.first_name}
              provider_last_name={project.last_name}
              client_first_name={project.first_name}
              client_last_name={project.last_name}
              onDeleteSuccess={handleDeleteSuccess}
              onEditClick={handleEditClick}
            />
          </motion.div>
        ))}
      </Grid>
    </Flex>
  );
};

const isDateInRange = (date, range) => {
  const currentDate = new Date();
  const projectDate = new Date(date);
  switch (range) {
    case "all":
      return true;
    case "month":
      return (
        projectDate.getMonth() === currentDate.getMonth() &&
        projectDate.getFullYear() === currentDate.getFullYear()
      );
    case "week":
      const startOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() - currentDate.getDay(),
      );
      const endOfWeek = new Date(
        currentDate.getFullYear(),
        currentDate.getMonth(),
        currentDate.getDate() + (6 - currentDate.getDay()),
      );
      return projectDate >= startOfWeek && projectDate <= endOfWeek;
    case "today":
      return (
        projectDate.getDate() === currentDate.getDate() &&
        projectDate.getMonth() === currentDate.getMonth() &&
        projectDate.getFullYear() === currentDate.getFullYear()
      );
    default:
      return true;
  }
};

export default Work;
