import {
  Box,
  Flex,
  Grid,
  GridItem,
  VStack,
  Icon,
  Text,
  useColorModeValue,
  Heading,
} from "@chakra-ui/react";
import React from "react";
import {
  BsBriefcaseFill,
  BsGraphUp,
  BsBook,
  BsStars,
} from "react-icons/bs";

const HowItWorks = () => {
  const textColor = useColorModeValue("oleniumBlack.600", "white");

  const renderGridItem = (icon, title, description) => (
    <Flex
      direction="column"
      align="start"
      p={4}
      borderWidth="2px"
      borderRadius="2xl"
      borderColor="oleniumBlack.500"
      _hover={{
        borderColor: "#A0B100",
        bg: "oleniumBlack.600",
      }}
    >
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        width="50px"
        height="50px"
        borderRadius="10px"
        bg="#A0B100"
        mb={4}
      >
        <Icon as={icon} boxSize={6} />
      </Box>
      <Text fontSize="xl" fontWeight="bold" mb={2} textAlign={"left"}>
        {title}
      </Text>
      <Text fontSize="sm" color={"oleniumGray.500"} textAlign={"left"}>
        {description}
      </Text>
    </Flex>
  );

  return (
    <>
      <Box width="100%" align="center" px={8}>
        <Box mt={{ base: 4, md: 12 }} align="left" maxW="1000px" w="100%">
          <Box mt={{ base: 4, md: 36 }} align="left">
            <Text
              color={textColor}
              fontSize="4xl"
              fontWeight="bold"
              lineHeight={"1.2"}
            >
              Your Partner in Professional Growth
            </Text>
            <Text
              color={"oleniumGray.500"}
              fontSize="xl"
              lineHeight={"1.2"}
              fontWeight={"700"}
              mt={4}
              maxW={"700px"}
            >
              Unlocked Expertise, we provide a unique platform for you to share your expertise and make a tangible impact on ambitious business owners. By becoming a mentor, you not only help others grow but also develop your own professional network and skills.
            </Text>
          </Box>
        </Box>
      </Box>
      <Box maxWidth={"100%"} my={12} mx="auto" px={8}>
        <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={8} maxW="1000px" mx="auto">
          <GridItem>
            <Box
              w="100%"
              bg="#A0B100"
              borderWidth={2}
              borderColor="#A0B100"
              backdropBlur={4}
              rounded="2xl"
              p={{ base: 6, md: 8 }}
            >
              <VStack alignItems="start" spacing={4}>
                <Heading size="lg" color="white" textAlign={"left"} zIndex={1}>
                  Competitive Compensation
                </Heading>
                <Text fontSize="lg" color="white" mb={4}>
                  Earn £100 per hour for every online mentoring session, with the potential to increase your income by taking on additional clients and sessions. This flexible opportunity allows you to fit sessions around your schedule, from early mornings to late evenings, 7 days a week.
                </Text>
              </VStack>
            </Box>
          </GridItem>
          <GridItem>
            <Box
              w="100%"
              bg="rgba(32, 32, 32, 0.4)"
              borderWidth={2}
              borderColor="#A0B100"
              backdropBlur={4}
              rounded="2xl"
              p={{ base: 6, md: 8 }}
            >
              <VStack alignItems="start" spacing={4}>
                <Heading size="lg" color="white" textAlign={"left"} zIndex={1}>
                  Professional Development
                </Heading>
                <Text fontSize="lg" color="white" mb={4}>
                  Enhance your skills and knowledge through collaboration with a wide array of clients and fellow mentors. Gain fresh insights from diverse business challenges and experiences.
                </Text>
              </VStack>
            </Box>
          </GridItem>
        </Grid>
      </Box>
      <Box width="100%" align={{ base: "left", md: "center" }} px={8}>
        <Box my={48}>
          <Grid
            templateColumns={["repeat(1, 1fr)", "repeat(3, 1fr)"]}
            gap={4}
            maxW="1000px"
            mx="auto"
          >
            <GridItem colSpan={{ base: 3, md: 3, lg: 2 }} align="left">
              <VStack spacing={6} maxW={"700px"} align="start">
                <Text
                  color={textColor}
                  fontSize="5xl"
                  fontWeight="bold"
                  lineHeight={"1.2"}
                >
                  What <Text as="span" bgGradient="linear(to-r, #A0B100, #A0B100)" backgroundClip="text">Unlocked Expertise</Text> specialises in.
                </Text>
              </VStack>
            </GridItem>
            <GridItem colSpan={{ base: 3, md: 3, lg: 3 }}>
              <Grid
                templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }}
                gap={{ base: 4, md: 4, lg: 8, xl: 8 }}
              >
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsGraphUp,
                    "Problem Solving",
                    "Help businesses overcome challenging barriers, similar to those that you may have experienced in your own career."
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsBook,
                    "Accountability",
                    "Keeping businesses on track over a long period to ensure they meet objectives and value your time."
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsBriefcaseFill,
                    "Sector Specific",
                    "Matching mentors with clients focusing on specific sectors or transitioning into new ones."
                  )}
                </GridItem>
                <GridItem maxW={{ base: "100%", md: "500px" }}>
                  {renderGridItem(
                    BsStars,
                    "Special Projects",
                    "Collaborating with clients on specific projects, potentially bringing in additional mentors to supercharge outcomes."
                  )}
                </GridItem>
              </Grid>
            </GridItem>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default HowItWorks;
