// ManageBooking.js
import {
  Box,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  useDisclosure,
  useToast,
  Tag,
  Icon,
  Text,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
} from "@chakra-ui/react";
import { WarningIcon } from "@chakra-ui/icons";
import axios from "axios";
import React, { useState, useEffect } from "react";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import MeetingModal from "./MeetingModal";
import moment from 'moment';

const ManageBooking = ({ project_id, client_id }) => {
  const apiUrl = process.env.REACT_APP_API_URL;
  const [bookings, setBookings] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();
  const { isOpen: isOpenAccept, onOpen: onOpenAccept, onClose: onCloseAccept } = useDisclosure();
  const { isOpen: isOpenReject, onOpen: onOpenReject, onClose: onCloseReject } = useDisclosure();
  const { isOpen: isOpenCancel, onOpen: onOpenCancel, onClose: onCloseCancel } = useDisclosure();
  const { isOpen: isOpenMeeting, onOpen: onOpenMeeting, onClose: onCloseMeeting } = useDisclosure();
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [meetingDetails, setMeetingDetails] = useState({});

  useEffect(() => {
    const fetchBookings = async () => {
      const token = await getIdToken();
      try {
        const response = await axios.get(`${apiUrl}/slots/project/${project_id}`, {
          headers: { Authorization: `Bearer ${token}` },
        });
        setBookings(response.data.slots);
      } catch (error) {
        toast({
          title: "Error fetching bookings",
          description: "Unable to fetch booking data.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      }
    };

    fetchBookings();
  }, [project_id, apiUrl, toast]);

  const handleAction = async (action) => {
    setIsLoading(true);
    const token = await getIdToken();
    const selectedBookingDetails = bookings.find((booking) => booking.slot_id === selectedBooking);
  
    if (!selectedBookingDetails) {
      toast({
        title: "Error",
        description: "Selected booking not found.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      return;
    }
  
    const { status: currentStatus } = selectedBookingDetails;
  
    // Check if the action is valid based on the current status
    const validTransitions = {
      'AWAITING_REVIEW': ['Accept', 'Deny'],
      'ACCEPTED': ['Cancel'],
      'DENIED': ['Cancel'],
      'CANCELLED': [],
    };
  
    if (!validTransitions[currentStatus].includes(action)) {
      toast({
        title: `Invalid Action`,
        description: `Cannot ${action.toLowerCase()} a booking that is ${currentStatus.toLowerCase()}.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
      onCloseAccept();
      onCloseReject();
      onCloseCancel();
      return;
    }
  
    try {
      const response = await axios.put(
        `${apiUrl}/slots/update`,
        { slot_id: selectedBooking, project_id, action },
        {
          headers: { Authorization: `Bearer ${token}` },
        },
      );
  
      if (response.status === 200) {
        toast({
          title: `Booking ${action}ed`,
          description: `You have successfully ${action.toLowerCase()}ed the booking.`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        setBookings((prevBookings) =>
          prevBookings.map((booking) =>
            booking.slot_id === selectedBooking
              ? { ...booking, status: action === 'Accept' ? 'ACCEPTED' : action === 'Deny' ? 'DENIED' : 'CANCELLED' }
              : booking
          )
        );
      }
    } catch (error) {
      toast({
        title: `Error ${action}ing Booking`,
        description: error.response?.data?.message || `An error occurred while ${action.toLowerCase()}ing the booking.`,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
    setIsLoading(false);
    onCloseAccept();
    onCloseReject();
    onCloseCancel();
  };
  

  const renderStatus = (status) => {
    const statuses = {
      ACCEPTED: "green",
      AWAITING_REVIEW: "yellow",
      DENIED: "red",
      CANCELLED: "red",
    };

    const capitalizedStatus = status
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());

    return (
      <Tag variant="outline" colorScheme={statuses[status] || "yellow"}>
        {capitalizedStatus.replace("_", " ")}
      </Tag>
    );
  };

  const isTimeSlotInPast = (date_time) => {
    return new Date(date_time) < new Date();
  };

  return (
    <Box w="100%" rounded="xl" my={4} bg="oleniumBlack.600">
      <Text color="white" fontSize="md" fontWeight="bold" mb={2}>
        Manage Booking
      </Text>
      {bookings.length === 0 ? (
        <Box textAlign="center" color="white" py={10}>
          <Icon as={WarningIcon} boxSize={6} mr={2} />
          No bookings have been made
        </Box>
      ) : (
        <Table variant="simple" colorScheme="whiteAlpha">
          <Thead>
            <Tr>
              <Th color="white">Date</Th>
              <Th color="white">Time</Th>
              <Th color="white">Status</Th>
              <Th color="white">Actions</Th>
              <Th color="white">Meeting Link</Th>
            </Tr>
          </Thead>
          <Tbody>
            {bookings.map((booking) => (
              <Tr key={booking.slot_id}>
                <Td>{moment(booking.date_time).format('MMMM D, YYYY')}</Td>
                <Td>{moment(booking.date_time).format('h:mm A')}</Td>
                <Td>{renderStatus(booking.status)}</Td>
                <Td>
                  {booking.status === "AWAITING_REVIEW" && (
                    <>
                      <Button
                        size="sm"
                        colorScheme="green"
                        mr={3}
                        onClick={() => { setSelectedBooking(booking.slot_id); onOpenAccept(); }}
                      >
                        Accept
                      </Button>
                      <Button
                        size="sm"
                        colorScheme="red"
                        onClick={() => { setSelectedBooking(booking.slot_id); onOpenReject(); }}
                      >
                        Deny
                      </Button>
                    </>
                  )}
                  {booking.status === "ACCEPTED" && !isTimeSlotInPast(booking.date_time) && (
                    <Button
                      size="sm"
                      colorScheme="orange"
                      onClick={() => { setSelectedBooking(booking.slot_id); onOpenCancel(); }}
                    >
                      Cancel
                    </Button>
                  )}
                </Td>
                <Td>
                  {booking.status === "ACCEPTED" && (
                    <Button
                      size="sm"
                      onClick={() => { setSelectedBooking(booking.slot_id); onOpenMeeting(); }}
                    >
                      Join
                    </Button>
                  )}
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}

      <Modal isOpen={isOpenAccept} onClose={onCloseAccept}>
        <ModalOverlay />
        <ModalContent background={"oleniumBlack.600"} color={"white"} borderColor={"oleniumBlack.600"}>
          <ModalHeader>Confirm Acceptance</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to accept this booking?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCloseAccept}>
              Cancel
            </Button>
            <Button
              variant="ExperienceBankGreen"
              onClick={() => handleAction("Accept")}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenReject} onClose={onCloseReject}>
        <ModalOverlay />
        <ModalContent background={"oleniumBlack.600"} color={"white"} borderColor={"oleniumBlack.600"}>
          <ModalHeader>Confirm Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to reject this booking?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCloseReject}>
              Cancel
            </Button>
            <Button
              variant="ExperienceBankGreen"
              onClick={() => handleAction("Deny")}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
      <Modal isOpen={isOpenCancel} onClose={onCloseCancel}>
        <ModalOverlay />
        <ModalContent background={"oleniumBlack.600"} color={"white"} borderColor={"oleniumBlack.600"}>
          <ModalHeader>Confirm Cancellation</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to cancel this booking?</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onCloseCancel}>
              Cancel
            </Button>
            <Button
              variant="ExperienceBankGreen"
              onClick={() => handleAction("Cancel")}
              isLoading={isLoading}
            >
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <MeetingModal
        isOpen={isOpenMeeting}
        onClose={onCloseMeeting}
        slot_id={selectedBooking} // Pass the selectedBooking slot_id here
        date_time={bookings.find(booking => booking.slot_id === selectedBooking)?.date_time}
      />
    </Box>
  );
};

export default ManageBooking;