import React, { useEffect, useState } from "react";
import {
    AspectRatio,
    Box,
    Button,
    CheckboxGroup,
    FormControl,
    FormErrorMessage,
    FormLabel,
    Grid,
    HStack,
    Icon,
    Input,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    Spacer,
    Tag,
    TagCloseButton,
    TagLabel,
    Textarea,
    VStack,
    Wrap,
    WrapItem,
    TagRightIcon,
    useToast,
    Image,
    Spinner,
    Text,
    Alert,
    AlertIcon,
    AlertTitle,
    AlertDescription,
    Badge,
} from "@chakra-ui/react";
import Lottie from 'react-lottie';

import { BsXCircle, BsCircle, BsCheckCircle } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";
import axios from "axios";

import { PiSparkleFill } from "react-icons/pi";
import sidekickLoadingAnimationData from "../../../assets/animations/SidekickLoading.json";
import { set } from "date-fns";
import { is } from "date-fns/locale";

const PortfolioModal = ({
    isOpen,
    onClose,
    initialValues,
    services,
    isEditMode = false,
}) => {

    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: sidekickLoadingAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    const API_URL = process.env.REACT_APP_API_URL;

    const toast = useToast();

    const [title, setTitle] = useState(initialValues?.title || "");
    const [description, setDescription] = useState(
        initialValues?.description || "",
    );
    const [selectedServices, setSelectedServices] = useState(
        initialValues?.services || [],
    );
    const [tags, setTags] = useState(initialValues?.tags || []);
    const [tagInput, setTagInput] = useState("");
    const [images, setImages] = useState(initialValues?.images || []);

    const handleTitleChange = (e) => setTitle(e.target.value);
    const handleDescriptionChange = (e) => setDescription(e.target.value);

    const [isConfirmed, setIsConfirmed] = useState(false);


    const [newImages, setNewImages] = useState([]);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        const loadNewImages = async () => {
            const newUrls = await Promise.all(
                newImages.map(async (image) => {
                    const url = await getDownloadPresignedUrl(image);
                    const parsedBody = JSON.parse(url.body);
                    return parsedBody.signed_url;
                })
            );

            // Append the new URLs to the existing ones
            setImageUrls((prevUrls) => [...prevUrls, ...newUrls]);
        };

        if (newImages.length > 0) {
            loadNewImages();
        }

        setLoading(false);
    }, [newImages]);

    const handleServiceChange = (serviceId, isChecked) => {
        setSelectedServices((prevSelectedServices) => {
            if (isChecked) {
                return [...prevSelectedServices, serviceId];
            } else {
                return prevSelectedServices.filter((id) => id !== serviceId);
            }
        });
    };

    const handleTagInputChange = (e) => setTagInput(e.target.value);
    const handleTagSubmit = (e) => {
        if (e.key === "Enter" && tagInput.trim()) {
            setTags([...tags, tagInput.trim()]);
            setTagInput("");
        }
    };

    const handleTagRemove = (tagToRemove) => {
        setTags(tags.filter((tag) => tag !== tagToRemove));
    };

    const initializeState = (initialValues) => {
        setTitle(initialValues?.title || "");
        setDescription(initialValues?.description || "");
        setSelectedServices(initialValues?.services || []);
        setTags(initialValues?.tags || []);
        setImages(initialValues?.images || []);
    };

    const portfolio_id = initialValues?.portfolio_id || null;

    useEffect(() => {
        if (isOpen) {
            initializeState(initialValues);
        } else {
            // Clear the state when the modal is closed
            initializeState(null);
        }
    }, [isOpen, initialValues]);

    // Initialize state for errors
    const [errors, setErrors] = useState({});

    // Validation function
    const validate = () => {
        const newErrors = {};

        if (title.length <= 1) {
            newErrors.title = "Title must be more than 1 characters.";
        }

        if (description.length <= 50) {
            newErrors.description = "Description must be more than 50 characters.";
        }

        // if description is more than 2200 don't allow it
        if (description.length > 2200) {
            newErrors.description = "Description must be less than 2200 characters.";
        }

        if (selectedServices.length === 0) {
            newErrors.services = "At least one service should be selected.";
        }

        if (images.length === 0) {
            newErrors.images = "At least one image should be selected.";
        }

        setErrors(newErrors);

        // If no errors, return true
        return Object.keys(newErrors).length === 0;
    };

    const getDownloadPresignedUrl = async (imageName) => {


        // add a check to make sure the image hasn't already been signed

        if (imageName.includes("?")) {
            // the image has already been signed so remove the signed part
            imageName = imageName.split("?")[0];
        }

        const token = await getIdToken();
        try {
            const response = await axios.post(`${API_URL}/provider/presigned-url`, {
                file_name: imageName,
                request_type: 'download',
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            return response.data;


        } catch (error) {
            console.error("Error generating download pre-signed URL: ", error);
            toast({
                title: "Error",
                description: "Failed to generate download pre-signed URL.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return null;
        }
    };





    const [imageUrls, setImageUrls] = useState([]);


    // Function to generate a pre-signed URL for uploading an image
    const generatePresignedUrl = async (fileName, contentType) => {
        setLoading(true);
        try {
            const token = await getIdToken();
            const response = await axios.post(`${API_URL}/provider/presigned-url`, {
                file_name: fileName,
                content_type: contentType,
            }, {
                headers: {
                    Authorization: `Bearer ${token}`,
                    "Content-Type": "application/json",
                },
            });

            // Parse the response
            const parsedBody = JSON.parse(response.data.body);
            return parsedBody;
        } catch (error) {
            console.error("Error generating pre-signed URL: ", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to generate pre-signed URL.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return null;
        }
    };

    // Function to upload an image to S3 using the pre-signed URL
    const uploadImageToS3 = async (file) => {
        setLoading(true);
        const response = await generatePresignedUrl(file.name, file.type);

        // Check if the response is valid
        if (!response || !response.signed_url || !response.image_url) {
            console.error("Invalid response for presigned URL: ", response);
            return null;
        }

        const { signed_url, image_url } = response;

        try {
            await axios.put(signed_url, file, {
                headers: {
                    "Content-Type": file.type,
                },
            });
            setLoading(false);
            return image_url;
        } catch (error) {
            console.error("Error uploading image to S3: ", error);
            setLoading(false);
            toast({
                title: "Error",
                description: "Failed to upload image to S3.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return null;
        }
    };

    // Function to handle image selection and upload to S3
    const handleImageSelection = async (event) => {

        setLoading(true);

        const files = Array.from(event.target.files);
        const uploadedImageUrls = [];

        // Upload each image to S3
        for (const file of files) {
            const uploadedImageUrl = await uploadImageToS3(file);
            uploadedImageUrls.push(uploadedImageUrl);
        }

        setImages([...images, ...uploadedImageUrls]);
        // Update imageUrls state to display images
        setImageUrls([...imageUrls, ...uploadedImageUrls]);

        setLoading(false);
    };

    // Function to submit the portfolio item
    const submitPortfolioItem = async () => {
        setLoading(true);
        const isValid = validate();

        if (!isValid) {
            setLoading(false); // Stop the loading state if validation fails
            toast({
                title: "Validation Error",
                description: "Please ensure all required fields, including at least one service, are filled out.",
                status: "error",
                duration: 9000,
                isClosable: true,
            });
            return; // Prevent further execution if validation fails
        }

        if (!validate()) return;

        // remove the signed part of the URL from each image URL
        const imageUrls = images.map((url) => url.split("?")[0]);

        // Include the S3 URLs of the images
        const portfolioItem = {

            title,
            description,
            services: selectedServices,
            tags,
            images: imageUrls,
        };

        const payload = {
            portfolio_item: portfolioItem
        };


        if (isEditMode) {

            // make sure the portfolio ID is included in the payload
            payload.portfolio_item.portfolio_id = portfolio_id;

            try {
                const token = await getIdToken();
                const response = await axios.post(`${API_URL}/provider/portfolio/update`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200 || response.data.statusCode === 200) {
                    toast({
                        title: "Portfolio item updated.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });

                    onClose();
                }


            } catch (error) {
                console.error("Error updating portfolio item: ", error);
                toast({
                    title: "Error",
                    description: "Failed to update portfolio item.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

        } else {
            try {
                const token = await getIdToken();
                const response = await axios.post(`${API_URL}/provider/portfolio/add`, payload, {
                    headers: {
                        Authorization: `Bearer ${token}`,
                        "Content-Type": "application/json",
                    },
                });

                if (response.status === 200 || response.data.statusCode === 200) {
                    toast({
                        title: "Portfolio item saved.",
                        status: "success",
                        duration: 5000,
                        isClosable: true,
                    });
                }

            } catch (error) {
                console.error("Error saving portfolio item: ", error);
                toast({
                    title: "Error",
                    description: "Failed to save portfolio item.",
                    status: "error",
                    duration: 9000,
                    isClosable: true,
                });
                return;
            }

            onClose();
            setLoading(false);

            // Clear the state
            initializeState(null);
        }
    }


    const handleImageDelete = (url) => {
        const newImages = images.filter((image) => image !== url);
        setImages(newImages);
        setImageUrls(imageUrls.filter((imageUrl) => imageUrl !== url));
    };
    
    
    useEffect(() => {
        const loadImages = async () => {
            setLoading(true);
            const urls = await Promise.all(images.map(async (image) => {
                const url = await getDownloadPresignedUrl(image);
                const parsedBody = JSON.parse(url.body);
                return parsedBody.signed_url;
            }));
            setImageUrls(urls);
            setLoading(false);
        };
    
        if (images.length > 0) {
            loadImages();
        } else {
            setImageUrls([]); // Clear imageUrls if there are no images left
        }
    }, [images]);


    return (
        <>
            <Modal isOpen={isOpen} onClose={onClose} size="2xl" closeOnOverlayClick={false}>
                <ModalOverlay />
                <ModalContent bg="oleniumBlack.700">
                    <ModalHeader>
                        {isEditMode ? "Edit Post" : "Add Post"}
                    </ModalHeader>
                    <ModalCloseButton />

                    <ModalBody>
                        <VStack spacing={4} align="stretch">
                        {loading ? (
                            <Box w="100%" h="100%">
                                <Spinner />
                            </Box>
                        ) : (
                            <Box w="100%" h="100%">
                                {/* Display the first image in full width */}
                                {images.length > 0 && (
                                    <Box mb={4}>
                                        <Image
                                            src={imageUrls[0]}
                                            alt="portfolio item"
                                            objectFit="cover"
                                            w="100%"
                                            h="300px"
                                            borderRadius="md"
                                        />
                                        <Icon
                                            as={BsXCircle}
                                            position="absolute"
                                            top="85px"
                                            right="40px"
                                            boxSize="5"
                                            color="red.500"
                                            cursor="pointer"
                                            onClick={() => handleImageDelete(images[0])}
                                        />
                                    </Box>
                                )}

                                {/* Display the remaining images in a grid */}
                                {images.length > 1 && (
                                    <Grid
                                        templateColumns={{ base: "repeat(1, 1fr)", sm: "repeat(4, 1fr)" }}
                                        gap={6}
                                    >
                                        {imageUrls.slice(1).map((url, index) => (
                                            <Box key={index} position="relative">
                                                <AspectRatio ratio={1} w="100%" height={"80px"}>
                                                    <Image
                                                        src={url}
                                                        alt={`portfolio item ${index + 1}`}
                                                        minW={"100%"}
                                                        borderRadius="md"
                                                        objectFit="cover"
                                                    />
                                                </AspectRatio>
                                                <Icon
                                                    as={BsXCircle}
                                                    position="absolute"
                                                    top="10px"
                                                    right="10px"
                                                    boxSize="5"
                                                    color="red.500"
                                                    cursor="pointer"
                                                    onClick={() => handleImageDelete(images[index + 1])}
                                                />
                                            </Box>
                                        ))}
                                    </Grid>
                                )}
                            </Box>
                        )}

                            <FormControl isInvalid={errors.title}>
                                <HStack justifyContent="space-between" mb={2}>
                                    <FormLabel>Title</FormLabel>
                                    {isConfirmed && (
                                        <Tag borderRadius="md" bg="whiteAlpha.100">
                                            <TagRightIcon mr={2} as={PiSparkleFill} />
                                            <TagLabel mr={2}>Autofilled</TagLabel>
                                        </Tag>
                                    )}
                                </HStack>
                                <Input value={title} onChange={handleTitleChange} />
                                <FormErrorMessage>{errors.title}</FormErrorMessage>
                            </FormControl>

                            <FormControl isInvalid={errors.description}>
                                <HStack justifyContent="space-between" mb={2}>
                                    <FormLabel>Description/Brief</FormLabel>
                                    {isConfirmed && (
                                        <Tag borderRadius="md" bg="whiteAlpha.100">
                                            <TagRightIcon mr={2} as={PiSparkleFill} />
                                            <TagLabel mr={2}>Autofilled</TagLabel>
                                        </Tag>
                                    )}
                                </HStack>
                                <Textarea
                                    value={description}
                                    onChange={handleDescriptionChange}
                                />
                                <FormErrorMessage>{errors.description}</FormErrorMessage>
                            </FormControl>

                            <FormControl id="services" isInvalid={errors.services}>
                                <FormLabel>Services involved in this project</FormLabel>
                                <CheckboxGroup>
                                    <Box display="flex" flexWrap="wrap" justifyContent="left">
                                        {services.map((service, index) => (
                                            <ServiceBox
                                                key={index}
                                                value={service.id}
                                                label={service.name}
                                                isChecked={selectedServices.includes(service.id)}
                                                onServiceChange={(isChecked) =>
                                                    handleServiceChange(service.id, isChecked)
                                                }
                                            />
                                        ))}
                                    </Box>
                                </CheckboxGroup>
                                <FormErrorMessage>{errors.services}</FormErrorMessage>
                            </FormControl>

                            <FormControl>
                                <HStack justifyContent="space-between" mb={2}>
                                    <FormLabel>Tags</FormLabel>
                                    {isConfirmed && (
                                        <Tag borderRadius="md" bg="whiteAlpha.100">
                                            <TagRightIcon mr={2} as={PiSparkleFill} />
                                            <TagLabel mr={2}>Autofilled</TagLabel>
                                        </Tag>
                                    )}
                                </HStack>
                                <Input
                                    value={tagInput}
                                    onChange={handleTagInputChange}
                                    onKeyDown={handleTagSubmit}
                                    placeholder="Type and press Enter to add a tag"
                                />
                                <HStack spacing={2} mt={4}>
                                    {tags.map((tag, index) => (
                                        <Tag
                                            key={index}
                                            borderRadius="full"
                                            variant="solid"
                                            bg="blue.500"
                                        >
                                            <TagLabel>{tag}</TagLabel>
                                            <TagCloseButton onClick={() => handleTagRemove(tag)} />
                                        </Tag>
                                    ))}
                                </HStack>
                            </FormControl>
                            <FormControl isInvalid={errors.images}>
                                <FormLabel mb={4}>Add images</FormLabel>
                                <label>
                                    <Input
                                        type="file"
                                        multiple
                                        accept="image/*"
                                        style={{ display: 'none' }}
                                        onChange={handleImageSelection}
                                    />

                                    <Button as="span" variant="ExperienceBankGreen">
                                        Select Images
                                    </Button>
                                </label>
                                <FormErrorMessage>{errors.images}</FormErrorMessage>
                            </FormControl>


                        </VStack>
                    </ModalBody>
                    <ModalFooter>
                        <Button mr={3} onClick={onClose} isDisabled={loading}>
                            Close
                        </Button>
                        <Spacer />
                        <Button
                            form="portfolio-form"
                            variant="ExperienceBankGreen"
                            onClick={submitPortfolioItem}
                            isLoading={loading}
                            isDisabled={loading}
                        >
                            {isEditMode ? "Save" : "Create"}
                        </Button>
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    );
};

// Component for a service tag
const ServiceBox = ({ label, onServiceChange, isChecked, ...rest }) => {
    const [localIsChecked, setLocalIsChecked] = useState(isChecked);

    useEffect(() => {
        setLocalIsChecked(isChecked);
    }, [isChecked]);

    const handleCheck = () => {
        const newCheckState = !localIsChecked;
        setLocalIsChecked(newCheckState);
        onServiceChange(newCheckState);
    };

    return (
        <VStack alignItems="center" justifyContent="center" mb={3}>
            <Wrap spacing={1}>
                <WrapItem>
                    <Tag
                        size="lg"
                        variant={isChecked ? "solid" : "outline"}
                        rounded="full"
                        background={isChecked ? "blue.500" : ""}
                        colorScheme={isChecked ? "blue" : "gray"}
                        onClick={handleCheck}
                        mr={3}
                    >
                        <TagLabel>{label}</TagLabel>
                        <TagRightIcon
                            boxSize="16px"
                            as={isChecked ? BsCheckCircle : BsCircle}
                        />
                    </Tag>
                </WrapItem>
            </Wrap>
        </VStack>
    );
};

export default PortfolioModal;