import {
    Box,
    Button,
    Input,
    Icon,
    AspectRatio,
    HStack,
    Image,
    useToast,
    Heading,
    Spacer,
    Stack,
    Flex,
    Text,
    IconButton,
    Hide,
  } from "@chakra-ui/react";
  import { BsUpload, BsFillFilePersonFill } from "react-icons/bs";
  import React, { useContext, useEffect, useState, useRef } from "react";
  import axios from "axios";
  import { motion } from "framer-motion";
  import { UserContext } from "../../../../UserContext";
import { getDetails, getIdToken } from "../../../../cognito/cognitoAuth";
  
  const ProfilePictureUpload = ({ setProfilePicture, setPreviewImage, profilePicture, previewImage }) => {
    const [loading, setLoading] = useState(false);
    const toast = useToast();
    const fileInputRef = useRef(null);
    const apiUrl = process.env.REACT_APP_API_URL;
    const [details, setDetails] = useState({});

    const { userDetails, setUserDetails } = useContext(UserContext);

    const fadeInUpVariants = (delay) => {
        return {
          initial: {
            opacity: 0,
            y: 50,
          },
          animate: {
            opacity: 1,
            y: 0,
            transition: {
              duration: 0.5,
              delay: delay,
            },
          },
        };
      };

  
    const handleImageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        const file = e.target.files[0];
  
        if (!file.type.startsWith("image/")) {
          toast({
            title: "Invalid file type.",
            description: "Please upload an image file.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return;
        }
  
        setLoading(true);
        const reader = new FileReader();
        reader.onloadend = async () => {
          const base64data = reader.result;
  
          try {
            const token = await getIdToken();
            const body = { profile_picture: base64data };
  
            const response = await axios.post(`${apiUrl}/users/profile-picture`, body, {
              headers: { Authorization: `Bearer ${token}`, "Content-Type": "application/json" },
            });
  
            if (response.status === 200) {
              setLoading(false);
              setProfilePicture(response.data.profile_picture_url);
              setPreviewImage(response.data.profile_picture_url);
  
              toast({
                title: "Profile picture updated.",
                description: "Your profile picture has been successfully updated.",
                status: "success",
                duration: 5000,
                isClosable: true,
              });

              // reload the window to update the profile picture in the header
              window.location.reload();
            }
          } catch (e) {
            setLoading(false);
            console.error(e);
  
            toast({
              title: "An error occurred.",
              description: "Unable to update your profile picture.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
          }
        };
        reader.readAsDataURL(file);
      }
    };

  
    return (
        <Box w="100%" maxW={"600px"} mx="auto" alignContent={"center"}>
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 }}
          >
            <Heading as="h1" size="lg" mb={4} textAlign={{ base: "center" }}>
              Hello, {details.given_name || "there"} 👋
            </Heading>
          </motion.div>
  
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.4 }}
          >
            <Text
              fontSize="md"
              mb={8}
              textAlign={"center"}
              maxW={"370px"}
              mx="auto"
            >
                Let's start by adding a profile picture to your account. This will help other users recognise you.
            </Text>
          </motion.div>
  
          <motion.div
            initial={{ opacity: 0, y: -20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.6 }}
          >
            <Stack spacing={4}>
              <Flex justifyContent="center">
                <Box
                  border="2px"
                  borderColor="gray.200"
                  p={4}
                  borderRadius="xl"
                  borderStyle="dashed"
                  w={{ base: "100%", md: "700px" }}
                  maxWidth={"600px"}
                  mb={4}
                >
                  <HStack spacing={4}>
                    {previewImage || userDetails.profilePicture ? (
                      <AspectRatio ratio={1} w={12} h={12} borderRadius="lg">
                        <Image
                          src={previewImage || userDetails.profilePicture}
                          alt="Profile Picture"
                          rounded="lg"
                          objectFit="cover"
                          aspectRatio={1}
                        />
                      </AspectRatio>
                    ) : (
                      <Box
                        bg="oleniumBlack.300"
                        w={12}
                        h={12}
                        borderRadius="lg"
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        aspectRatio={1}
                      >
                        <Icon as={BsFillFilePersonFill} w={6} h={6} color="white" />
                      </Box>
                    )}
                    <Text fontSize="md">{profilePicture ? "Change" : "Your profile picture"}</Text>
                    <Spacer />
                    <Input
                      ref={fileInputRef}
                      type="file"
                      display="none"
                      onChange={handleImageChange}
                    />
  
                    <Hide below="md">
                      <Button
                        onClick={() => {
                          document.querySelector('input[type="file"]').click();
                        }}
                      >
                        Browse
                      </Button>
                    </Hide>
  
                    <Hide above="md">
                      <IconButton
                        icon={<Icon as={BsUpload} />}
                        onClick={() => {
                          if (fileInputRef.current) {
                            fileInputRef.current.click();
                          }
                        }}
                      />
                    </Hide>
                  </HStack>
                </Box>
              </Flex>
            </Stack>
          </motion.div>
      </Box>
    );
  };
  
  export default ProfilePictureUpload;
  