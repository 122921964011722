import {
  Avatar,
  Box,
  Button,
  Flex,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Progress,
  Spacer,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";

import { Link, useNavigate } from "react-router-dom";
import Card from "../../Shared/Cards/Card";
import CardBody from "../../Shared/Cards/CardBody";
import CardHeader from "../../Shared/Cards/CardHeader";
import { IoPersonCircleOutline } from "react-icons/io5";
import { BsExclamationCircleFill, BsCheckCircleFill } from "react-icons/bs";

import React from "react";

const ProjectCard = ({
  id,
  brief,
  service_name,
  problem_statement,
  service_icon,
  provider_name,
  provider_id,
  status,
  created_at,
  deadline,
  profile_picture,
  provider_first_name,
  provider_last_name,
  onDeleteSuccess,
  onEditClick,
  active_subscription
}) => {
  const bgColor = useColorModeValue("white", "oleniumBlack.600");
  const borderColor = useColorModeValue("oleniumGray.200", "oleniumBlack.700");
  const textColor = useColorModeValue("oleniumGray.700", "oleniumGray.200");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuDisclosure = useDisclosure();

  const [progress, setProgress] = React.useState(0);

  const link = "/project/" + id;
  const navigate = useNavigate();

  const projectStatusColor = (projectStatus) => {
    const colors = {
      UNASSIGNED: "gray",
      AWAITING_ASSIGNMENT: "yellow",
      AWAITING_ACCEPTANCE: "yellow",
      IN_PROGRESS: "green",
      COMPLETED: "green",
      CLOSED: "gray",
    };
    return colors[projectStatus] || "gray";
  };

  const deleteProject = () => {
    // API call to delete project here, this should only be available if the project is unassigned
    onDeleteSuccess(id);
    onClose();
  };

  const daysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = Math.abs(due - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const renderStatus = (status) => {
    if (status === "AWAITING_ASSIGNMENT") {
      return "Awaiting Assignment";
    } else if (status === "AWAITING_ACCEPTANCE") {
      return "Awaiting Consultant Acceptance";
    } else if (status === "IN_PROGRESS") {
      return "In Progress";
    } else if (status === "COMPLETED") {
      return "Completed";
    } else if (status === "CLOSED") {
      return "Closed";
    }
  };

  React.useEffect(() => {
    const percentage = calculatePercentage(created_at, deadline);
    if (!isNaN(percentage)) {
      setProgress(percentage);
    }
  }, [created_at, deadline]);

  const calculatePercentage = (created_at, deadline) => {
    const start = new Date(created_at);
    const due = new Date(deadline);
    const today = new Date();
    const total = due - start;
    const elapsed = today - start;
    const percentage = (elapsed / total) * 100;

    if (percentage > 100) {
      return 100;
    } else if (percentage < 0) {
      return 0;
    } else {
      return percentage;
    }
  };

  const convertToDaysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const timeDiff = due.getTime() - today.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (diffDays > 0) {
      if (diffDays === 1) {
        return "1 day left";
      } else {
        return diffDays + " days left";
      }
    } else if (diffDays === 0) {
      return "Due today";
    } else {
      const diffPastDays = Math.abs(diffDays);
      return diffPastDays + " days past due";
    }
  };

  // Helper function to truncate text to 500 characters
  const truncateText = (text, maxLength) => {
    if (text.length > maxLength) {
      return text.slice(0, maxLength) + "...";
    }
    return text;
  };

  return (
    <GridItem colSpan={1} rowSpan={1} w="100%">
      <Card
        alignSelf="flex-start"
        p={5}
        borderRadius={"xl"}
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="2px"
        _hover={{
          shadow: "lg",
          cursor: "pointer",
          borderColor: "blue.500",
          borderWidth: "2px",
        }}
        onClick={() => navigate(link)}
      >
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={5}>
              <Text mb="0px">
                Are you sure you want to delete this project?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button color="red.500" variant="solid" onClick={deleteProject}>
                Confirm Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <CardHeader>
          <Flex justify="space-between" w="100%">
            <Flex>
              <Box
                w="50px"
                h="50px"
                borderRadius="50%"
                bg={useColorModeValue("oleniumGray.400", "oleniumBlack.200")}
                mr="16px"
                display="flex"
                alignItems="center"
                justifyContent="center"
                aspectRatio={1}
              >
                <Image
                  src={service_icon}
                  alt={service_name}
                  width={{ base: "30px", md: "30px" }}
                  height={{ base: "30px", md: "30px" }}
                />
              </Box>

              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  mb="8px"
                >
                  {service_name}
                </Text>
                <Stack direction="row">
                  <Tag
                    size="sm"
                    variant="outline"
                    colorScheme={projectStatusColor(status)}
                    rounded={"full"}
                  >
                    {renderStatus(status)}
                  </Tag>
                </Stack>
              </Flex>
            </Flex>
            {/* <Menu
              isOpen={menuDisclosure.isOpen}
              onClose={menuDisclosure.onClose}
            >
              <MenuButton
                onClick={menuDisclosure.onOpen}
                alignSelf="flex-start"
                zIndex={5}
              >
                <Icon
                  as={IoEllipsisVerticalSharp}
                  color="gray.400"
                  w="20px"
                  h="20px"
                />
              </MenuButton>
              <MenuList>
                <MenuItem icon={<EditIcon />} onClick={onEditClick}>
                  Edit this project
                </MenuItem>
                <MenuItem
                  color={"red.400"}
                  icon={<DeleteIcon />}
                  onClick={onOpen}
                >
                  Delete
                </MenuItem>
              </MenuList>
            </Menu> */}
          </Flex>
        </CardHeader>

        <CardBody>
          <Flex direction="column">
            <Box mb={5}>
              <Text
                color="gray.400"
                fontSize="sm"
                fontWeight="normal"
                mt="16px"
                minH={"200px"}
              >
                {truncateText(problem_statement, 400)}
              </Text>
            </Box>

            <Flex justify="space-between" w="100%">
              <>
                {provider_first_name ? (
                  <HStack>
                    <Avatar
                      size="sm"
                      name={provider_first_name + " " + provider_last_name}
                      src={profile_picture}
                    />
                    <Text
                      color="gray.100"
                      fontSize="md"
                      fontWeight="bold"
                      pl={2}
                    >
                      {provider_first_name + " " + provider_last_name}
                    </Text>
                  </HStack>
                ) : (
                  <HStack>
                    <Avatar
                      name="?"
                      size="sm"
                      fontWeight="bold"
                      bg={"#A0B100"}
                      icon={<Icon as={IoPersonCircleOutline} />}
                    />

                    <Text
                      color="gray.100"
                      fontSize="md"
                      fontWeight="bold"
                      pl={2}
                    >
                      Unassigned
                    </Text>
                  </HStack>

                )}
              </>
              <Flex direction="column">
                <Link to={link}>
                  <Tooltip label={active_subscription ? "Active Subscription" : "This project requires a subscription"} placement="top" hasArrow bg="blackAlpha.800" color="white" px={4} py={2} borderRadius={8} textAlign={"center"}>
                    <Button leftIcon={<Icon as={active_subscription ? BsCheckCircleFill : BsExclamationCircleFill} colorScheme={active_subscription ? "orange" : "green"} />} size="sm">
                      {active_subscription ? "View Project" : "Get Started"}
                    </Button>
                  </Tooltip>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default ProjectCard;
