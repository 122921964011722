import { CloseIcon } from "@chakra-ui/icons";
import {
  Avatar,
  AvatarBadge,
  Badge,
  Box,
  Button,
  Flex,
  HStack,
  Hide,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Show,
  Spacer,
  Text,
  VStack,
  useColorMode,
  useMediaQuery,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import PropTypes from "prop-types";
import React, { useContext, useEffect, useState } from "react";
import { AiOutlineInfoCircle, AiOutlinePoweroff } from "react-icons/ai";
import { GiHamburgerMenu } from "react-icons/gi";
import {
  VscLayoutActivitybarLeft,
  VscLayoutSidebarLeft,
} from "react-icons/vsc";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../UserContext";
import { signOut } from "../../cognito/cognitoAuth";
import { BsX } from "react-icons/bs";
import { is } from "date-fns/locale";

const MotionIconButton = motion(IconButton);

const AnimatedAvatarBadge = motion(AvatarBadge);

const Header = ({ onMenuClick, onMobileMenuClick, isOpen, onLogout }) => {
  const { colorMode, toggleColorMode } = useColorMode();
  const iconColor = { light: "gray.600", dark: "white" };

  const [isTablet] = useMediaQuery("(max-width: 768px)");
  const [show, setShow] = useState(false);

  const { userDetails } = useContext(UserContext);

  const nativate = useNavigate();

  useEffect(() => {
    if (isTablet) {
      setShow(true);
    } else {
      setShow(false);
    }
  }, [isTablet]);

  const iconVariants = {
    hidden: { opacity: 0, scale: 0.8 },
    visible: { opacity: 1, scale: 1 },
  };

  const handleLogout = () => {
    if (typeof onLogout === "function") {
      onLogout();
    }
    signOut(nativate);
    window.location.reload();
  };

  const [isFreelancerBoxVisible, setIsFreelancerBoxVisible] = useState(() => {
    // Check local storage for the state; default to true if not found
    const storedVisibility = localStorage.getItem("freelancerBoxVisible");
    return storedVisibility ? JSON.parse(storedVisibility) : true;
  });

  const toggleFreelancerBoxVisibility = () => {
    setIsFreelancerBoxVisible((prev) => {
      const newValue = !prev;
      // Update local storage
      localStorage.setItem("freelancerBoxVisible", JSON.stringify(newValue));
      return newValue;
    });
  };

  const displayedUserRole = (role) => {
    switch (role) {
      case "Providers":
        return "Mentor";
      case "Clients":
        return "Client";
      case "Admins":
        return "Admin";
      case "ProviderApplicant":
        return "Awaiting Approval";
      default:
        return "Unknown Role";
    }
  };

  const isProviderRegistered = localStorage.getItem("providerRegistered") === "true";

  const handleCancelApplication = () => {
    localStorage.setItem("providerRegistered", "false");
    // Optionally, you can add any additional logic here, like refreshing the page or updating state
    window.location.reload(); // Reload to reflect changes
  };

  return (
    <header>
      <Box
        bg={colorMode === "light" ? "oleniumGray.100" : "oleniumBlack.600"}
        w="100%"
        px={isOpen ? 4 : 6}
        color="white"
        rounded="3xl"
        py={3}
        mb={6}
        zIndex={100}
      >
        <Flex
          as="nav"
          align="center"
          justify={{
            base: "space-between",
            md: "space-between",
            lg: "space-between",
          }}
          wrap="wrap"
          w="100%"
          maxW="100%"
          mx="auto"
        >
          {show && !isProviderRegistered && (
            <MotionIconButton
              aria-label="Toggle menu"
              icon={isOpen ? <CloseIcon /> : <GiHamburgerMenu w={5} h={5} />}
              color={iconColor[colorMode]}
              onClick={onMobileMenuClick}
              rounded="full"
              initial="hidden"
              animate="visible"
              exit="hidden"
              variants={iconVariants}
            />
          )}

          <Show above="lg">
            {!isTablet && !isProviderRegistered && (
              <MotionIconButton
                aria-label="Toggle menu"
                icon={
                  isOpen ? (
                    <VscLayoutSidebarLeft />
                  ) : (
                    <VscLayoutActivitybarLeft />
                  )
                }
                onClick={onMenuClick}
                rounded="full"
                color={iconColor[colorMode]}
                initial="hidden"
                animate="visible"
                exit="hidden"
                variants={iconVariants}
              />
            )}
          </Show>

          <>
            <Spacer />
          </>

          <HStack spacing={2} alignItems="center">

            {userDetails && (
              <Hide below="md">
                <Button
                  variant="ExperienceBankGreen"
                  onClick={isProviderRegistered ? handleCancelApplication : () => nativate("/new-mentor")}
                  size="sm"
                  mr={4}
                >
                  {isProviderRegistered ? "Cancel Application" : "Meet a Mentor"}
                </Button>
              </Hide>
            )}

            <Menu>
              <MenuButton
                size="sm"
                name={userDetails?.firstName}
                src=""
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  textAlign: "center",
                }}
              >
                <Avatar
                  src={userDetails?.profilePicture}
                  name={userDetails?.firstName}
                  w="40px"
                  h="40px"
                >
                  <AnimatedAvatarBadge
                    boxSize="0.8em"
                    bg="green.500"
                    borderColor={
                      colorMode === "light" ? "white" : "oleniumBlack.600"
                    }
                    borderWidth={3}
                    transitionDelay={0.5}
                    initial={{ scale: 0 }} // Initial scale of 0 for animation
                    animate={{ scale: 1 }} // Scale up to 1 during animation
                    transition={{ duration: 0.5 }} // Animation duration of 0.5 seconds
                  />
                </Avatar>
              </MenuButton>

              <MenuList
                bg={colorMode === "light" ? "white" : "oleniumBlack.700"}
                rounded="xl"
                zIndex={100}
              >
                <MenuItem
                  bg="transparent"
                  px={4}
                  onClick={() => nativate("/my-account")}
                >
                  <VStack align="flex-start" spacing={0} w="100%">
                    <HStack spacing={2} justify={"space-between"} w="100%">
                      <Text
                        fontWeight="bold"
                        color={colorMode === "light" ? "black" : "white"}
                      >
                        Hello, {userDetails?.firstName}
                      </Text>
                      <Badge
                        variant="outline"
                        rounded={"full"}
                        px={2}
                        py={1}
                        size="xs"
                        fontSize="0.5em"
                        colorScheme={
                          userDetails?.userRole === "Providers" ||
                            userDetails?.userRole === "Clients" ||
                            userDetails?.userRole === "Admins"
                            ? "green"
                            : "yellow"
                        }
                      >
                        {displayedUserRole(userDetails?.userRole)}
                      </Badge>
                    </HStack>
                    <Text
                      fontSize="sm"
                      color={
                        colorMode === "light" ? "oleniumGray.500" : "white"
                      }
                    >
                      {userDetails?.email}
                    </Text>
                  </VStack>
                </MenuItem>

                {userDetails?.userRole === "ProviderApplicant" && (
                  <Box
                    w="100%"
                    maxW="250px"
                    m={4}
                    p={2}
                    borderRadius={"xl"}
                    border={"1px solid"}
                    borderColor={
                      colorMode === "light"
                        ? "oleniumGray.100"
                        : "oleniumGray.700"
                    }
                  >
                    <Flex align="top" justify="space-between">
                      <Icon
                        as={AiOutlineInfoCircle}
                        color={"yellow.500"}
                        m={1}
                        mr={2}
                      />
                      <Text fontSize="xs" pb={2} color={"white"}>
                        We're reviewing your account to become a mentor on Unlocked Expertise.
                      </Text>
                    </Flex>
                    <Button
                      variant="ExperienceBankGreen"
                      size="xs"
                      w="100%"
                      onClick={() => nativate("/my-account")}
                    >
                      Learn More
                    </Button>
                  </Box>
                )}

                <MenuDivider />
                <MenuItem
                  onClick={handleLogout}
                  icon={<AiOutlinePoweroff />}
                  color="red.500"
                  bg={"transparent"}
                  __hover={{ bg: "oleniumGray.100" }}
                >
                  Logout
                </MenuItem>
              </MenuList>
            </Menu>
          </HStack>
        </Flex>
      </Box>
    </header>
  );
};

Header.propTypes = {
  siteTitle: PropTypes.string,
};

Header.defaultProps = {
  siteTitle: ``,
};

export default Header;
