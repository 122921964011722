import {
  Box,
  Center,
  Divider,
  HStack,
  Icon,
  Text,
  VStack,
} from "@chakra-ui/react";
import React from "react";

import { BsBriefcase, BsClock, BsClipboardCheck } from "react-icons/bs";

const Values = () => {
  const ourValues = [
    {
      icon: BsBriefcase,
      title: "Work with Ambitious Clients",
      description:
        "Connect with driven business owners across various sectors who are actively seeking mentorship, coaching, and guidance for their personal and business growth.",
    },
    {
      icon: BsClock,
      title: "Flexible Availability",
      description:
        "Set your schedule by uploading your availability three months in advance on a rolling basis, offering as little as 1 hour per month. The platform allows you to manage sessions easily around your existing commitments.",
    },
    {
      icon: BsClipboardCheck,
      title: "Seamless Experience",
      description:
        "Our platform is designed to make the mentoring and coaching process smooth and efficient, providing integrated tools for communication, file sharing, and video calls—all in one place.",
    },
  ];

  const renderFeature = (icon, title, description) => (
    <HStack align="start" justify={"center"} spacing={4} w={"100%"} h={"100%"}>
      <Box
        p={2}
        aspectRatio={1}
        rounded="10px"
        bg="oleniumBlack.100"
        display="flex"
        alignItems="center"
        justifyContent="center"
        marginRight={4}
      >
        <Icon as={icon} boxSize={6} />
      </Box>
      <VStack
        align="start"
        spacing={1}
        justifyContent="center"
        w={"100%"}
        h={"100%"}
      >
        <Text fontSize="xl" fontWeight="bold" textAlign={"left"}>
          {title}
        </Text>
        <Text
          fontSize="md"
          color={"oleniumGray.700"}
          textAlign={"left"}
          style={{ overflowWrap: "break-word" }}
        >
          {description}
        </Text>
      </VStack>
    </HStack>
  );

  return (
    <Box w="80%" py={8} mt={48} mx="auto" align="center">
      <Center w="100%">
        <VStack
          align="center"
          mx="auto"
          spacing={8}
          maxW={"600px"}
          justify={"center"}
        >
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            fontWeight="bold"
            mb={8}
            textAlign="center"
          >
            Why Join Unlocked Expertise?
          </Text>
          {ourValues.map((feature, index) => (
            <Box key={index} w={"100%"} h={"100%"} maxW={"600px"}>
              {renderFeature(feature.icon, feature.title, feature.description)}
              {index !== ourValues.length - 1 && (
                <Divider
                  orientation="horizontal"
                  borderColor="oleniumBlack.500"
                  opacity={0.2}
                  my={2}
                />
              )}
            </Box>
          ))}
        </VStack>
      </Center>
    </Box>
  );
};

export default Values;
