import React, { useEffect, useState } from "react";
import axios from "axios";
import { getIdToken } from "../../../cognito/cognitoAuth";
import {
  Flex,
  Grid,
  Heading,
  Skeleton,
  Image,
  Box,
  Text,
  Button,
  HStack,
  IconButton,
  Icon,
  VStack,
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import PortfolioItemModal from "./PortfolioItemModal";
import PortfolioModal from "./PortfolioForm";
import { BsArrowRepeat, BsPostcardHeartFill } from "react-icons/bs";

function Work() {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null); // State for the selected item
  const [isModalOpen, setIsModalOpen] = useState(false); // State to control modal visibility
  const [services, setServices] = useState([]);
  const [alert, setAlert] = useState({
    show: false,
    status: "success",
    message: "",
  });

  const API_URL = process.env.REACT_APP_API_URL;

  const [isPortfolioModalOpen, setIsPortfolioModalOpen] = useState(false);
  const openPortfolioModal = () => setIsPortfolioModalOpen(true);


  const fetchServices = async () => {
    try {
      const IdToken = await getIdToken();
      const response = await axios.get(API_URL + "/services", {
        headers: {
          Authorization: `Bearer ${IdToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        const servicesData = JSON.parse(response.data.body);
        if (Array.isArray(servicesData)) {
          setServices(servicesData);
        } else {
          throw new Error("Failed to fetch services.");
        }
      } else {
        throw new Error("Failed to fetch services.");
      }
    } catch (error) {
      console.error("Error fetching services", error);
      setAlert({
        show: true,
        status: "error",
        message: error.message,
      });
    } finally {
      setLoading(false); // Set loading to false even if an error occurs
    }
  };

  const fetchProjects = async () => {
    setLoading(true); // Set loading to true at the beginning of the function
    setError(null); // Reset error state

    try {
      const token = await getIdToken();
      const response = await axios.get(`${API_URL}/portfolio/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      // Assuming the Lambda response body is in the correct format
      const portfolioItems = response.data;
      setProjects(portfolioItems);
    } catch (error) {
      console.error("Error fetching projects:", error);
      setError(error.message || 'Error fetching portfolio items'); // Set error state
    } finally {
      setLoading(false); // Set loading to false at the end of the function
    }
  };


  useEffect(() => {
    fetchServices();
    fetchProjects();
  }, []);

  if (loading) {
    return <SkeletonGrid />;
  }

  if (error) {
    return <Text>Failed to load projects.</Text>;
  }

  const handleItemClick = (item) => {
    setSelectedItem(item);
    setIsModalOpen(true);
  };

  const handlePortfolioSubmit = async (values) => {
    try {
      const token = await getIdToken();
      const response = await axios.post(
        `${API_URL}/portfolio`,
        {
          title: values.title,
          description: values.description,
          images: values.images,
          service_id: values.service_id,
        },
        {
          headers: {
            Authorization: token,
          },
        },
      );

      // Parsing the JSON string in the response body
      const parsedData = JSON.parse(response.data.body);
      setProjects([...projects, parsedData]);
      setAlert({
        show: true,
        status: "success",
        message: "Portfolio item created successfully.",
      });
    } catch (error) {
      console.error("Error creating portfolio item:", error);
      setAlert({
        show: true,
        status: "error",
        message: error.message,
      });
    } finally {
      setIsPortfolioModalOpen(false);
    }
  }

  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" ml={3} mb={5} mt={5}>
        <Heading as="h1" size="lg">Posts</Heading>

        <HStack spacing={3}>
          <IconButton aria-label="Refresh" icon={<BsArrowRepeat />} onClick={fetchProjects} rounded={"full"} />
          <Button variant="ExperienceBankGreen" size="sm" onClick={openPortfolioModal}>
            Create Post
          </Button>
        </HStack>
      </Flex>
      {projects.length === 0 && (
        <VStack spacing={2} mt={10}>
          <Icon as={BsPostcardHeartFill} w={16} h={16} color="gray.300" />
          <Text textAlign="center" fontSize="lg" mt={5}>
            You've not created any posts yet.
          </Text>
          <Button variant="ExperienceBankGreen" size="sm" mt={5} onClick={openPortfolioModal}>
            Create Post
          </Button>
        </VStack>
      )}

      <ProjectsGrid projects={projects} onItemClick={handleItemClick} />
      <PortfolioItemModal
        isOpen={isModalOpen}
        onClose={
          () => {
            setIsModalOpen(false);
          }
        }
        selectedPortfolioItem={selectedItem}
      />
      {isPortfolioModalOpen && (
        <PortfolioModal
          isOpen={isPortfolioModalOpen}
          onClose={() => {
            setIsPortfolioModalOpen(false);
            fetchProjects();
          }}
          initialValues={{}} // Pass empty initialValues for new portfolio
          onSubmit={handlePortfolioSubmit} // You need to implement this function
          services={services}
          isEditMode={false}
        />
      )}

    </Flex>
  );
}

const SkeletonGrid = () => {
  const variants = {
    hidden: { opacity: 0, scale: 0 },
    visible: (i) => ({
      opacity: 1,
      scale: 1,
      transition: {
        delay: i * 0.1,
      },
    }),
  };

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(4, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {[...Array(6)].map((_, index) => (
          <motion.div
            key={index}
            custom={index}
            initial="hidden"
            animate="visible"
            variants={variants}
          >
            <Skeleton
              height="200px"
              borderRadius="2xl"
              startColor="oleniumBlack.300"
              endColor="oleniumBlack.500"
            />
          </motion.div>
        ))}
      </Grid>
    </Flex>
  );
};

const ProjectsGrid = ({ projects, onItemClick }) => {

  if (!Array.isArray(projects)) {
    // Optionally, handle this scenario differently, like showing an error message
    return null;
  }

  return (
    <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
      <Grid
        templateColumns={{
          sm: "1fr",
          md: "repeat(1, 1fr)",
          lg: "repeat(2, 1fr)",
          xl: "repeat(3, 1fr)",
          "2xl": "repeat(4, 1fr)",
        }}
        gap={8}
        mt={5}
      >
        {projects.map((project, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 50 }}
            animate={{
              opacity: 1,
              y: 0,
              transition: { delay: index * 0.1, duration: 0.5 },
            }}
            exit={{ opacity: 0, y: -50 }}
            onClick={() => onItemClick(project)}
          >
            <Box position="relative" borderRadius="xl" overflow="hidden" borderWidth="2px" borderColor="transparent" _hover={{ borderColor: "#A0B100" }}>
              <Image
                src={project.images[0]}
                alt="portfolio item"
                objectFit="cover"
                w={"100%"}
                h={"250px"}
              />

              <Box
                position="absolute"
                top={0}
                left={0}
                w={"100%"}
                minH={"250px"}
                bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.5))"
                opacity="0"
                _hover={{ opacity: "1", cursor: "pointer" }}
                display="flex"
                flexDirection="column"
                justifyContent="flex-end"
              >
                <Text
                  fontSize="lg"
                  color="white"
                  textAlign={"left"}
                  mb={4}
                  ml={4}
                  fontWeight="bold"
                >
                  {project.title}
                </Text>
              </Box>
            </Box>
          </motion.div>
        ))}
      </Grid>
    </Flex>
  );
};


export default Work;
