import React, { useState, useEffect } from "react";
import {
  Alert,
  AlertIcon,
  Box,
  Button,
  Center,
  Flex,
  Heading,
  Icon,
  Spacer,
  Spinner,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import { Link } from "react-router-dom";
import { BsCheckCircle } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";
import ProjectDetails from "./Forms/ProjectDetails";
import ProjectService from "./Forms/ProjectService";
import WhatNext from "./Forms/WhatNext";
import axios from "axios";

const NewProject = () => {
  const { nextStep, prevStep, setStep, reset, activeStep } = useSteps({
    initialStep: 0,
  });

  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const [isFormValid, setIsFormValid] = useState(false);

  const [selectedServices, setSelectedServices] = useState({});
  const [reasonForJoining, setReasonForJoining] = useState("");
  const [selectedTimeActions, setSelectedTimeActions] = useState([]);
  const [selectedDayActions, setSelectedDayActions] = useState([]);
  const [alert, setAlert] = useState({ show: false, status: "", message: "" });
  const [isLoading, setIsLoading] = useState(false);

  // **New State for Terms Acceptance**
  const [acceptedTerms, setAcceptedTerms] = useState(false);

  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const createProject = async () => {
    try {
      setIsLoading(true);
      const IdToken = await getIdToken();

      // Send selected services as an object with service IDs as keys and associated sub-tags
      const response = await axios.post(
        apiUrl + "/projects",
        {
          selected_services: selectedServices, // Send services with their sub-tags
          reason_for_joining: reasonForJoining,
          preferred_time: selectedTimeActions,
          preferred_day: selectedDayActions,
        },
        {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        setAlert({
          show: true,
          status: "success",
          message: "Project created successfully.",
        });
      }
    } catch (error) {
      console.error("Error creating project", error);

      let message = "An unexpected error occurred.";
      if (error.response) {
        switch (error.response.status) {
          case 400:
            message = "Bad request. Please check the provided data.";
            break;
          case 401:
            message = "Unauthorized. Please log in again.";
            break;
          case 403:
            message =
              "Forbidden. You don't have permission to perform this action.";
            break;
          case 404:
            message = "Not found. The requested resource was not found.";
            break;
          case 500:
            message =
              "Internal Server Error. Something went wrong on our side.";
            break;
          default:
            break;
        }
      }

      setAlert({
        show: true,
        status: "error",
        message: message,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const steps = [
    {
      label: "Objective",
      title: "Choose a core objective",
      comp: (
        <ProjectService
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        />
      ),
    },
    {
      label: "Details",
      title: "Tell us more",
      comp: (
        <ProjectDetails
          reasonForJoining={reasonForJoining}
          setReasonForJoining={setReasonForJoining}
          preferedTime={selectedTimeActions}
          setPreferedTime={setSelectedTimeActions}
          preferedDay={selectedDayActions}
          setPreferedDay={setSelectedDayActions}
          onFormValidityChange={setIsFormValid}
        />
      ),
    },
    {
      label: "What's next",
      title: "What happens next?",
      comp: (
        <WhatNext
          acceptedTerms={acceptedTerms}
          setAcceptedTerms={setAcceptedTerms}
        />
      ),
    },
  ];

  return (
    <div>
      <Flex maxW={"800px"} w={"full"} mx={"auto"} py={{ base: 0, md: 4 }} px={0}>
        <Flex flexDir="column" width="100%">
          {activeStep < steps.length && (
            <Steps
              activeStep={activeStep}
              colorScheme="green"
              size="sm"
              p={0}
              bg="transparent"
              variant="simple"
            >
              {steps.map(({ label, title, comp }) => (
                <Step label={label} key={label}>
                  <Box
                    w="100%"
                    justifyContent={"center"}
                    px={{ base: 0, md: 8 }}
                    bg={boxColor}
                    rounded="xl"
                    maxWidth={"800px"}
                    pt={{ lg: 8, md: 10, base: 8 }}
                    pb={{ lg: 12, md: 10, base: 8 }}
                    mt={8}
                  >
                    <Heading
                      size="lg"
                      color={textColor}
                      mb={6}
                      textAlign="center"
                    >
                      {title}
                    </Heading>
                    {comp}
                  </Box>
                  <Flex width="100%" justify="space-between" mt={8}>
                    {activeStep > 0 && (
                      <Button
                        isDisabled={activeStep === 0}
                        mr={4}
                        onClick={prevStep}
                      >
                        Previous
                      </Button>
                    )}
                    <Spacer />
                    <Button
                      onClick={() => {
                        if (activeStep === steps.length - 1) {
                          createProject();
                          nextStep();
                        } else {
                          nextStep();
                        }
                      }}
                      variant="ExperienceBankGreen"
                      isDisabled={
                        activeStep === 0
                          ? Object.keys(selectedServices).length === 0 // Only check if services are selected
                          : activeStep === 1
                          ? !isFormValid
                          : activeStep === 2
                          ? !acceptedTerms // Disable if terms not accepted
                          : false
                      }
                    >
                      {activeStep === steps.length - 1 ? "Submit" : "Next"}
                    </Button>
                  </Flex>
                </Step>
              ))}
            </Steps>
          )}

          {activeStep === steps.length && (
            <Center
              w="100%"
              py={10}
              bg={boxColor}
              rounded="xl"
              height={"400px"}
              maxWidth={"800px"}
              mt={{ base: 8, md: 0, lg: 16 }}
            >
              <VStack spacing={4}>
                {isLoading ? (
                  <>
                    <Spinner size={"xl"} />
                    <Text color={textColor} fontSize="xl" fontWeight="bold">
                      Sending Request
                    </Text>
                  </>
                ) : (
                  <>
                    {alert.status === "success" ? (
                      <>
                        <Icon as={BsCheckCircle} w={12} h={12} />
                        <Text
                          color={textColor}
                          fontSize="xl"
                          fontWeight="bold"
                        >
                          Thanks for your request!
                        </Text>
                        <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="normal"
                          overflowWrap={"break-word"}
                          px={8}
                          textAlign={"center"}
                          maxW={"500px"}
                        >
                          We've received your request for a mentor or consultant to help
                          you. We'll be in touch once we've found the right
                          person for you.
                        </Text>
                        <Link to={"/"}>
                          <Button variant="ExperienceBankGreen">Done</Button>
                        </Link>
                      </>
                    ) : (
                      <Alert status="error" rounded="md" w="100%">
                        <AlertIcon />
                        {alert.message}
                      </Alert>
                    )}
                  </>
                )}
              </VStack>
            </Center>
          )}
        </Flex>
      </Flex>
    </div>
  );
};

export default NewProject;
