import { Flex, Grid, Heading } from "@chakra-ui/react";

import {
  BsCardChecklist,
  BsChatSquareDots,
  BsFilePost,
  BsFillCreditCard2FrontFill,
  BsFillPersonBadgeFill,
} from "react-icons/bs";
import { FaUsers } from "react-icons/fa";
import AdminCard from "./AdminCard";

function AdminPanel() {
  return (
    <Flex direction="column">
      <Flex justifyContent="space-between" mb={10}>
        <Heading as="h1" size="lg">
          Admin Panel
        </Heading>
      </Flex>

      <Flex direction="column" mt={{ sm: "0px", lg: "0px" }} mb={17}>
        <Grid
          templateColumns={{
            sm: "1fr",
            md: "repeat(1, 1fr)",
            lg: "repeat(2, 1fr)",
            xl: "repeat(3, 1fr)",
            "2xl": "repeat(3, 1fr)",
          }}
          gap="30px"
        >
          <AdminCard
            icon={<BsFilePost />}
            title={"Projects"}
            description={
              "Manage all your ongoing projects, track progress, and view relevant documentation."
            }
            category={"Projects"}
            link={"projects"}
          />

          {/* <AdminCard
            icon={<BsFillCreditCard2FrontFill />}
            title={"Invoice Manager"}
            description={
              "Manage all your invoices, and approve or reject the payout to the providers."
            }
            category={"Invoices"}
            link={"invoice-manager"}
          /> */}

          <AdminCard
            icon={<FaUsers />}
            title={"User Manager"}
            description={
              "Shows all users, role and the ability to enable or disable their account."
            }
            category={"Users"}
            link={"user-manager"}
          />

          <AdminCard
            icon={<BsFillPersonBadgeFill />}
            title={"Mentors"}
            description={
              "Manage mentors and their ability to be assigned to a project."
            }
            category={"Providers"}
            link={"mentors"}
          />

          <AdminCard
            icon={<BsCardChecklist />}
            title={"Services Manager"}
            description={
              "Add, edit, or delete services (Core objectives) that a client can request. Live preview of the service editor is available."
            }
            category={"Services"}
            link={"services-manager"}
          />

          <AdminCard
            icon={<BsChatSquareDots />}
            title={"User Feedback"}
            description={
              "Centralised location for all user feedback, including suggestions and complaints."
            }
            category={"Users"}
            link={"user-feedback"}
          />
          <AdminCard
            icon={<BsChatSquareDots />}
            title={"Corporate"} 
            description={
              "Manage all your corporate accounts, including their assigned credits and projects."
            }
            category={"Users"}
            link={"corporate"}
          />
          <AdminCard
            icon={<BsChatSquareDots />}
            title={"Time Slots"}
            description={
              "View all available time slots for the mentors and clients and manage them."
            }
            category={"Users"}
            link={"time-slots"}
          />

          {/* <AdminCard
            icon={<BsFilePost />}
            title={"Add User"}
            description={
              "Automate the process of adding a new user to the system, including all necessary information."
            }
            category={"Users"}
            link={"add-user"}
          />

          <AdminCard
            icon={<BsFilePost />}
            title={"Add Provider"}
            description={
              "Automate the process of adding a new user to the system, including all necessary information."
            }
            category={"Users"}
            link={"add-provider"}
          /> */}
        </Grid>
        
      </Flex>
    </Flex>
  );
}

export default AdminPanel;
