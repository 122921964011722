// Slots.jsx

import React, { useState, useEffect } from "react";
import {
    Box,
    Button,
    VStack,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    useToast,
    Flex,
    Heading,
    Divider,
    Text,
    Icon,
    Tooltip,
    SimpleGrid,
} from "@chakra-ui/react";
import { CalendarIcon } from "@chakra-ui/icons";
import axios from 'axios';
import { getIdToken } from "../../../cognito/cognitoAuth";

// MUI Components
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';

const API_URL = process.env.REACT_APP_API_URL;

// Optional: Customize MUI theme to match Chakra UI
const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#3182ce', // Chakra UI blue.500
        },
    },
    components: {
        MuiPickersDay: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: '#3182ce',
                        color: 'white',
                        '&:hover': {
                            backgroundColor: '#2c5282',
                        },
                    },
                    '&.MuiPickersDay-today': {
                        border: '1px solid #3182ce',
                    },
                },
            },
        },
    },
});

const Slots = ({ isOpen, onClose }) => {
    const [selectedDate, setSelectedDate] = useState(null);
    const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
    const [slotsData, setSlotsData] = useState({});
    const [isLoading, setIsLoading] = useState(false);
    const [isCreating, setIsCreating] = useState(false);
    const toast = useToast();
    const toastIdRef = React.useRef();

    // Get local time zone string
    const localTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    useEffect(() => {
        if (isOpen) {
            fetchSlots();
        }
    }, [isOpen]);

    const convertUTCToLocal = (utcDateTimeString) => {
        return new Date(utcDateTimeString);
    };

    const convertLocalToUTC = (localDateTimeString) => {
        const localDate = new Date(localDateTimeString);
        return localDate.toISOString();
    };

    const fetchSlots = async () => {
        setIsLoading(true);
        try {
            const token = await getIdToken();
            const response = await axios.get(`${API_URL}/slots`, {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });

            const slots = response.data.slots;
            const formattedSlots = slots.reduce((acc, slot) => {
                try {
                    let date_time = convertUTCToLocal(slot.date_time);
                    const dateKey = date_time.toLocaleDateString('en-CA');
                    const localTime = date_time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });

                    if (!acc[dateKey]) {
                        acc[dateKey] = [];
                    }
                    acc[dateKey].push({
                        time: localTime,
                        isBooked: slot.is_booked,
                    });
                } catch (error) {
                    console.error(`Error parsing date: ${slot.date_time}`, error);
                }
                return acc;
            }, {});

            setSlotsData(formattedSlots);
        } catch (error) {
            console.error('Error fetching slots:', error);
            if (!toastIdRef.current) {
                toastIdRef.current = toast({
                    title: "Error",
                    description: `Failed to load slots. ${error.response ? error.response.data : error.message}`,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                    onCloseComplete: () => { toastIdRef.current = null }
                });
            }
        } finally {
            setIsLoading(false);
        }
    };

    const handleDateChange = (newDate) => {
        setSelectedDate(newDate);
    };

    const handleTimeSlotChange = (value) => {
        const dateKey = selectedDate.toLocaleDateString('en-CA');
        setSelectedTimeSlots((prevSlots) => {
            const updatedSlots = { ...prevSlots };
            if (updatedSlots[dateKey]?.includes(value)) {
                updatedSlots[dateKey] = updatedSlots[dateKey].filter((slot) => slot !== value);
            } else {
                updatedSlots[dateKey] = [...(updatedSlots[dateKey] || []), value];
            }
            return updatedSlots;
        });
    };

    const handleSelectAll = () => {
        const dateKey = selectedDate.toLocaleDateString('en-CA');
        const availableSlots = slotsDataForDate
            .filter(slot => slot.isBooked === 'not_created' && !isPastTime(slot.time))
            .map(slot => slot.time);

        setSelectedTimeSlots((prevSlots) => ({
            ...prevSlots,
            [dateKey]: availableSlots,
        }));
    };

    const handleDeselectAll = () => {
        const dateKey = selectedDate.toLocaleDateString('en-CA');
        setSelectedTimeSlots((prevSlots) => ({
            ...prevSlots,
            [dateKey]: [],
        }));
    };

    const handleCreateSlots = async () => {
        setIsCreating(true);

        const payload = Object.entries(selectedTimeSlots).map(([date, slots]) => ({
            date: date,
            timeSlots: slots.map(slot => {
                const localDateTimeString = `${date}T${slot}`;
                const utcDateTimeString = convertLocalToUTC(localDateTimeString);
                return utcDateTimeString;
            }),
        }));

        try {
            const response = await axios.post(`${API_URL}/slots`, payload, {
                headers: {
                    Authorization: `Bearer ${await getIdToken()}`,
                    "Content-Type": "application/json",
                },
            });

            if (response.status === 200) {
                toast({
                    title: "Success",
                    description: `Created slots on selected dates`,
                    status: "success",
                    duration: 5000,
                    isClosable: true,
                });
            } else {
                toast({
                    title: "Error",
                    description: "Failed to create slots.",
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                });
            }
            fetchSlots();
            setSelectedTimeSlots({});
        } catch (error) {
            toast({
                title: "Error",
                description: "Failed to create slots.",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        } finally {
            setIsCreating(false);
        }
    };

    const generateTimeSlotsForDate = (dateKey) => {
        const slots = [];
        for (let hour = 8; hour < 20; hour++) {
            const time = new Date();
            time.setHours(hour, 0, 0, 0);
            slots.push({
                time: time.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }),
                isBooked: 'not_created',
            });
        }
        return slots;
    };

    const mergeSlots = (existingSlots, generatedSlots) => {
        const existingTimes = new Set(existingSlots.map(slot => slot.time));
        const allSlots = [...existingSlots];

        generatedSlots.forEach(gSlot => {
            if (!existingTimes.has(gSlot.time)) {
                allSlots.push(gSlot);
            }
        });

        return allSlots.sort((a, b) => a.time.localeCompare(b.time));
    };

    const isPastTime = (time) => {
        const [hours, minutes] = time.split(':').map(Number);
        const now = new Date();
        return selectedDate.toDateString() === now.toDateString() && (hours < now.getHours() || (hours === now.getHours() && minutes < now.getMinutes()));
    };

    const dateKey = selectedDate ? selectedDate.toLocaleDateString('en-CA') : null;
    const slotsForDate = selectedDate ? selectedTimeSlots[selectedDate.toLocaleDateString('en-CA')] || [] : [];
    const slotsDataForDate = dateKey ? mergeSlots(slotsData[dateKey] || [], generateTimeSlotsForDate(dateKey)) : [];

    return (
        <Modal isOpen={isOpen} onClose={onClose} size={{ base: "full", md: "5xl" }} isCentered scrollBehavior="inside" closeOnOverlayClick={false}>
            <ModalOverlay />
            <ModalContent bg="oleniumBlack.700" borderRadius={8} p={4}>
                <ModalHeader>
                    <Heading as="h2" size="lg" color="white">
                        Create Bookable Slots
                    </Heading>
                    <Text fontSize="sm" color="gray.400" mt={2} mb={4}>
                        Select a date and time slots to create bookable slots for clients that have been assigned to you.
                    </Text>
                    <Divider my={4} borderColor="gray.600" />
                </ModalHeader>

                <ModalCloseButton />
                <ModalBody>
                    <Flex direction={{ base: "column", md: "row" }}>
                        {/* Date Calendar Section */}
                        <Box flex="1" mr={{ md: 4 }} mb={{ base: 4, md: 0 }}>
                            <ThemeProvider theme={theme}>
                                <LocalizationProvider dateAdapter={AdapterDateFns}>
                                    <DateCalendar
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        minDate={new Date()}
                                    />
                                </LocalizationProvider>
                            </ThemeProvider>
                            <Text fontSize="sm" color="gray.400" mt={2}>
                                Time Zone: {localTimeZone}
                            </Text>
                        </Box>

                        {/* Time Slots Section */}
                        <VStack spacing={4} overflowY="auto" maxHeight="400px" bg="oleniumBlack.500" p={4} borderRadius={8} w="100%">
                            {!selectedDate && (
                                <VStack w="100%" display="flex" justifyContent="center" alignItems="center" h="100%">
                                    <Icon as={CalendarIcon} boxSize={8} color="white" />
                                    <Text fontSize="lg" color="white" textAlign="center" w="100%" mb={2} fontWeight={"bold"} display="flex" justifyContent="center" alignItems="center" mt={2}>
                                        Select a date to see available time slots
                                    </Text>
                                </VStack>
                            )}

                            {selectedDate && (
                                <>
                                    <Flex w="100%" justifyContent="space-between">
                                        <Button
                                            maxWidth={200}
                                            width="48%"
                                            colorScheme="blue"
                                            onClick={handleSelectAll}
                                            isDisabled={slotsDataForDate.filter(slot => slot.isBooked === 'not_created').length === 0}
                                        >
                                            Select All
                                        </Button>
                                        <Button
                                            maxWidth={200}
                                            width="48%"
                                            colorScheme="red"
                                            onClick={handleDeselectAll}
                                            isDisabled={slotsForDate.length === 0}
                                        >
                                            Deselect All
                                        </Button>
                                    </Flex>
                                    <SimpleGrid columns={3} spacing={4} width="100%" mt={4}>
                                        {slotsDataForDate.map((slot) => (
                                            <Tooltip
                                                key={slot.time}
                                                label={
                                                    slot.isBooked === 'booked'
                                                        ? 'Booked'
                                                        : slot.isBooked === 'not_created'
                                                            ? 'Not created'
                                                            : 'Available for booking'
                                                }
                                                placement="top"
                                                hasArrow
                                            >
                                                <Button
                                                    colorScheme={
                                                        slot.isBooked === 'booked' ? 'red' :
                                                            slot.isBooked === 'not_created' ? 'blue' : 'green'
                                                    }
                                                    variant={slotsForDate.includes(slot.time) ? 'solid' : 'outline'}
                                                    onClick={() => slot.isBooked === 'not_created' && !isPastTime(slot.time) && handleTimeSlotChange(slot.time)}
                                                    isDisabled={slot.isBooked !== 'not_created' || isPastTime(slot.time)}
                                                >
                                                    {slot.time}
                                                </Button>
                                            </Tooltip>
                                        ))}
                                    </SimpleGrid>
                                </>
                            )}
                        </VStack>
                    </Flex>
                </ModalBody>
                <ModalFooter justifyContent="space-between">
                    <Button variant="ghost" onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        variant="solid"
                        colorScheme="green"
                        mr={3}
                        onClick={handleCreateSlots}
                        isDisabled={Object.values(selectedTimeSlots).every(slots => slots.length === 0)}
                        isLoading={isCreating}
                    >
                        Create Slots
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    );
};

export default Slots;
