import {
  Box,
  Button,
  Center,
  Divider,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  Icon,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Spinner,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import {
  BsCashCoin,
  BsExclamationCircle
} from "react-icons/bs";

import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const PayOutInfo = () => {

  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();

  const [initialLoading, setInitialLoading] = useState(true);
  const [loading, setLoading] = useState(false);

  const [showRefreshModal, setShowRefreshModal] = useState(false);
  const [showReturnModal, setShowReturnModal] = useState(false);

  const [stripeInfo, setStripeInfo] = useState({
    can_request_payout: false,
    has_setup_payouts: false,
    balance: 0,
    balance_currency: null,
    pending_balance_currency: null,
    weekly_earnings: 0,
    livemode: false,
  });


  useEffect(() => {
    const currentPath = window.location.pathname;
    if (currentPath === '/my-account/refresh') {
      setShowRefreshModal(true);
    } else if (currentPath === '/my-account/return') {
      setShowReturnModal(true);
    }
    fetchStripeInfo();
  }, []);

  const setupPayouts = async () => {
    setLoading(true);
    try {
      // Get Cognito token if you're using AWS Cognito for authentication
      const token = await getIdToken();

      const response = await axios.post(
        `${apiUrl}/stripe/connect`, // Replace with your API Gateway URL
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`, // If you're using token-based authentication
            "Content-Type": "application/json",
          },
        }
      );

      const accountLinkUrl = response.data.account_link_url;
      // Redirect the user to Stripe to complete the setup
      window.location.href = accountLinkUrl;

    } catch (error) {
      toast({
        title: "Error",
        description: "Could not set up payouts. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  };

  const returnToAccount = () => {
    setShowRefreshModal(false)
    setShowReturnModal(false)

    window.location.href = '/my-account';
  }

  const getDashboardLink = async () => {
    setLoading(true);
    try {
      // Get Cognito token if you're using AWS Cognito for authentication
      const token = await getIdToken();

      const response = await axios.post(
        `${apiUrl}/stripe/connect/dashboard`, // Replace with your API Gateway URL
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      const accountLinkUrl = response.data.login_link;
      // Redirect the user to Stripe to complete the setup
      window.location.href = accountLinkUrl;

    } catch (error) {
      toast({
        title: "Error",
        description: "Could access your Stripe dashboard. Please try again.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  // could be eur, usd or gbp
  const convertToCurrency = (amount, currency) => {
    // round to 2 decimal places
    const roundedAmount = amount.toFixed(2);


    switch (currency) {
      case "eur":
        return `€${roundedAmount}`;
      case "usd":
        return `$${roundedAmount}`;
      case "gbp":
        return `£${roundedAmount}`;
      default:
        return `$${roundedAmount}`;
    }
  };




  const requestPayout = async () => {
    setLoading(true);
    try {
      // Get Cognito token if you're using AWS Cognito for authentication
      const token = await getIdToken();

      const response = await axios.post(
        `${apiUrl}/stripe/request-payout`, // Replace with your API Gateway URL
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast({
        title: "Success",
        description: response.data.message,
        status: "success",
        duration: 5000,
        isClosable: true,
      });

    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setLoading(false);
  }

  const fetchStripeInfo = async () => {
    setInitialLoading(true);
    try {
      // Get Cognito token if you're using AWS Cognito for authentication
      const token = await getIdToken();

      const response = await axios.post(
        `${apiUrl}/stripe/balance-and-payout`,  // Replace with your Lambda function URL
        {},
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
        } 
      );

      setStripeInfo(response.data);


    } catch (error) {
      console.error(error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
    setInitialLoading(false);
  };








  return (
    <>
      <Flex
        w={"100%"}
        my={5}
        alignSelf={{ lg: "flex-end" }}
        justifySelf={{ lg: "flex-end" }}
      >
        <Box bg={boxColor} w="100%" p={8} rounded="2xl" shadow="xl">
          <HStack justify={"space-between"}>
            <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor}>
              Payouts
            </Heading>
            {/* {!stripeInfo.livemode && (
              <Tag colorScheme="red" variant="outline" rounded={"full"} size={"sm"} fontSize={"xs"} fontWeight={"bold"}>
                Test mode
              </Tag>
            )} */}
          </HStack>

          <Divider my={4} />

          {initialLoading && (
            <Center flexDirection={"column"} my={8}>
              <Spinner size={"xl"} color={textColor} />
            </Center>
          )}

          {initialLoading === false && (
            <>
              {!stripeInfo.has_setup_payouts ? (
                <Box borderWidth={2} borderRadius={10} borderStyle={"dashed"} p={8} borderColor={textColor}>
                  <Center flexDirection={"column"} my={4}>
                    <Icon as={BsCashCoin} w={12} h={12} color={textColor} mb={4} />
                    <Text color={textColor}>You have not set up payouts yet.</Text>
                    <Button
                      variant="ExperienceBankGreen"
                      size="sm"
                      mt={4}
                      onClick={setupPayouts}
                      isLoading={loading}
                    >
                      Set up payouts
                    </Button>
                  </Center>
                </Box>
              ) : (
                <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(3, 1fr)" }} gap={6} mt={8}>
                  <GridItem>
                    <Text color={textColor} fontWeight={"bold"} mb={2}>This week</Text>
                    <Tag
                      size={"lg"}
                      variant="outline"
                      rounded={"full"}
                      px={4}
                      fontSize={"lg"}
                    >
                      {convertToCurrency(stripeInfo.weekly_earnings / 100, stripeInfo.balance_currency)}
                    </Tag>
                  </GridItem>
                  <GridItem>
                    <Text color={textColor} fontWeight={"bold"} mb={2}>Your balance</Text>
                    <Tag
                      size={"lg"}
                      variant="outline"
                      rounded={"full"}
                      px={4}
                      fontSize={"lg"}
                    >
                      {convertToCurrency(stripeInfo.balance / 100, stripeInfo.balance_currency)}
                    </Tag>
                  </GridItem>
                  <GridItem>
                    <HStack>
                      <Tooltip label={stripeInfo.can_request_payout ? "You can request a payout" : "You can't request a payout yet"} placement="top">
                        <Button size="sm" mt={4} width={"100%"} onClick={requestPayout} isDisabled={!stripeInfo.can_request_payout} isLoading={loading}>
                          Pay out
                        </Button>
                      </Tooltip>
                      <Button variant="ExperienceBankGreen" size="sm" mt={4} width={"100%"} onClick={getDashboardLink} isLoading={loading}>
                        Dashboard
                      </Button>
                    </HStack>
                  </GridItem>
                </Grid>
              )}
            </>
          )}
        </Box>
      </Flex>
      {/* Refresh Modal */}
      <Modal isOpen={showRefreshModal} onClose={returnToAccount} isCentered>
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.600" color="white" padding={5}>
          <ModalBody p={5} textAlign={"center"}>
            <Icon as={BsExclamationCircle} w={12} h={12} color={textColor} mb={4} />

            <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor} textAlign={"center"}>
              An error occurred
            </Heading>
            <Text color={textColor} textAlign={"center"}>
              Try logging out and logging back in to refresh your account and try again.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ExperienceBankGreen' mr={3} onClick={returnToAccount} width={"100%"}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      {/* Return Modal */}
      <Modal isOpen={showReturnModal} onClose={returnToAccount} isCentered>
        <ModalOverlay />
        <ModalContent bg="oleniumBlack.600" color="white" padding={5}>
          <ModalBody p={5} textAlign={"center"}>
            <Icon as={BsCashCoin} w={12} h={12} color={textColor} mb={4} />

            <Heading as="h1" size="md" fontWeight="bold" mb={5} color={textColor} textAlign={"center"}>
              You've successfully set up payouts!
            </Heading>
            <Text color={textColor} textAlign={"center"}>
              You can now receive payments from clients. You can view your payouts
              in your Stripe dashboard.
            </Text>
          </ModalBody>
          <ModalFooter>
            <Button variant='ExperienceBankGreen' mr={3} onClick={returnToAccount} width={"100%"}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PayOutInfo;
