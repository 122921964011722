import {
  Box,
  Button,
  Container,
  FormControl,
  FormLabel,
  HStack,
  Heading,
  IconButton,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Stack,
  StackDivider,
  Text,
  VStack,
  Checkbox,
  Textarea,
  FormErrorMessage,
  Tag,
  Select,
  Icon,
  InputGroup,
  InputLeftAddon,
  Flex
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsPlusLg } from "react-icons/bs";
import { FiEdit2, FiTrash2 } from "react-icons/fi";
import { AiOutlineEnvironment } from "react-icons/ai";
import Countries from '../../../Countries';

// Helper function to format YYYY-MM to "Month YYYY"
const formatDate = (dateString) => {
  if (!dateString) return "";
  const [year, month] = dateString.split("-");
  const date = new Date(year, month - 1);
  return date.toLocaleString('default', { month: 'long', year: 'numeric' });
};

// List of months
const months = [
  { value: '01', label: 'January' },
  { value: '02', label: 'February' },
  { value: '03', label: 'March' },
  { value: '04', label: 'April' },
  { value: '05', label: 'May' },
  { value: '06', label: 'June' },
  { value: '07', label: 'July' },
  { value: '08', label: 'August' },
  { value: '09', label: 'September' },
  { value: '10', label: 'October' },
  { value: '11', label: 'November' },
  { value: '12', label: 'December' },
];

// Generate a list of years (e.g., from 1950 to current year + 10)
const generateYears = () => {
  const currentYear = new Date().getFullYear();
  const years = [];
  for (let year = currentYear; year >= 1950; year--) {
    years.push(year.toString());
  }
  return years;
};

const years = generateYears();

const WorkExperienceItem = ({ work, onDelete, onEdit, canDelete }) => {
  const handleDelete = () => {
    onDelete(work.id);
  };

  const handleEdit = () => {
    onEdit(work.id);
  };

  return (
    <Stack justify="space-between" direction="row" spacing="4" p={4} borderWidth="1px" borderRadius="lg" boxShadow="sm">
      <Stack spacing="0.5" fontSize="sm">
        <Text fontWeight="bold">
          {work.title} • {work.company} • {formatDate(work.startDate)} - {work.endDate ? formatDate(work.endDate) : "Present"}
        </Text>
        <Text mt={2}>{work.description}</Text>

        <HStack spacing="3" mt="2">
          <Tag size="sm" variant="solid" colorScheme="experienceBankGreen">
            {work.location}
          </Tag>

          <Tag size="sm" variant="solid" colorScheme="experienceBankGreen">
            {work.country}
          </Tag>
        </HStack>
      </Stack>

      <Stack direction={{ base: "column", sm: "row" }} spacing={{ base: "0", sm: "1" }}>
        <IconButton
          icon={<FiEdit2 />}
          onClick={handleEdit}
          variant="ghost"
          aria-label="Edit experience"
        />
        {/* Conditionally render the delete button */}
        {canDelete && (
          <IconButton
            icon={<FiTrash2 />}
            onClick={handleDelete}
            variant="ghost"
            colorScheme="red"
            aria-label="Delete experience"
          />
        )}
      </Stack>
    </Stack>
  );
};

const WorkExperience = ({ setFormValidExperience, workExperience, setWorkExperience }) => {
  const [isWorkModalOpen, setIsWorkModalOpen] = useState(false);
  const [editingWork, setEditingWork] = useState(null);

  const [formErrors, setFormErrors] = useState({});
  const [formTouched, setFormTouched] = useState(false);

  useEffect(() => {
    setFormValidExperience(workExperience && workExperience.length > 0);
  }, [workExperience, setFormValidExperience]);

  const closeWorkModal = () => {
    setIsWorkModalOpen(false);
    setEditingWork(null);
    setFormErrors({});
    setFormTouched(false);
  };

  const openWorkModal = () => setIsWorkModalOpen(true);

  const addOrUpdateWorkExperience = (work) => {
    if (editingWork) {
      setWorkExperience(
        workExperience.map((item) =>
          item.id === editingWork.id ? work : item
        )
      );
    } else {
      setWorkExperience([...workExperience, { ...work, id: Date.now() }]);
    }
    closeWorkModal();
  };

  const deleteWorkExperience = (id) => {
    setWorkExperience(workExperience.filter((item) => item.id !== id));
  };

  const editWorkExperience = (id) => {
    setEditingWork(workExperience.find((item) => item.id === id));
    openWorkModal();
  };

  return (
    <Box as="section">
      <Text fontSize="md" mb={4} textAlign={"center"} mx="auto">
        Add your work experience to showcase your skills and expertise
      </Text>
      <Container maxW="3xl">
        <Box bg="bg.surface" boxShadow="sm" borderRadius="lg" p={{ base: "4", md: "6" }}>
          <Stack spacing="5" divider={<StackDivider />} justifyContent={{ base: "center" }} alignContent={{ base: "center" }}>
            <Button leftIcon={<BsPlusLg />} variant="ExperienceBankGreen" onClick={openWorkModal} maxW="250px" mx="auto">
              Add Work Experience
            </Button>
            {workExperience && workExperience.length > 0 && workExperience.map((work) => (
              <WorkExperienceItem 
                key={work.id} 
                work={work} 
                onDelete={deleteWorkExperience} 
                onEdit={editWorkExperience} 
                canDelete={workExperience.length > 1} // Pass the canDelete prop
              />
            ))}
          </Stack>
        </Box>
      </Container>

      <Modal isOpen={isWorkModalOpen} onClose={closeWorkModal} size="4xl" isCentered>
        <ModalOverlay />
        <ModalContent borderRadius="2xl" bg="oleniumBlack.700">
          <ModalHeader borderBottomWidth="1px" py={4} borderRadiusTop="2xl">
            {editingWork ? "Edit Work Experience" : "Add Work Experience"}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <WorkExperienceForm 
              initialValues={editingWork} 
              onSubmit={addOrUpdateWorkExperience} 
              formErrors={formErrors}
              setFormErrors={setFormErrors}
              setFormTouched={setFormTouched} 
              formTouched={formTouched} 
            />
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={closeWorkModal}>Close</Button>
            <Spacer />
            <Button type="submit" form="work-experience-form" variant="ExperienceBankGreen">
              {editingWork ? "Update" : "Add"}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

const WorkExperienceForm = ({ initialValues, onSubmit, formErrors, setFormErrors, setFormTouched, formTouched }) => {
  const [formValues, setFormValues] = useState({
    title: initialValues?.title || "",
    company: initialValues?.company || "",
    location: initialValues?.location || "",
    country: initialValues?.country || "",
    startMonth: initialValues?.startDate ? initialValues.startDate.split('-')[1] : "",
    startYear: initialValues?.startDate ? initialValues.startDate.split('-')[0] : "",
    endMonth: initialValues?.endDate ? initialValues.endDate.split('-')[1] : "",
    endYear: initialValues?.endDate ? initialValues.endDate.split('-')[0] : "",
    description: initialValues?.description || "",
    present: initialValues?.endDate ? false : true,
  });

  const validate = () => {
    const errors = {};
    const today = new Date();
    const selectedStartDate = formValues.startMonth && formValues.startYear ? new Date(formValues.startYear, formValues.startMonth - 1) : null;
    const selectedEndDate = formValues.present ? null : (formValues.endMonth && formValues.endYear ? new Date(formValues.endYear, formValues.endMonth - 1) : null);

    if (!formValues.title) errors.title = "Title is required";
    if (formValues.title.length > 50) errors.title = "Title cannot exceed 50 characters";
    if (!formValues.company) errors.company = "Company is required";
    if (formValues.company.length > 50) errors.company = "Company cannot exceed 50 characters";
    if (!formValues.startMonth || !formValues.startYear) {
      errors.startDate = "Start date is required";
    } else if (selectedStartDate > today) {
      errors.startDate = "Start date cannot be in the future";
    }
    if (!formValues.present) {
      if (!formValues.endMonth || !formValues.endYear) {
        errors.endDate = "End date is required";
      } else if (selectedEndDate && selectedStartDate && selectedEndDate < selectedStartDate) {
        errors.endDate = "End date cannot be before start date";
      }
    }
    if (formValues.description.length > 400) errors.description = "Description cannot exceed 400 characters";

    setFormErrors(errors);
  };

  useEffect(() => {
    validate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formValues]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormValues({
      ...formValues,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setFormTouched(true);
    validate();
    if (Object.keys(formErrors).length === 0) {
      const formattedWork = {
        title: formValues.title,
        company: formValues.company,
        location: formValues.location,
        country: formValues.country,
        startDate: formValues.startMonth && formValues.startYear ? `${formValues.startYear}-${formValues.startMonth}` : "",
        endDate: formValues.present ? null : (formValues.endMonth && formValues.endYear ? `${formValues.endYear}-${formValues.endMonth}` : ""),
        description: formValues.description,
      };
      onSubmit(formattedWork);
    }
  };

  return (
    <form id="work-experience-form" onSubmit={handleSubmit}>
      <VStack spacing={4}>
        <FormControl isInvalid={formTouched && formErrors.title}>
          <FormLabel>Job Title</FormLabel>
          <Input
            type="text"
            placeholder="Title"
            name="title"
            value={formValues.title}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.title}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.company}>
          <FormLabel>Company</FormLabel>
          <Input
            type="text"
            placeholder="Company"
            name="company"
            value={formValues.company}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.company}</FormErrorMessage>
        </FormControl>
        <FormControl isInvalid={formTouched && formErrors.description}>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Description"
            name="description"
            value={formValues.description}
            onChange={handleChange}
          />
          <FormErrorMessage>{formErrors.description}</FormErrorMessage>
        </FormControl>
        <HStack width="100%" spacing={4}>
          <FormControl width="50%">
            <FormLabel>Location</FormLabel>
            <InputGroup>
              <InputLeftAddon>
                <Icon as={AiOutlineEnvironment} />
              </InputLeftAddon>
              <Input
                type="text"
                placeholder="Location"
                name="location"
                value={formValues.location}
                onChange={handleChange}
              />
            </InputGroup>      
          </FormControl>
          <FormControl width="50%">
            <FormLabel>Country</FormLabel>
            <Select
              placeholder="Select Country"
              name="country"
              value={formValues.country}
              onChange={handleChange}
            >
              {Countries.map((country) => (
                <option key={country} value={country}>
                  {country}
                </option>
              ))}
            </Select>
          </FormControl>
        </HStack>
        <HStack width="100%" spacing={4}>
          <FormControl isInvalid={formTouched && formErrors.startDate} width="50%">
            <FormLabel>Start Date</FormLabel>
            <HStack>
              <Select
                placeholder="Month"
                name="startMonth"
                value={formValues.startMonth}
                onChange={handleChange}
              >
                {months.map((month) => (
                  <option key={month.value} value={month.value}>
                    {month.label}
                  </option>
                ))}
              </Select>
              <Select
                placeholder="Year"
                name="startYear"
                value={formValues.startYear}
                onChange={handleChange}
              >
                {years.map((year) => (
                  <option key={year} value={year}>
                    {year}
                  </option>
                ))}
              </Select>
            </HStack>
            <FormErrorMessage>{formErrors.startDate}</FormErrorMessage>
          </FormControl>
          <FormControl isInvalid={formTouched && formErrors.endDate} width="50%">
            <Flex justify="space-between" align="center">
              <FormLabel mb={0}>End Date</FormLabel>
              <Checkbox
                name="present"
                isChecked={formValues.present}
                onChange={handleChange}
              >
                Present
              </Checkbox>
            </Flex>
            {!formValues.present && (
              <HStack mt={2}>
                <Select
                  placeholder="Month"
                  name="endMonth"
                  value={formValues.endMonth}
                  onChange={handleChange}
                >
                  {months.map((month) => (
                    <option key={month.value} value={month.value}>
                      {month.label}
                    </option>
                  ))}
                </Select>
                <Select
                  placeholder="Year"
                  name="endYear"
                  value={formValues.endYear}
                  onChange={handleChange}
                >
                  {years.map((year) => (
                    <option key={year} value={year}>
                      {year}
                    </option>
                  ))}
                </Select>
              </HStack>
            )}
            {!formValues.present && <FormErrorMessage>{formErrors.endDate}</FormErrorMessage>}
          </FormControl>
        </HStack>
      </VStack>
    </form>
  );
};

export default WorkExperience;
