// ProviderOnboarding.js

import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  Heading,
  Icon,
  Text,
  VStack,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { Step, Steps, useSteps } from "chakra-ui-steps";
import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BsCheckCircle } from "react-icons/bs";
import { getIdToken } from "../../../cognito/cognitoAuth";

import ProfilePictureUpload from "./ProviderForms/ProfilePictureUpload";
import BioSection from "./ProviderForms/BioSection";
import ServiceSelection from "./ProviderForms/ServiceSelection";
import WorkExperience from "./ProviderForms/Experience";
import Posts from "./ProviderForms/Posts";
import WhatsNext from "./ProviderForms/WhatNext";
import { UserContext } from "../../../UserContext";

const API_URL = process.env.REACT_APP_API_URL;

const navigateAndReload = (navigate) => {
  navigate("/my-account");
  window.location.reload();
};

const successfullySubmitted = (navigate) => {
  return (
    <Center h="80vh">
      <Box
        bg="oleniumBlack.600"
        rounded="xl"
        p={14}
        textAlign="center"
        w={"100%"}
        maxWidth="800px"
      >
        <VStack spacing={3}>
          <Icon as={BsCheckCircle} boxSize={12} color="white" />
          <Text color="white" fontSize="xl" fontWeight="bold" mt={4}>
            Your application has been submitted!
          </Text>
          <Text
            color="white"
            fontSize="md"
            fontWeight="normal"
            mt={2}
            maxW={500}
          >
            We appreciate your interest in joining Olenium. We will review your
            application and get back to you shortly.
          </Text>
          <Button
            mt={4}
            variant="ExperienceBankGreen"
            onClick={() => navigateAndReload(navigate)}
          >
            Go to my account
          </Button>
        </VStack>
      </Box>
    </Center>
  );
};

const ProviderOnboarding = () => {
  const { nextStep, prevStep, activeStep } = useSteps({
    initialStep: 0,
  });

  const toast = useToast();
  const navigate = useNavigate();

  // States for each section of the form
  const [profilePicture, setProfilePicture] = useState(null);
  const [previewImage, setPreviewImage] = useState(null);
  const [bio, setBio] = useState("");
  const [selectedServices, setSelectedServices] = useState([]);
  const [services, setServices] = useState([]);
  const [workExperience, setWorkExperience] = useState([]);
  const [portfolioItems, setPortfolioItems] = useState([]);

  const [formValidBasicProfile, setFormValidBasicProfile] = useState(false);
  const [formValidExperience, setFormValidExperience] = useState(false);
  const [formValidPortfolio, setFormValidPortfolio] = useState(false);

  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(true);

  const boxColor = useColorModeValue("white", "oleniumBlack.600");

  const { userDetails, setUserDetails } = useContext(UserContext);


  // Updated State for Agreements (only one agreement)
  const [agreements, setAgreements] = useState({
    agree: false,
  });

  // Check if all agreements are checked
  const allAgreed = agreements.agree;

  // Fetch available services
  useEffect(() => {
    const fetchServices = async () => {
      try {
        const IdToken = await getIdToken();
        const response = await axios.get(API_URL + "/services", {
          headers: {
            Authorization: `Bearer ${IdToken}`,
            "Content-Type": "application/json",
          },
        });

        if (response.status === 200 && response.data.statusCode === 200) {
          const servicesData = JSON.parse(response.data.body);
          if (Array.isArray(servicesData)) {
            setServices(servicesData);
          } else {
            throw new Error("Failed to fetch services.");
          }
        } else {
          throw new Error("Failed to fetch services.");
        }
      } catch (error) {
        console.error("Error fetching services", error);
        toast({
          title: "Error",
          description: error.message,
          status: "error",
          duration: 9000,
          isClosable: true,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchServices();
  }, [toast]);

  // Collect all the data to submit
  const createOnboardingData = () => {
    return {
      profile: {
        bio,
      },
      work: {
        services: selectedServices,
      },
      experience: workExperience,
    };
  };

  console.log(createOnboardingData());

  const submitApplication = async () => {
    try {
      setLoading(true);
      const onboardingData = createOnboardingData();

      const response = await axios.post(API_URL + "/provider", onboardingData, {
        headers: {
          Authorization: `Bearer ${await getIdToken()}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        setLoading(false);
        setSubmitted(true);
        // set local storage for providerRegistration to false

        localStorage.setItem("providerRegistered", false);
        
      } else {
        throw new Error("Failed to submit application.");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error submitting application", error);
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 9000,
        isClosable: true,
      });
    }
  };

  const handleNextStep = () => {
    if (activeStep < steps.length - 1) {
      nextStep();
    } else {
      submitApplication();
    }
  };

  if (submitted) {
    return successfullySubmitted(navigate);
  }

  const steps = [
    {
      label: "Profile Picture",
      component: (
        <ProfilePictureUpload
          setProfilePicture={setProfilePicture}
          setPreviewImage={setPreviewImage}
          profilePicture={profilePicture}
          previewImage={previewImage}
        />
      ),
    },
    {
      label: "Services",
      componentLabel: "Select the services you offer",
      component: (
        <ServiceSelection
          selectedServices={selectedServices}
          setSelectedServices={setSelectedServices}
        />
      ),
    },
    {
      label: "About You",
      componentLabel: "Tell us about yourself",
      component: <BioSection bio={bio} setBio={setBio} />,
    },
    {
      label: "Experience",
      componentLabel: "Tell us about your work experience",
      component: (
        <WorkExperience
          workExperience={workExperience}
          setWorkExperience={setWorkExperience}
          setFormValidExperience={setFormValidExperience}
        />
      ),
    },
    {
      label: "What's Next",
      component: (
        <WhatsNext
          agreements={agreements}
          setAgreements={setAgreements}
        />
      ),
    },
  ];

  return (
    <Flex
      minH="80vh"
      alignItems="center"
      justifyContent="center"
      maxW={"900px"}
      w={"full"}
      mx={"auto"}
      py={4}
      px={0}
    >
      <Flex flexDir="column" width="100%" mx={4}>
        <Steps activeStep={activeStep} colorScheme="green" size="sm" p={0} variant="simple">
          {steps.map(({ component, label, componentLabel }) => (
            <Step label={label} key={label}>
              <Box
                justifyContent={"center"}
                px={{ lg: 10, md: 6, base: 5 }}
                bg={boxColor}
                rounded="xl"
                pt={{ lg: 8, md: 10, base: 8 }}
                pb={{ lg: 12, md: 10, base: 8 }}
                mt={8}
              >
                <Center>
                  <VStack spacing={4}>
                    {componentLabel && (
                      <Heading size="lg" textAlign="center">
                        {componentLabel}
                      </Heading>
                    )}
                    <Box>{component}</Box>
                  </VStack>
                </Center>
              </Box>

              <HStack mt={4} justifyContent={activeStep === 0 ? "flex-end" : "space-between"}>
                {activeStep !== 0 && (
                  <Button onClick={prevStep} isDisabled={activeStep === 0}>
                    Previous
                  </Button>
                )}
                <Button
                  onClick={handleNextStep}
                  variant="ExperienceBankGreen"
                  isLoading={activeStep === steps.length - 1 && loading}
                  isDisabled={
                    (activeStep === 0 && userDetails.profilePicture === "") ||
                    (activeStep === 1 && selectedServices.length < 1) ||
                    (activeStep === 2 && bio.length < 100) ||
                    (activeStep === 3 && !formValidExperience) ||
                    (activeStep === 4 && !allAgreed) // Disable if agreements not all checked
                  }
                >
                  {activeStep === steps.length - 1 ? "Finish" : "Next"}
                </Button>
              </HStack>
            </Step>
          ))}
        </Steps>
      </Flex>
    </Flex>
  );
};

export default ProviderOnboarding;
