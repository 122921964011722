import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  HStack,
  Textarea,
  Text,
  useToast,
  Alert,
  AlertIcon,
  AlertTitle,
  AlertDescription,
  CloseButton
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const quickActions = [
  { label: "Early morning", time: "07:00:00" },
  { label: "Mid-morning", time: "10:00:00" },
  { label: "Midday", time: "12:00:00" },
  { label: "Early afternoon", time: "14:00:00" },
  { label: "Late afternoon", time: "17:00:00" },
  { label: "Evening", time: "19:00:00" },
];

const dayActions = [
  { label: "Monday", dayOffset: 0 },
  { label: "Tuesday", dayOffset: 1 },
  { label: "Wednesday", dayOffset: 2 },
  { label: "Thursday", dayOffset: 3 },
  { label: "Friday", dayOffset: 4 },
  { label: "Saturday", dayOffset: 5 },
  { label: "Sunday", dayOffset: 6 },
];

const ProjectDetails = ({
  reasonForJoining,
  setReasonForJoining,
  preferedTime,
  setPreferedTime,
  preferedDay,
  setPreferedDay,
  onFormValidityChange,
}) => {
  const [dateError, setDateError] = useState(""); // For date and time selection errors
  const [challengesError, setChallengesError] = useState(""); // For reasonForJoining errors
  const [touched, setTouched] = useState({
    date: false,
    challenges: false,
  });
  const [showAlert, setShowAlert] = useState(true); // State to manage alert visibility

  const toast = useToast();

  useEffect(() => {
    // Determine form validity
    const isFormValid =
      preferedTime.length > 0 &&
      preferedDay.length > 0 &&
      reasonForJoining.trim().length >= 10;

    // Notify parent about form validity
    onFormValidityChange(isFormValid);

    // Set date and time errors
    if (preferedTime.length === 0 || preferedDay.length === 0) {
      setDateError("Please select at least one day and one time of day.");
    } else {
      setDateError("");
    }

    // Set reason for joining error
    if (reasonForJoining.trim().length < 10) {
      setChallengesError("Please provide a detailed response (at least 10 characters).");
    } else {
      setChallengesError("");
    }
  }, [preferedTime, preferedDay, reasonForJoining, onFormValidityChange]);

  const handleTimeQuickActionClick = (action) => {
    setPreferedTime((prev) => {
      const exists = prev.includes(action.label);
      if (exists) {
        return prev.filter((label) => label !== action.label);
      } else {
        return [...prev, action.label];
      }
    });
    setTouched((prev) => ({ ...prev, date: true }));
  };

  const handleDayQuickActionClick = (action) => {
    setPreferedDay((prev) => {
      const exists = prev.includes(action.label);
      if (exists) {
        return prev.filter((label) => label !== action.label);
      } else {
        return [...prev, action.label];
      }
    });
    setTouched((prev) => ({ ...prev, date: true }));
  };

  const handleReasonForJoiningChange = (e) => {
    setReasonForJoining(e.target.value);
    setTouched((prev) => ({ ...prev, challenges: true }));
  };

  return (
    <Box w={"80%"} maxW={"600px"} mx={"auto"}>
      {showAlert && (
        <Alert status="warning" mb={6} rounded="md">
          <AlertIcon />
          <Box flex="1">
            <AlertTitle mb={2}>Important Notice</AlertTitle>
            <AlertDescription display="block" fontSize="sm">
              Provide sufficient information to help us find the right match for your business requirements. However, refrain from sharing any confidential details. Be aware that the information you submit may be shared with a selected group of mentors or consultants.
            </AlertDescription>
          </Box>
          <CloseButton onClick={() => setShowAlert(false)} position="absolute" right="8px" top="8px" />
        </Alert>
      )}

      {/* Date and Time Selection */}
      <FormControl mt={7} maxW={"600px"} isInvalid={touched.date && !!dateError}>
        <FormLabel>When are you available for meetings with your mentor?</FormLabel>
        <Text fontSize="sm" color="oleniumGray.500" mb={2}>
          This helps us match you with the right mentor at a time that best suits you.
        </Text>
        <HStack wrap={"wrap"} mt={4} spacing={2}>
          {dayActions.map((action) => (
            <Button
              onClick={() => handleDayQuickActionClick(action)}
              key={action.label}
              size="xs"
              bg={
                preferedDay.includes(action.label) ? "#A0B100" : "oleniumBlack.700"
              }
            >
              {action.label}
            </Button>
          ))}
        </HStack>
        <HStack wrap={"wrap"} mt={4} spacing={2}>
          {quickActions.map((action) => (
            <Button
              onClick={() => handleTimeQuickActionClick(action)}
              key={action.label}
              size="xs"
              bg={
                preferedTime.includes(action.label) ? "#A0B100" : "oleniumBlack.700"
              }
            >
              {action.label}
            </Button>
          ))}
        </HStack>
        <FormErrorMessage>{dateError}</FormErrorMessage>
      </FormControl>

      {/* Reason for Joining */}
      <FormControl mt={7} isInvalid={touched.challenges && !!challengesError}>
        <FormLabel>Why are you looking to join?</FormLabel>
        <Text fontSize="sm" color="oleniumGray.500" mb={2}>
          What are you hoping to achieve from joining the Unlocked Expertise platform?
        </Text>
        <Textarea
          placeholder={
            "Tell us about the challenges for which you are looking for help. Include details such as:\n" +
            "• Specific obstacles encountered\n" +
            "• Impact on business operations\n" +
            "• Strategies attempted to overcome these challenges\n" +
            "• Any ongoing issues that need addressing"
          }
          size="md"
          resize={"vertical"}
          width="100%"
          minHeight="500px"
          rounded={"md"}
          value={reasonForJoining}
          onChange={handleReasonForJoiningChange}
        />
        <FormErrorMessage>{challengesError}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};

export default ProjectDetails;
