import {
  Alert,
  Box,
  Button,
  FormControl,
  FormLabel,
  HStack,
  Input,
  Text,
  VStack,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { resendEmailConfirmation } from "../../cognito/cognitoAuth";

const EmailVerification = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [showEmailInput, setShowEmailInput] = useState(false);
  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(0); // New state for countdown
  const toast = useToast();
  const bgColor = useColorModeValue(
    "rgba(255, 255, 255, 0.7)",
    "rgba(1, 1, 1, 0.7)",
  );

  const getEmailFromUrl = () => {
    try {
      const urlParams = new URLSearchParams(window.location.search);
      const email = urlParams.get("email");
      setShowEmailInput(!email);
      return email;
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    const email = getEmailFromUrl();
    setEmail(email);
  }, []);

  useEffect(() => {
    if (validateEmail(email) && countdown === 0) {
      setDisableResend(false);
    } else {
      setDisableResend(true);
    }
  }, [email, countdown]);

  useEffect(() => {
    let timer;
    if (countdown > 0) {
      timer = setInterval(() => {
        setCountdown(prev => prev - 1);
      }, 1000);
    }
    return () => {
      if (timer) clearInterval(timer);
    };
  }, [countdown]);

  const handleResendEmail = async () => {
    if (!validateEmail(email)) {
      toast({
        title: "Invalid email.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setShowEmailInput(true);
      return;
    }

    try {
      await resendEmailConfirmation(email);
      toast({
        title: "Email confirmation code resent successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      setCountdown(30); // Start countdown
    } catch (error) {
      console.error("Error resending email confirmation code:", error);
      toast({
        title: "Error resending email confirmation code.",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const validateEmail = (email) => {
    const re = /\S+@\S+\.\S+/;
    return re.test(email);
  };

  return (
    <motion.div
      as="form"
      initial={{ x: -100, opacity: 0 }}
      animate={{ x: 0, opacity: 1 }}
      transition={{ duration: 0.5 }}
    >
      <Box
        as="form"
        maxW={500}
        mx={6}
        px={10}
        py="40px"
        borderRadius="2xl"
        p={6}
        bg={bgColor}
        backdropFilter="blur(10px)"
        mb="30%"
      >
        <Box>
          <VStack spacing={8} alignItems="start" mb={8}>
            <Box>
              <Text fontSize="3xl" fontWeight="bold">
                Email Verification
              </Text>
              <HStack mt={1} mb={2}>
                {/* <Text fontSize="md" color="white" fontWeight="bold" w="100%">
                  Don't have an account?
                </Text> */}
                <Button
                  variant="link"
                  size={"md"}
                  color="#A0B100"
                  onClick={() => navigate("/")}
                >
                  Create an account
                </Button>
              </HStack>
            </Box>

            {!showEmailInput && (
              <Box>
                <Alert status="info" rounded={6}>
                  <Text fontSize="md">
                    We've sent an email to {email}. Please click the link in the
                    email to verify your email address. If you can't find the email, please check your spam folder.
                  </Text>
                </Alert>
              </Box>
            )}

            {showEmailInput && (
              <FormControl id="email">
                <FormLabel fontSize="md">
                  Please enter your email address
                </FormLabel>
                <Input
                  placeholder="Email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </FormControl>
            )}

            <HStack spacing={6}>
              <Button
                variant="outline"
                onClick={handleResendEmail}
                isDisabled={disableResend || !validateEmail(email)}
              >
                {countdown > 0 ? `Resend in ${countdown}s` : "Resend Email"}
              </Button>
              <Button variant="ExperienceBankGreen" onClick={() => navigate("/login")}>
                Login
              </Button>
            </HStack>
          </VStack>
        </Box>
      </Box>
    </motion.div>
  );
};

export default EmailVerification;
