import {
  Avatar,
  Box,
  Button,
  Flex,
  Grid,
  GridItem,
  HStack,
  Heading,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Spacer,
  Select,
  Input
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const API_URL = process.env.REACT_APP_API_URL;

const TimeSlotsTable = () => {
  const [slots, setSlots] = useState([]);
  const [filteredSlots, setFilteredSlots] = useState([]);
  const [providers, setProviders] = useState([]);
  const [currentSlot, setCurrentSlot] = useState(null);
  const [action, setAction] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const [providerProfiles, setProviderProfiles] = useState({});
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedDate, setSelectedDate] = useState(null);


  const fetchSlots = async () => {
    try {
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/slots/all`, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      const slotsData = response.data.time_slots;
      setSlots(slotsData);
      setFilteredSlots(slotsData.filter(slot => slot.status !== "AVAILABLE"));
    } catch (error) {
      console.error("Error fetching time slots:", error);
    }
  };

  useEffect(() => {
    fetchSlots();
  }, []);

  useEffect(() => {
    if (slots.length > 0) {
      const providerIds = slots.map(slot => slot.provider_id);
      fetchProviderProfiles(providerIds);
    }
  }, [slots]);

  useEffect(() => {
    applyFilters();
  }, [selectedProvider, selectedStatus, selectedDate]);

  const getProviderNameAndProfilePicture = async (id) => {
    const authentication = await getIdToken();
    const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication}`,
      },
    });

    const profile = userResponse.data.body;
    return profile;
  };

  const fetchProviderProfiles = async (providerIds) => {
    const uniqueProviderIds = [...new Set(providerIds)];
    const newProfiles = {};

    for (const id of uniqueProviderIds) {
      if (!providerProfiles[id]) {
        try {
          const profile = await getProviderNameAndProfilePicture(id);
          newProfiles[id] = profile;
        } catch (error) {
          console.error(`Error fetching profile for provider ${id}:`, error);
          newProfiles[id] = { given_name: "Unknown", family_name: "Provider", profile_picture_url: "" };
        }
      }
    }

    setProviderProfiles(prev => ({ ...prev, ...newProfiles }));
  };

  const openModal = (slot, modalAction) => {
    setAction(modalAction);
    setCurrentSlot(slot);
    onOpen();
  };

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  const getProviderInfo = (providerId) => {
    const provider = providerProfiles[providerId];
    return provider
      ? { name: `${provider.given_name} ${provider.family_name}`, avatar: provider.profile_picture_url }
      : { name: "Unknown", avatar: "" };
  };

  const handleConfirm = async () => {
    try {
      const authentication = await getIdToken();
      const slotData = {
        provider_id: currentSlot.provider_id,
        date_time: new Date(currentSlot.date_time).toISOString(),
        status: currentSlot.status,
      };

      if (action === "Edit") {
        await axios.post(`${API_URL}/slots/update`, slotData, {
          headers: {
            Authorization: `Bearer ${authentication}`,
          },
        });
        toast({
          title: "Slot Updated.",
          description: "The time slot has been successfully updated.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      }
      fetchSlots();
      onClose();
    } catch (error) {
      console.error(`Error performing ${action} action on slot:`, error);
      toast({
        title: "An error occurred.",
        description: `Could not ${action.toLowerCase()} the slot.`,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const renderStatus = (status) => {
    switch (status) {
      case "AVAILABLE":
        return <Tag colorScheme="gray">Available</Tag>;
      case "AWAITING_REVIEW":
        return <Tag colorScheme="yellow">Awaiting Review</Tag>;
      case "ACCEPTED":
        return <Tag colorScheme="green">Accepted</Tag>;
      case "DENIED":
        return <Tag colorScheme="red">Denied</Tag>;
      case "CANCELLED":
        return <Tag colorScheme="red">Cancelled</Tag>;
      case "PAID":
        return <Tag colorScheme="green">Paid</Tag>;
      default:
        return <Tag colorScheme="yellow">Unknown</Tag>;
    }
  };

  const renderTime = (time) => {
    // 2024-07-18T22:29:06.806088 -> DD/MM/YYYY HH:MM

    if (!time) {
      return "Unknown";
    }

    const date = new Date(time);
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();
    const hours = date.getHours();
    const minutes = date.getMinutes();

    return `${day}/${month}/${year} ${hours}:${minutes}`;
  };

  const applyFilters = () => {
    let updatedSlots = slots.filter(slot => slot.status !== "AVAILABLE");
    if (selectedProvider) {
      updatedSlots = updatedSlots.filter(slot => slot.provider_id === selectedProvider);
    }
    if (selectedStatus) {
      updatedSlots = updatedSlots.filter(slot => slot.status === selectedStatus);
    }
    if (selectedDate) {
      const selectedDateString = selectedDate.toISOString().split('T')[0];
      updatedSlots = updatedSlots.filter(slot => slot.date_time.split('T')[0] === selectedDateString);
    }
    setFilteredSlots(updatedSlots);
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Time Slots Manager
        </Heading>
      </Flex>
      <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Total Slots
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {slots.length}
            </Text>
          </Box>
        </GridItem>
        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Unpaid Slots
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {slots.filter((slot) => slot.status === "ACCEPTED").length}
            </Text>
          </Box>
        </GridItem>

        <GridItem colSpan={{ base: 2, md: 2, lg: 1 }}>
          <Box
            p={5}
            shadow="md"
            borderWidth="1px"
            flex="1"
            borderRadius="md"
            bg={"oleniumBlack.600"}
          >
            <Text color={"white"} fontSize="sm" fontWeight="bold">
              Paid Slots
            </Text>
            <Text color={"white"} fontSize="2xl" fontWeight="bold">
              {slots.filter((slot) => slot.status === "PAID").length}
            </Text>
          </Box>
        </GridItem>
      </Grid>
      <Flex mb={4}>
        <Select placeholder="Select Provider" onChange={(e) => setSelectedProvider(e.target.value)} value={selectedProvider} maxW={200}>
          <option value="">All Providers</option>
          {Object.entries(providerProfiles).map(([id, profile]) => (
            <option key={id} value={id}>{profile.given_name} {profile.family_name}</option>
          ))}
        </Select>
        <Select placeholder="Select Status" onChange={(e) => setSelectedStatus(e.target.value)} value={selectedStatus} ml={4} maxW={200}>
          <option value="">All Statuses</option>
          <option value="AWAITING_REVIEW">Awaiting Review</option>
          <option value="ACCEPTED">Accepted</option>
          <option value="DENIED">Denied</option>
          <option value="CANCELLED">Cancelled</option>
          <option value="PAID">Paid</option>
        </Select>
        <DatePicker selected={selectedDate} onChange={date => setSelectedDate(date)} placeholderText="Select Date" dateFormat="yyyy/MM/dd" customInput={<Input ml={4} />} />
      </Flex>
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>Provider</Th>
            <Th>Date & Time</Th>
            <Th>Last Updated</Th>
            <Th>Status</Th>
            <Th>Actions</Th>
          </Tr>
        </Thead>
        <Tbody>
          {filteredSlots.map((slot) => {
            const providerInfo = getProviderInfo(slot.provider_id);
            return (
              <Tr key={slot.slot_id}>
                <Td>
                  <HStack>
                    <Avatar name={providerInfo.name} src={providerInfo.avatar} size={"sm"} />
                    <Text>{providerInfo.name}</Text>
                  </HStack>
                </Td>
                <Td>{new Date(slot.date_time).toLocaleString()}</Td>
                <Td>
                  <Tag colorScheme="gray">{renderTime(slot.last_updated)}</Tag>
                </Td>
                <Td>{renderStatus(slot.status)}</Td>
                <Td>
                  <Menu bg="oleniumBlack.600">
                    <MenuButton>
                      <IconButton
                        aria-label="Options"
                        icon={<BsThreeDotsVertical />}
                        size="xs"
                        variant="outline"
                        rounded="full"
                        mx={2}
                      />
                    </MenuButton>
                    <MenuList bg="oleniumBlack.600">
                      <MenuItem
                        onClick={() => openModal(slot, "Pay")}
                        isDisabled={slot.status !== "ACCEPTED"}
                        bg="oleniumBlack.600"
                      >
                        Pay Provider
                      </MenuItem>
                    </MenuList>
                  </Menu>
                </Td>
              </Tr>
            );
          })}
        </Tbody>
      </Table>
      <ActionModal
        isOpen={isOpen}
        onClose={onClose}
        currentSlot={currentSlot}
        action={action}
        setCurrentSlot={setCurrentSlot}
      />
    </Box>
  );
};

const ActionModal = ({ isOpen, onClose, currentSlot, action, setCurrentSlot }) => {
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const handleConfirm = async () => {
    setIsLoading(true);
    try {
      const authentication = await getIdToken();
      await axios.put(`${API_URL}/slots/update/admin`, {
        action: 'payment',
        slot_id: currentSlot.slot_id
      }, {
        headers: {
          Authorization: `Bearer ${authentication}`,
        },
      });
      toast({
        title: "Payment Successful",
        description: "The provider has been paid for this time slot.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      onClose();
    } catch (error) {
      console.error("Error processing payment:", error);

      const errorMessage = error.response?.data?.message || "Could not process the payment.";
      
      toast({
        title: "An error occurred.",
        description: errorMessage,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="md" isCentered borderRadius="md">
      <ModalOverlay />
      <ModalContent bg="oleniumBlack.600" color="white">
        <ModalHeader>{action} Provider</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          Are you sure you want to process the payment for this time slot?
        </ModalBody>
        <ModalFooter>
          <Button onClick={onClose} variant="ghost">
            Cancel
          </Button>
          <Spacer />
          <Button
            variant="solid"
            onClick={handleConfirm}
            isLoading={isLoading}
          >
            Confirm Payment
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default TimeSlotsTable;
