import {
  Avatar,
  AvatarBadge,
  Box,
  Icon,
  Spinner,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import { motion } from "framer-motion";
import React, { useContext, useState } from "react";
import { BsCheckLg } from "react-icons/bs";
import { TbEdit } from "react-icons/tb";
import { UserContext } from "../../../../UserContext";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const EditableAvatar = ({ userDetails, defaultUserDetails, isVerified }) => {
  const [isHovering, setIsHovering] = useState(false);
  const [loading, setLoading] = useState(false);
  const apiUrl = process.env.REACT_APP_API_URL;

  const { setUserDetails, setIdToken } = useContext(UserContext);

  const toast = useToast();

  const handleFileChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setLoading(true);
      const file = e.target.files[0];
      const reader = new FileReader();
      reader.onloadend = async () => {
        const base64data = reader.result;

        try {
          const token = await getIdToken();

          const body = {
            profile_picture: base64data,
          };

          const response = await axios.post(
            `${apiUrl}/users/profile-picture`,
            body,
            {
              headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
              },
            },
          );

          if (response.status === 200) {
            setLoading(false);
            setUserDetails({
              ...userDetails,
              profilePicture: response.data.profile_picture_url,
            });

            // refresh the page to show the new profile picture
            window.location.reload();
          }

          toast({
            title: "Profile picture updated.",
            description: "Your profile picture has been successfully updated.",
            status: "success",
            duration: 9000,
            isClosable: true,
          });
        } catch (e) {
          setLoading(false);
          console.error(e);

          toast({
            title: "An error occurred.",
            description: "Unable to update your profile picture.",
            status: "error",
            duration: 9000,
            isClosable: true,
          });
        }
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <Box
      position="relative"
      onMouseEnter={() => setIsHovering(true)}
      onMouseLeave={() => setIsHovering(false)}
    >
      {loading ? (
        <Avatar size="lg" icon={<Spinner />} bg="blackAlpha.600" />
      ) : (
        <>
          <Avatar
            size="lg"
            mt={1}
            name={userDetails?.firstName || defaultUserDetails.firstName}
            src={userDetails?.profilePicture}
          >
            {isVerified && (
              <motion.div
                initial={{ opacity: 0, scale: 1 }}
                animate={{ opacity: 1, scale: 1 }}
                transition={{ duration: 0.5 }}
              >
                <AvatarBadge boxSize="1em" bg="#A0B100" borderColor={"oleniumBlack.600"} zIndex={1} borderWidth={3}>
                  <Icon as={BsCheckLg} color="white" w={4} h={4} />
                </AvatarBadge>
              </motion.div>

            )}
          </Avatar>
          {isHovering && (
            <Avatar
              size="lg"
              icon={<TbEdit fontSize="1.5rem" />}
              position="absolute"
              bottom={0}
              right={0}
              onClick={() => document.getElementById("fileInput").click()}
              bg="blackAlpha.600"
            />
          )}
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: "none" }}
            onChange={handleFileChange}
          />
        </>
      )}
    </Box>
  );
};

export default EditableAvatar;
