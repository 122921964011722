import {
  Box,
  Flex,
  Heading,
  IconButton,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Text,
} from "@chakra-ui/react";
import { FaArrowLeft, FaExclamationCircle } from "react-icons/fa";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const UserFeedback = () => {
  const [feedback, setFeedback] = useState([]);
  const [loading, setLoading] = useState(true);
  const API_URL = process.env.REACT_APP_API_URL;

  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  useEffect(() => {
    fetchFeedback();
  }, []);

  const getProviderNameAndProfilePicture = async (id) => {
    const authentication = await getIdToken();
    const userResponse = await axios.get(`${API_URL}/users/info/${id}`, {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${authentication}`,
      },
    });

    const profile = userResponse.data.body;

    return profile;
  };

  const fetchFeedback = async () => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/feedback`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      const data = await response.json();

      if (response.ok) {
        const parsedFeedback = JSON.parse(data.body);
        if (Array.isArray(parsedFeedback)) {
          const normalizedFeedback = parsedFeedback.map((item) => ({
            feedback: item.feedback_text,
            feedbackType: item.feedback_type,
            feedbackTime: item.feedback_timestamp,
          }));
          setFeedback(normalizedFeedback);
        }
      } else {
        console.error("Error status code:", data.statusCode);
        setFeedback([]);
      }
    } catch (error) {
      console.error("Error fetching feedback:", error);
      setFeedback([]);
    } finally {
      setLoading(false);
    }
  };

  const convertTime = (time) => {
    // 2023-08-26T00:03:13.848805 to days old
    const date = new Date(time);
    const now = new Date();
    const diffTime = Math.abs(now - date);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

    if (diffDays === 1) {
      return `${diffDays} day ago`;
    }

    return `${diffDays} days ago`;
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          User Feedback
        </Heading>
      </Flex>
      {loading ? (
        <Flex justifyContent="center" alignItems="center" minH="200px">
          <Spinner size="xl" />
        </Flex>
      ) : feedback.length === 0 ? (
        <Flex direction="column" alignItems="center" mt={10}>
          <FaExclamationCircle size="2em" />
          <Text mt={2}>No feedback found</Text>
        </Flex>
      ) : (
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Feedback</Th>
              <Th>Feedback Type</Th>
              <Th>Date</Th>
            </Tr>
          </Thead>
          <Tbody>
            {feedback.map((feedback, index) => (
              <Tr key={index}>
                <Td>{feedback.feedback}</Td>
                <Td>
                  <Tag
                    variant="outline"
                    rounded={"full"}
                    textTransform={"capitalize"}
                  >
                    {feedback.feedbackType}
                  </Tag>
                </Td>
                <Td>{convertTime(feedback.feedbackTime)}</Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      )}
    </Box>
  );
};

export default UserFeedback;
