import { Box, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect } from "react";

import Features from "./Sections/Features";
import Hero from "./Sections/Hero";
import HowItWorks from "./Sections/HowItWorks";
import Values from "./Sections/Values";

const WorkAtOlenium = () => {
  const borderColor = useColorModeValue("white", "oleniumBlack.700");

  useEffect(() => {
    // set window scroll to top
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Box
        w="100%"
        borderColor={borderColor}
        bg="black"
        borderWidth="3px"
        overflow={"hidden"}
        rounded={"25px"}
      >
        <Hero />
        <Values />
        <Features />
        <HowItWorks />
      </Box>
    </>
  );
};

export default WorkAtOlenium;
