import {
  AddIcon,
  AttachmentIcon,
  ChevronDownIcon,
  DeleteIcon,
  EditIcon,
} from "@chakra-ui/icons";
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spacer,
  Table,
  Tbody,
  Td,
  Text,
  Textarea,
  Th,
  Thead,
  Tr,
  useDisclosure,
  useToast,
  Spinner,
  Tag,
  VStack,
  HStack,
  Divider,
  Hide,
  useColorModeValue,
  Switch,
} from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { BsCheckCircleFill, BsXCircleFill } from "react-icons/bs";
import { FaArrowLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { motion } from "framer-motion";

import { getIdToken } from "../../../../cognito/cognitoAuth";

const CapabilityCard = ({
  value,
  title,
  description,
  icon,
  onChange,
  selectedOption,
  isDisabled,
  selectedService,
  selectedSubTag,
  setSelectedSubTag,
  services,
}) => {
  const textColor = useColorModeValue("oleniumBlack.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.300");
  const borderColor = useColorModeValue("oleniumBlack.200", "oleniumBlack.600");
  const borderColorActive = useColorModeValue("#A0B100", "#A0B100");

  const disabledTextColour = useColorModeValue("gray.100", "white");
  const disabledBoxColour = useColorModeValue("oleniumBlack.300", "oleniumBlack.500");

  const handleClick = (e) => {
    if (!isDisabled) {
      onChange(value);
    }
  };

  const handleSubTagClick = (e, tag) => {
    e.stopPropagation();
    setSelectedSubTag(tag);
  };

  return (
    <motion.div transition={{ delay: 0.2 }}>
      <Box
        borderColor={
          selectedOption && !isDisabled ? borderColorActive : borderColor
        }
        shadow={selectedOption && !isDisabled ? "lg" : ""}
        borderRadius="2xl"
        p={{ base: 3, sm: 4, md: 6 }}
        pb={{ base: 4, sm: 6, md: 6 }}
        m={{ base: 0, md: 4 }}
        my={{ base: 2 }}
        bg={isDisabled ? disabledBoxColour : boxColor}
        borderWidth="2px"
        cursor={!isDisabled ? "pointer" : "not-allowed"}
        _hover={{
          borderColor: !isDisabled ? borderColorActive : borderColor,
        }}
      >
        <Hide above="md">
          <HStack spacing={3} align="center">
            <Image
              src={icon || "https://via.placeholder.com/150"}
              alt={title || "Service Icon"}
              width={{ base: "40px", md: "60px" }}
              height={{ base: "40px", md: "60px" }}
              aspectRatio={1}
              my={3}
              ml={3}
              opacity={0.5}
            />
            <VStack align="left" spacing={0}>
              <Text
                color={isDisabled ? disabledTextColour : textColor}
                fontSize={{ base: "sm", sm: "md", md: "lg", lg: "lg" }}
                fontWeight="bold"
                align="left"
                m={0}
                p={0}
              >
                {title || "Service Title Here"}
              </Text>
              {isDisabled && (
                <Tag size="sm" w="fit-content" bg={"#A0B100"} color="white" fontWeight={"bold"} mt={1}>
                  Coming Soon
                </Tag>
              )}
            </VStack>
          </HStack>
        </Hide>
        <HStack spacing={{ base: 3, md: 2 }}>
          <Hide below="md">
            <Image
              src={icon || "https://via.placeholder.com/150"}
              alt={title || "Service Icon"}
              width={{ base: "40px", md: "50px" }}
              height={{ base: "40px", md: "50px" }}
              aspectRatio={1}
            />
          </Hide>

          <Box>
            <Hide below="md">
              <HStack>
                <Text
                  color={isDisabled ? disabledTextColour : textColor}
                  fontSize={{ base: "sm", sm: "sm", md: "lg", lg: "lg" }}
                  fontWeight="bold"
                  ml={3}
                  mb={1}
                  align="left"
                >
                  {title || "Service Title Here"}
                </Text>
                {isDisabled && (
                  <Tag size="sm" w="fit-content" bg={"#A0B100"} color="white" fontWeight={"bold"} mb={1} ml={2}>
                    Coming Soon
                  </Tag>
                )}
              </HStack>
            </Hide>
            <Text
              color={isDisabled ? disabledTextColour : textColor}
              fontSize={{ base: "sm", sm: "sm", md: "sm", lg: "md" }}
              ml={3}
              align="left"
            >
              {description || "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Ut elit tellus, luctus nec ullamcorper mattis, pulvinar dapibus leo "}
            </Text>
            {selectedOption && value === selectedService && (
              <motion.div
                initial={{ opacity: 0, y: 20 }}
                animate={{ opacity: 1, y: 0 }}
                transition={{ delay: 0.2 }}
              >
                <Divider m={3} />
                <Text fontSize="sm" color={textColor} ml={3} mt={2} fontWeight="bold" textAlign={"left"}>
                  Choose a sub section of {title || "Service Title Here"}:
                </Text>
                <VStack align="start" spacing={1} ml={3}>
                  <HStack wrap={"wrap"} mt={4}>
                    {services
                      .find((service) => service.id === selectedService)
                      ?.sub_tags.map((tag, index) => (
                        <Tag
                          key={index}
                          size="md"
                          w="fit-content"
                          bg={tag === selectedSubTag ? "#A0B100" : "oleniumBlack.500"}
                          color={"white"}
                          fontWeight={"bold"}
                          cursor="pointer"
                          onClick={(e) => handleSubTagClick(e, tag)} // Update selectedSubTag on click
                        >
                          {tag}
                        </Tag>
                      ))}
                  </HStack>
                </VStack>
              </motion.div>
            )}
          </Box>
        </HStack>
      </Box>
    </motion.div>
  );
};

const ServiceModal = ({ isOpen, onClose, action, service }) => {
  const [serviceId, setServiceId] = useState("");
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [icon, setIcon] = useState(null);
  const [isActive, setIsActive] = useState(false);
  const [isInternal, setIsInternal] = useState(false);
  const [iconChanged, setIconChanged] = useState(false);
  const [subTags, setSubTags] = useState([]);
  const [newSubTag, setNewSubTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [previewService, setPreviewService] = useState(null);
  const toast = useToast();

  const API_URL = process.env.REACT_APP_API_URL;

  const handleIconChange = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.onloadend = function () {
      setIcon(reader.result);
      setIconChanged(true);
      setPreviewService({
        ...previewService,
        icon: reader.result,
      });
    };
    reader.readAsDataURL(file);
  };

  useEffect(() => {
    if (action === "update" && service) {
      setServiceId(service.id);
      setName(service.name);
      setDescription(service.description);
      setIcon(service.icon);
      setIsActive(service.is_active);
      setIsInternal(service.is_internal);
      setSubTags(service.sub_tags || []);
      setPreviewService(service);
    } else if (action === "create") {
      // Reset all fields when creating a new service
      setServiceId("");
      setName("");
      setDescription("");
      setIcon(null);
      setIsActive(false);
      setIsInternal(false);
      setSubTags([]);
      setNewSubTag("");
      setPreviewService(null);
    }
    setIconChanged(false);
  }, [service, action]);

  const handleAddSubTag = () => {
    if (newSubTag.trim() === "") {
      toast({
        title: "Sub-tag cannot be empty.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    if (subTags.includes(newSubTag.trim())) {
      toast({
        title: "Sub-tag already exists.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }
    const updatedSubTags = [...subTags, newSubTag.trim()];
    setSubTags(updatedSubTags);
    setNewSubTag("");
    setPreviewService({
      ...previewService,
      sub_tags: updatedSubTags,
    });
  };

  const handleRemoveSubTag = (index) => {
    const updatedSubTags = subTags.filter((_, i) => i !== index);
    setSubTags(updatedSubTags);
    setPreviewService({
      ...previewService,
      sub_tags: updatedSubTags,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    const url =
      action === "update"
        ? `${API_URL}/services/${service.id}`
        : `${API_URL}/services`;

    const method = action === "update" ? "PUT" : "POST";

    function cleanURL(url) {
      try {
        const urlObj = new URL(url);
        const params = new URLSearchParams(urlObj.search);
        params.delete("AWSAccessKeyId");
        params.delete("Signature");
        params.delete("x-amz-security-token");
        params.delete("Expires");
        urlObj.search = params;
        return urlObj.toString();
      } catch (error) {
        console.error("Invalid URL:", url);
        return url;
      }
    }

    const data = {
      name,
      description,
      is_active: isActive,
      is_internal: isInternal,
      sub_tags: subTags,
    };

    if (iconChanged && icon) {
      const iconUrl = cleanURL(icon);
      data.icon = iconUrl;
    }

    try {
      const authentication = await getIdToken();
      const response = await fetch(url, {
        method,
        headers: {
          Authorization: `Bearer ${authentication}`,
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        toast({
          title: `${action === "update" ? "Updated" : "Created"} service`,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        const responseBody = await response.json();
        console.error("Server responded with error:", responseBody);
        throw new Error("Failed to save the service");
      }
    } catch (error) {
      console.error("Error saving service:", error);
      toast({
        title: "Error saving service",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async () => {
    setIsLoading(true);
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/services/${service.id}`, {
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.ok) {
        toast({
          title: "Service deleted",
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        onClose();
      } else {
        throw new Error("Failed to delete the service");
      }
    } catch (error) {
      console.error("Error deleting service:", error);
      toast({
        title: "Error deleting service",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false);
    }
  };

  const renderModalBody = () => {
    if (action === "delete") {
      return (
        <ModalBody>
          Are you sure you want to delete the service "{name}"?
        </ModalBody>
      );
    }
    return (
      <ModalBody>
        <CapabilityCard
          value={serviceId}
          title={name}
          description={description}
          icon={icon}
          isDisabled={!isActive}
          selectedOption={true}
          selectedService={serviceId}
          selectedSubTag={""}
          setSelectedSubTag={() => {}}
          services={[previewService || {}]}
        />

        <Divider mt={10} />

        <Grid templateColumns="repeat(2, 1fr)" gap={6} mt={6} p={{ base: 0, md: 4 }}>
          <GridItem colSpan={1}>
            <FormControl id="name" mb={4}>
              <FormLabel>Name</FormLabel>
              <Text mb={2} fontSize="sm" color="gray.500">
                Enter the name of the service.
              </Text>
              <Input
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
                disabled={isLoading}
              />
            </FormControl>
            <Divider my={6} />
            <FormControl id="description" mb={4}>
              <FormLabel>Description</FormLabel>
              <Text mb={2} fontSize="sm" color="gray.500">
                Provide a brief description of the service.
              </Text>
              <Textarea
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                disabled={isLoading}
                minH={"200px"}
              />
            </FormControl>
          </GridItem>
          <GridItem colSpan={1}>
            <FormControl id="icon" mb={4}>
              <FormLabel>Icon</FormLabel>
              <Text mb={2} fontSize="sm" color="gray.500">
                Upload an icon for the service.
              </Text>
              <InputGroup>
                <InputLeftElement
                  pointerEvents="none"
                  children={<AttachmentIcon color="gray.300" />}
                />
                <Input
                  type="file"
                  accept="image/*"
                  onChange={handleIconChange}
                  disabled={isLoading}
                />
              </InputGroup>
            </FormControl>
            <Divider my={6} />
            <FormControl id="is_active" mb={4}>
              <FormLabel>Service Status</FormLabel>
              <HStack>
                <Switch
                  id="is_active"
                  isChecked={isActive}
                  onChange={(e) => setIsActive(e.target.checked)}
                  disabled={isLoading}
                />
                <Tag
                  size="sm"
                  variant="subtle"
                  colorScheme={isActive ? "green" : "red"}
                  ml={2}
                >
                  {isActive ? "Active" : "Inactive"}
                </Tag>
              </HStack>
            </FormControl>
            <Divider my={6} />
            <FormControl id="sub_tags" mb={4}>
              <FormLabel>Tags</FormLabel>
              <Text mb={2} fontSize="sm" color="gray.500">
                Add and manage tags for the service.
              </Text>
              <Flex>
                <Input
                  value={newSubTag}
                  onChange={(e) => setNewSubTag(e.target.value)}
                  placeholder="Add a sub-tag"
                  disabled={isLoading}
                />
                <Button
                  onClick={handleAddSubTag}
                  ml={2}
                  disabled={isLoading || newSubTag.trim() === ""}
                >
                  Add
                </Button>
              </Flex>
              <Flex mt={2} flexWrap="wrap">
                {subTags.map((tag, index) => (
                  <Box
                    key={index}
                    p={2}
                    m={1}
                    bg="oleniumBlack.700"
                    borderRadius="md"
                    display="flex"
                    alignItems="center"
                  >
                    <Text mx={2}>{tag}</Text>
                    {action === "create" && (
                      <IconButton
                        aria-label="Delete sub-tag"
                        icon={<DeleteIcon />}
                        size="xs"
                        onClick={() => handleRemoveSubTag(index)}
                        disabled={isLoading}
                        variant="ghost"
                        colorScheme="red"
                      />
                    )}
                  </Box>
                ))}
              </Flex>
            </FormControl>
          </GridItem>
        </Grid>
      </ModalBody>
    );
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} size="4xl">
      <ModalOverlay />
      <ModalContent bg="oleniumBlack.600">
        <ModalHeader>
          {action === "create"
            ? "Create Service"
            : action === "update"
            ? "Update Service"
            : "Delete Service"}
        </ModalHeader>
        <ModalCloseButton />
        <form onSubmit={handleSubmit}>
          {renderModalBody()}
          <ModalFooter>
            {action !== "delete" ? (
              <Button
                mr={3}
                type="submit"
                isLoading={isLoading}
                disabled={isLoading}
                variant={"ExperienceBankGreen"}
              >
                {action === "create" ? "Create" : "Save"}
              </Button>
            ) : null}

            <Button onClick={onClose} disabled={isLoading}>
              Cancel
            </Button>
          </ModalFooter>
        </form>
        {action === "delete" && !isLoading ? (
          <ModalFooter>
            <Button
              colorScheme="red"
              onClick={handleDelete}
              disabled={isLoading}
            >
              Delete
            </Button>
          </ModalFooter>
        ) : null}
      </ModalContent>
    </Modal>
  );
};

const ServiceManager = () => {
  const navigate = useNavigate();

  const onBack = () => {
    navigate("/admin-panel");
  };

  const [services, setServices] = useState([]);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentService, setCurrentService] = useState(null);
  const [action, setAction] = useState("");
  const API_URL = process.env.REACT_APP_API_URL;

  const toast = useToast();

  useEffect(() => {
    fetchServices();
  }, []);

  const handleResponse = (response) => {
    if (!response.ok) {
      const error = new Error(response.statusText);
      error.response = response;
      throw error;
    }
    return response;
  };

  const fetchServices = async () => {
    try {
      const authentication = await getIdToken();
      const response = await fetch(`${API_URL}/services`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      handleResponse(response);

      const data = await response.json();

      const servicesData = JSON.parse(data.body);

      if (Array.isArray(servicesData)) {
        setServices(servicesData.sort((a, b) => a.order - b.order));
      } else {
        setServices([]);
      }
    } catch (error) {
      console.error("Error fetching services:", error);
      toast({
        title: error.message,
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setServices([]);
    }
  };

  const openCreateModal = () => {
    setAction("create");
    setCurrentService(null);
    onOpen();
  };

  const openDeleteModal = (service) => {
    setAction("delete");
    setCurrentService(service);
    onOpen();
  };

  const openUpdateModal = (service) => {
    setAction("update");
    setCurrentService(service);
    onOpen();
  };

  const onModalClose = () => {
    fetchServices();
    onClose();
  };

  const totalNumberOfServices = services.length;
  const totalNumberOfActiveServices = services.filter(
    (service) => service.is_active
  ).length;
  const totalNumberOfInactiveServices = services.filter(
    (service) => !service.is_active
  ).length;

  const handleDragEnd = async (result) => {
    if (!result.destination) return;

    const reorderedServices = Array.from(services);
    const [removed] = reorderedServices.splice(result.source.index, 1);
    reorderedServices.splice(result.destination.index, 0, removed);

    setServices(reorderedServices);

    // Update the order in the backend
    try {
      const authentication = await getIdToken();
      await fetch(`${API_URL}/services/reorder`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
        body: JSON.stringify(
          reorderedServices.map((service, index) => ({
            id: service.id,
            order: index,
          }))
        ),
      });
    } catch (error) {
      console.error("Error updating service order:", error);
      toast({
        title: "Error updating service order",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };

  const renderSubTags = (subTags) => {
    // render tags as chakra tags
    return subTags.map((tag) => (
      <Tag key={tag} size="sm" variant="subtle" colorScheme="green" m={1}>
        {tag}
      </Tag>
    ));
  };

  return (
    <>
      <Box>
        <Flex justifyContent="flex-start" mb={10}>
          <IconButton
            aria-label="Back"
            icon={<FaArrowLeft />}
            onClick={onBack}
          />
          <Heading as="h1" size="lg" ml={5} mt={1}>
            Service Manager
          </Heading>
          <Spacer />
          <Button
            leftIcon={<AddIcon />}
            variant="ExperienceBankGreen"
            size={"sm"}
            onClick={openCreateModal}
            mt={1}
          >
            Create Service
          </Button>
        </Flex>

        <Grid templateColumns="repeat(4, 1fr)" gap={6} mb={6}>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Total services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfServices}
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Active services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfActiveServices}
              </Text>
            </Box>
          </GridItem>
          <GridItem colSpan={1}>
            <Box
              p={5}
              shadow="md"
              borderWidth="1px"
              flex="1"
              borderRadius="md"
              bg={"oleniumBlack.600"}
            >
              <Text color={"white"} fontSize="sm" fontWeight="bold">
                Inactive services
              </Text>
              <Text color={"white"} fontSize="2xl" fontWeight="bold">
                {totalNumberOfInactiveServices}
              </Text>
            </Box>
          </GridItem>
        </Grid>
      </Box>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="services">
          {(provided) => (
            <Table
              variant="simple"
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              <Thead>
                <Tr>
                  <Th>Icon</Th>
                  <Th>Name</Th>
                  <Th width="40%">Description</Th>
                  <Th>Active</Th>
                  <Th>Tags</Th>
                  <Th>Actions</Th>
                </Tr>
              </Thead>
              <Tbody>
                {services.map((service, index) => (
                  <Draggable
                    key={service.id}
                    draggableId={service.id}
                    index={index}
                  >
                    {(provided) => (
                      <Tr
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <Td>
                          <Image
                            src={service.icon}
                            alt={service.name}
                            w={10}
                            objectFit="cover"
                            aspectRatio={1}
                          />
                        </Td>
                        <Td>{service.name}</Td>
                        <Td>
                          <Text noOfLines={3}>{service.description}</Text>
                        </Td>
                        <Td>
                          {service.is_active ? (
                            <BsCheckCircleFill color="green" />
                          ) : (
                            <BsXCircleFill color="red" />
                          )}
                        </Td>
                        <Td>{renderSubTags(service.sub_tags)}</Td>
                        <Td>
                          <Menu>
                            <MenuButton
                              as={Button}
                              rightIcon={<ChevronDownIcon />}
                            >
                              Actions
                            </MenuButton>
                            <MenuList bg="oleniumBlack.600">
                              <MenuItem
                                icon={<EditIcon />}
                                onClick={() => openUpdateModal(service)}
                                bg={"oleniumBlack.600"}
                              >
                                Update
                              </MenuItem>
                              <MenuItem
                                icon={<DeleteIcon />}
                                onClick={() => openDeleteModal(service)}
                                bg={"oleniumBlack.600"}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Tbody>
            </Table>
          )}
        </Droppable>
      </DragDropContext>

      <ServiceModal
        isOpen={isOpen}
        onClose={onModalClose}
        action={action}
        service={currentService}
      />
    </>
  );
};

export default ServiceManager;
