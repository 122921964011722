import {
  Tag,
  Box,
  Button,
  Flex,
  GridItem,
  Icon,
  Stack,
  Text,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import Card from "../../Shared/Cards/Card";
import CardBody from "../../Shared/Cards/CardBody";
import CardHeader from "../../Shared/Cards/CardHeader";
import { HSeparator } from "../../Shared/Separator/Separator";

const ProjectCard = (props) => {
  function trimString(str) {
    if (str.length > 341) {
      return str.substring(0, 341) + "...";
    }
    return str;
  }

  return (
    <GridItem colSpan={1} rowSpan={1} w="100%">
      <Card
        alignSelf="flex-start"
        shadow={"xl"}
        p={5}
        borderRadius={"xl"}
        bg="oleniumBlack.600"
      >
        <CardHeader>
          <Flex justify="space-between" w="100%">
            <Flex>
              <Box
                w="50px"
                h="50px"
                borderRadius="50%"
                bg="blue.500"
                mr="16px"
                display="flex"
                alignItems="center"
                justifyContent="center"
              >
                <Icon color="white" fontSize="2xl">
                  {props.icon}
                </Icon>
              </Box>

              <Flex direction="column">
                <Text fontSize="md" color="white" fontWeight="bold" mb="8px">
                  {props.title}
                </Text>
                <Stack direction="row">
                  <Tag colorScheme="blue" mr="8px" fontWeight={"bold"}>
                    {props.category}
                  </Tag>
                </Stack>
              </Flex>
            </Flex>
            <Link to={props.link}>
                <Button variant="ExperienceBankGreen" size="sm" my={2}>
                  View
                </Button>
              </Link>
          </Flex>
        </CardHeader>


      </Card>
    </GridItem>
  );
};

export default ProjectCard;
