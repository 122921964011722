import React, { useContext, useEffect, useState, useMemo } from "react";
import {
  Box,
  Center,
  Flex,
  VStack,
  Heading,
  Spinner,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  Text,
  useBreakpointValue,
  useColorModeValue,
} from "@chakra-ui/react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { UserContext } from "../../../UserContext";
import { getIdToken } from "../../../cognito/cognitoAuth";
import ExperienceBankLogoAnimated from "../../ExperienceBankLogoAnimated";
import AcceptProject from "./Widgets/AcceptProject";
import TeamChat from "./Widgets/TeamChat";
import Project from "./Widgets/Project";
import MembershipInfo from "./Widgets/MembershipInfo.js";
import ProviderBookings from "./Widgets/ProviderBookings";
import ConsultantProfile from "./Widgets/ConsultantProfile.js";
import ClientsProfile from "./Widgets/ClientsProfile.js";
import BookingComponent from "./Widgets/Bookings";

const API_URL = process.env.REACT_APP_API_URL;

const ProjectDetail = () => {
  let { projectId, tab: paramTab } = useParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [providerDetails, setProviderDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [project, setProject] = useState({
    project: {},
    assets: [],
  });

  const { userDetails } = useContext(UserContext);

  // Fetch project details
  const fetchProject = async () => {
    const authentication = await getIdToken();
    try {
      const response = await axios.get(`${API_URL}/projects/${projectId}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      if (response.data.statusCode === 200) {
        const projectData = JSON.parse(response.data.body);
        setProject({
          project: projectData.project,
          assets: projectData.assets,
          business_profile: projectData.business_profile,
        });

        setIsLoading(false);
      } else {
        throw new Error("Failed to fetch project details.");
      }
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching project details:", error);
      setProject({
        project: {},
        assets: [],
        business_profile: {},
      });
    }
  };

  // Fetch provider details
  const getProvider = async (provider_id) => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = await getIdToken();

    const userResponse = await axios.get(
      `${API_URL}/users/info/${provider_id}`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
    );

    if (userResponse.data.statusCode === 200) {
      const { profile_picture_url, given_name, family_name } =
        userResponse.data.body;
      return {
        profile_picture_url,
        given_name,
        family_name,
      };
    } else {
      throw new Error("Failed to fetch user info.");
    }
  };

  useEffect(() => {
    fetchProject();
  }, [projectId]);

  useEffect(() => {
    if (project.project.provider_id) {
      getProvider(project.project.provider_id)
        .then((provider) => {
          setProviderDetails(provider);
        })
        .catch((err) => {
          console.error(err);
        });
    }
  }, [project.project.provider_id]);

  const bgColour = useColorModeValue("white", "oleniumBlack.600");

  const [tabIndex, setTabIndex] = useState(0);

  // Determine if user is provider or client
  const checkIfUserIsProvider = () => {
    return project.project.provider_id === userDetails.id;
  };

  const checkIfUserIsClient = () => {
    return project.project.user_id === userDetails.id;
  };

  const checkIfEnterpriseUser = () => {
    // Regular expression to check if a string is a valid UUID
    const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;

    // Extracting the enterprise_id
    const enterpriseId = project.project.enterprise_id;

    // Checking if the enterprise_id is a valid UUID and not "N/A"
    return uuidRegex.test(enterpriseId) && enterpriseId !== "N/A";
  };

  // Dynamically generate tabs based on user role and project status
  const tabs = useMemo(() => {
    const generatedTabs = [];

    // Common tabs
    generatedTabs.push({ name: "chat", label: "Chat" });
    generatedTabs.push({ name: "project", label: "Project" });

    if (checkIfUserIsClient() && project.project.status === "IN_PROGRESS") {
      generatedTabs.push({ name: "meetings", label: "Meetings" });
    }

    if (checkIfUserIsClient() && !checkIfEnterpriseUser()) {
      generatedTabs.push({ name: "membership", label: "Membership" });
    }

    if (checkIfUserIsProvider()) {
      if (project.project.status === "IN_PROGRESS") {
        generatedTabs.push({ name: "providerMeetings", label: "Meetings" });
      }
      generatedTabs.push({
        name: "mentor-profile", // Changed from "providerProfile" to "mentor-profile"
        label: providerDetails
          ? `Profile`
          : "Mentor Profile",
      });
      generatedTabs.push({
        name: "clientProfile",
        label:
          project.business_profile?.businessName || "No Profile",
      });
    }

    if (checkIfUserIsClient() && providerDetails) {
      generatedTabs.push({
        name: "mentor-profile", // Changed from "providerProfile" to "mentor-profile"
        label: `Profile`,
      });
    }

    return generatedTabs;
  }, [
    checkIfUserIsClient,
    checkIfUserIsProvider,
    project.project.status,
    providerDetails,
    checkIfEnterpriseUser,
    project.business_profile,
  ]);

  // Create mappings based on dynamic tabs
  const tabNameToIndex = useMemo(() => {
    const mapping = {};
    tabs.forEach((tab, index) => {
      mapping[tab.name] = index;
    });
    return mapping;
  }, [tabs]);

  const indexToTabName = useMemo(() => {
    const mapping = {};
    tabs.forEach((tab, index) => {
      mapping[index] = tab.name;
    });
    return mapping;
  }, [tabs]);

  // Set tabIndex based on paramTab
  useEffect(() => {
    if (paramTab && tabNameToIndex.hasOwnProperty(paramTab)) {
      setTabIndex(tabNameToIndex[paramTab]);
    } else {
      setTabIndex(0);
      // Optionally, navigate to default tab
      // navigate(`/project/${projectId}/${tabs[0].name}`, { replace: true });
    }
  }, [paramTab, tabNameToIndex, tabs, projectId]);

  // Handle tab change
  const handleTabChange = (index) => {
    const tabName = indexToTabName[index];
    if (tabName) {
      navigate(`/project/${projectId}/${tabName}`);
    }
  };

  return (
    <Box>
      {isLoading ? (
        <Center h="100vh">
          <VStack spacing={4}>
            <Spinner size={"xl"} />
          </VStack>
        </Center>
      ) : (
        <Flex direction="column" m={0} p={0} w={"100%"}>
          <VStack align="start" justify={"center"} spacing={6}>
            <Heading as="h1" size="lg" mb={4} mt={5} ml={3}>
              {project.project.service_name}
            </Heading>
          </VStack>

          {checkIfUserIsProvider() &&
            project.project.status === "AWAITING_ACCEPTANCE" && (
              <AcceptProject
                project_id={project?.project.id}
                user_id={project?.project.user_id}
              />
            )}

          <Box
            mb="24px"
            borderRadius="15px"
            bg={bgColour}
            p={{ base: "0px", md: "24px" }}
            pt={{ base: "10px", md: "24px" }}
          >
            <Tabs
              colorScheme="white"
              index={tabIndex}
              onChange={handleTabChange}
              maxW={"100%"}
            >
              <TabList px={{ base: "10px", md: "0px" }} pt={{ base: "10px", md: "0px" }}>
                {tabs.map((tab) => (
                  <Tab key={tab.name}>{tab.label}</Tab>
                ))}
              </TabList>

              <TabPanels px={{ base: "10px", md: "0px" }} pt={{ base: "10px", md: "0px" }}>
                {tabs.map((tab) => (
                  <TabPanel key={tab.name} maxW={"100%"}>
                    {tab.name === "chat" && (
                      <>
                        {project.project.status === "AWAITING_ASSIGNMENT" ? (
                          <Box>
                            <VStack
                              align="start"
                              justify={"center"}
                              spacing={6}
                              mt={12}
                              mb={6}
                            >
                              <ExperienceBankLogoAnimated />
                              <Text
                                fontSize={"4xl"}
                                align={"left"}
                                fontWeight="900"
                                lineHeight={1.2}
                                overflow="visible"
                                color="#A0B100"
                              >
                                {userDetails.id === project.project.user_id ? (
                                  <>
                                    We're reviewing your requirements <br />before
                                    assigning a mentor.
                                  </>
                                ) : (
                                  <>
                                    We're in the process of assigning <br />a new mentor
                                  </>
                                )}
                              </Text>
                              <Text
                                fontSize={"lg"}
                                align={"left"}
                                maxW={"500px"}
                                fontWeight="400"
                              >
                                {userDetails.id === project.project.user_id ? (
                                  <>
                                    Your project is currently being reviewed by our
                                    team. We'll be in touch soon with an update on
                                    the status of the project.
                                  </>
                                ) : (
                                  <>
                                    This project is currently in the process of
                                    being assigned to a new provider. We'll be in
                                    touch soon with an update on the status of the
                                    project. If you rejected the project, it will
                                    be assigned to a new provider and you will be
                                    notified.
                                  </>
                                )}
                              </Text>
                            </VStack>
                          </Box>
                        ) : (
                          <TeamChat
                            project_id={project.project.id}
                            userId={userDetails.id}
                            provider={providerDetails || {}}
                          />
                        )}
                      </>
                    )}

                    {tab.name === "project" && <Project project={project.project} />}

                    {tab.name === "meetings" &&
                      checkIfUserIsClient() &&
                      project.project.status === "IN_PROGRESS" && (
                        <BookingComponent
                          project_id={project.project.id}
                          user_id={userDetails.id}
                          is_provider={checkIfUserIsProvider()}
                          provider_id={project.project.provider_id}
                          provider={providerDetails || {}}
                          meeting_hours={project.project.meeting_hours}
                          membership_active={project.project.active_subscription}
                        />
                      )}

                    {tab.name === "membership" &&
                      checkIfUserIsClient() &&
                      !checkIfEnterpriseUser() && (
                        <MembershipInfo
                          project_id={project.project.id}
                          user_id={userDetails.id}
                          membership_active={project.project.active_subscription}
                          project_status={project.project.status}
                        />
                      )}

                    {tab.name === "providerMeetings" && checkIfUserIsProvider() && (
                      <ProviderBookings
                        project_id={project?.project.id}
                        client_id={project?.project.user_id}
                      />
                    )}

                    {tab.name === "mentor-profile" &&
                      ((checkIfUserIsProvider() && (
                        <ClientsProfile
                          business_profile={project.business_profile}
                        />
                      )) ||
                        (checkIfUserIsClient() && providerDetails && (
                          <ConsultantProfile
                            id={project.project.provider_id}
                            provider={providerDetails || {}}
                          />
                        )))}

                    {/* Add more tab-specific content here if needed */}
                  </TabPanel>
                ))}
              </TabPanels>
            </Tabs>
          </Box>
        </Flex>
      )}
    </Box>
  );
};

export default ProjectDetail;
