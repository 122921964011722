import {
  Avatar,
  Box,
  Button,
  Flex,
  GridItem,
  HStack,
  Icon,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  Progress,
  Stack,
  Tag,
  Text,
  Tooltip,
  useColorModeValue,
  useDisclosure
} from "@chakra-ui/react";

import { DeleteIcon, EditIcon } from "@chakra-ui/icons";
import { IoEllipsisVerticalSharp } from "react-icons/io5";
import { Link, useNavigate } from "react-router-dom";
import Card from "../../Shared/Cards/Card";
import CardBody from "../../Shared/Cards/CardBody";
import CardHeader from "../../Shared/Cards/CardHeader";

import React from "react";

const WorkCard = ({
  id,
  brief,
  service_name,
  problem_statement,
  service_icon,
  provider_id,
  status,
  created_at,
  start_date,
  deadline,
  profile_picture,
  provider_first_name,
  provider_last_name,
  client_first_name,
  client_last_name,
  onDeleteSuccess,
  onEditClick,
  
}) => {
  const bgColor = useColorModeValue("white", "oleniumBlack.600");
  const borderColor = useColorModeValue("oleniumGray.200", "oleniumBlack.700");
  const textColor = useColorModeValue("oleniumGray.700", "oleniumGray.200");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuDisclosure = useDisclosure();

  const [progress, setProgress] = React.useState(0);

  const link = "/project/" + id;
  const navigate = useNavigate();

  // Function to map project status to color scheme
  const projectStatusColor = (projectStatus) => {
    const colors = {
      UNASSIGNED: "gray",
      AWAITING_ASSIGNMENT: "yellow",
      AWAITING_ACCEPTANCE: "yellow",
      IN_PROGRESS: "yellow",
      COMPLETED: "green",
      CLOSED: "gray",
    };
    return colors[projectStatus] || "red"; // Fallback color
  };

  // Function to render project status text
  const renderStatus = (status) => {
    const statusMap = {
      UNASSIGNED: "Unassigned",
      AWAITING_ASSIGNMENT: "Awaiting Assignment",
      AWAITING_ACCEPTANCE: "Awaiting Consultant Acceptance",
      IN_PROGRESS: "In Progress",
      COMPLETED: "Completed",
      CLOSED: "Closed",
    };

    return statusMap[status] || "Unknown Status"; // Fallback text
  };

  const deleteProject = () => {
    // API call to delete project here, this should only be available if the project is unassigned
    onDeleteSuccess(id);
    onClose();
  };

  const daysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const diffTime = Math.abs(due - today);
    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
    return diffDays;
  };

  const progressBarColor = useColorModeValue("ExperienceBankGreen");
  const currentColor = useColorModeValue("oleniumGray.200", "oleniumGray.700");

  React.useEffect(() => {
    const percentage = calculatePercentage(created_at, deadline);
    if (!isNaN(percentage)) {
      setProgress(percentage);
    }
  }, [created_at, deadline]);

  const calculatePercentage = (created_at, deadline) => {
    const start = new Date(created_at);
    const due = new Date(deadline);
    const today = new Date();
    const total = due - start;
    const elapsed = today - start;
    const percentage = (elapsed / total) * 100;

    if (percentage > 100) {
      return 100;
    } else if (percentage < 0) {
      return 0;
    } else {
      return percentage;
    }
  };

  const convertToDaysLeft = (dueDate) => {
    const today = new Date();
    const due = new Date(dueDate);
    const timeDiff = due.getTime() - today.getTime();
    const diffDays = Math.ceil(timeDiff / (1000 * 60 * 60 * 24));

    if (diffDays > 0) {
      if (diffDays === 1) {
        return "1 day left";
      } else {
        return diffDays + " days left";
      }
    } else if (diffDays === 0) {
      return "Due today";
    } else {
      const diffPastDays = Math.abs(diffDays);
      return diffPastDays + " days past due";
    }
  };

  // Helper function to truncate text to a specified length
  const truncateText = (text, maxLength = 400) => {
    if (!text) return "";
    if (text.length <= maxLength) return text;
    return text.substring(0, maxLength) + "...";
  };

  return (
    <GridItem colSpan={1} rowSpan={1} w="100%">
      <Card
        alignSelf="flex-start"
        p={5}
        borderRadius={"xl"}
        bg={bgColor}
        borderColor={borderColor}
        borderWidth="2px"
        _hover={{
          shadow: "lg",
          cursor: "pointer",
          borderColor: "blue.500",
          borderWidth: "2px",
        }}
        onClick={() => navigate(link)}
      >
        <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
          <ModalOverlay />
          <ModalContent>
            <ModalBody p={5}>
              <Text mb="0px">
                Are you sure you want to delete this project?
              </Text>
            </ModalBody>

            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={onClose}>
                Close
              </Button>
              <Button color="red.500" variant="solid" onClick={deleteProject}>
                Confirm Delete
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>

        <CardHeader>
          <Flex justify="space-between" w="100%">
            <Flex>
              <Image
                src={service_icon}
                alt={service_name}
                width="100%"
                height="100%"
                objectFit="cover"
                boxSize="50px"
                mr={4}
                borderRadius={"full"}
              />

              <Flex direction="column">
                <Text
                  fontSize="md"
                  color={textColor}
                  fontWeight="bold"
                  mb="8px"
                >
                  {service_name}
                </Text>
                <Stack direction="row">
                  <Tooltip label={renderStatus(status)} aria-label="Status Tooltip">
                    <Tag
                      size="sm"
                      variant="outline"
                      colorScheme={projectStatusColor(status)}
                      rounded={"full"}
                    >
                      {renderStatus(status)}
                    </Tag>
                  </Tooltip>
                </Stack>
              </Flex>
            </Flex>
          </Flex>
        </CardHeader>

        <CardBody>
          <Flex direction="column">

            <Text
              fontSize="sm"
              color={textColor}
              mb="8px"
              my={4}
              minH={"200px"}
            >
              {truncateText(problem_statement)}
            </Text>

            <Flex justify="space-between" w="100%">
              <HStack>
                <Avatar
                  size="sm"
                  src={profile_picture}
                  name={`${client_first_name} ${client_last_name}`}
                />
                <Text color="gray.100" fontSize="md" fontWeight="bold" pl={2}>
                  {client_first_name} {client_last_name}
                </Text>
              </HStack>
              <Flex direction="column">
                <Link to={link}>
                  <Button colorScheme="gray" size="sm">
                    View Project
                  </Button>
                </Link>
              </Flex>
            </Flex>
          </Flex>
        </CardBody>
      </Card>
    </GridItem>
  );
};

export default WorkCard;
