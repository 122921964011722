import {
  Box,
  Button,
  Divider,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  Heading,
  Input,
  InputGroup,
  InputLeftAddon,
  Select,
  Spacer,
  Textarea,
  Checkbox,
  useColorModeValue,
  useToast
} from "@chakra-ui/react";
import axios from "axios";
import React, { useContext, useEffect, useState } from "react";
import { UserContext } from "../../../../UserContext";

const BusinessProfile = () => {
  const textColor = useColorModeValue("oleniumGray.700", "white");
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const apiUrl = process.env.REACT_APP_API_URL;
  const toast = useToast();

  const { idToken } = useContext(UserContext);

  const [businessProfile, setBusinessProfile] = useState({});
  const [originalBusinessProfile, setOriginalBusinessProfile] = useState({});
  const [otherIndustry, setOtherIndustry] = useState("");
  const [isProfileChanged, setIsProfileChanged] = useState(false); // Track if profile has changed
  const [isLoading, setIsLoading] = useState(false);

  const fetchBusinessProfile = async () => {
    try {
      const response = await axios.get(`${apiUrl}/business`, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 404) {
        setBusinessProfile({});
        setOriginalBusinessProfile({});
      } else if (response.status === 200) {
        const profileData = response.data;
        if (profileData.businessIndustry === 'Other' && profileData.otherIndustry) {
          profileData.businessIndustry = profileData.otherIndustry;
        }
        setBusinessProfile(profileData);
        setOriginalBusinessProfile(profileData);
      } else {
        console.log(`Unexpected status code: ${response.status}`);
      }

    } catch (error) {
      console.error("Error fetching business profile", error);
    }
  };

  useEffect(() => {
    fetchBusinessProfile();
  }, []);

  const handleInputChange = (e) => {
    setBusinessProfile({
      ...businessProfile,
      [e.target.name]: e.target.value,
    });
    setIsProfileChanged(true); // Set profile change flag to true
  };

  const handleCheckboxChange = (e) => {
    setBusinessProfile({
      ...businessProfile,
      [e.target.name]: e.target.checked,
    });
    setIsProfileChanged(true); // Set profile change flag to true
  };

  const handleCancel = () => {
    setBusinessProfile(originalBusinessProfile);
    setIsProfileChanged(false); // Reset profile change flag
  };

  const handleOtherIndustryChange = (e) => {
    setOtherIndustry(e.target.value);
    setIsProfileChanged(true); // Set profile change flag to true
  };

  const handleSave = async () => {
    if (!isProfileChanged) {
      toast({
        title: "No changes made.",
        description: "You haven't made any changes to your business profile.",
        status: "info",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      setIsLoading(true);

      const apiEndpoint = `${apiUrl}/business`;  // same endpoint for both create and update
      const httpMethod = Object.keys(businessProfile).length === 0 ? 'post' : 'put';

      // Prepare data to be saved
      const profileDataToSave = { ...businessProfile };
      if (profileDataToSave.businessIndustry === 'Other') {
        profileDataToSave.otherIndustry = otherIndustry;
      }

      await axios[httpMethod](apiEndpoint, profileDataToSave, {
        headers: {
          Authorization: `Bearer ${idToken}`,
          "Content-Type": "application/json",
        },
      });

      toast({
        title: "Profile updated.",
        description: "Your business profile has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      setOriginalBusinessProfile(profileDataToSave);
      setIsProfileChanged(false); // Reset profile change flag
      setIsLoading(false);
    } catch (error) {
      console.error("Error updating business profile", error);
      toast({
        title: "Error updating profile.",
        description: "There was an error updating your business profile.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  return (
    <>
      <Flex
        w={"100%"}
        my={5}
        alignSelf={{ lg: "flex-end" }}
        justifySelf={{ lg: "flex-end" }}
      >
        <Box bg={boxColor} w="100%" p={8} rounded="2xl" shadow="xl">
          <Heading as="h1" size="md" fontWeight="bold" mb={2} color={textColor}>
            Business Profile
          </Heading>
          <Divider my={4} />
          <Grid
            colSpan={{ base: 2, lg: 1 }}
            templateColumns="repeat(2, 1fr)"
            gap={6}
          >
            {/* Business name */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Business Name
              </FormLabel>
              <Input
                name="businessName"
                type="text"
                value={businessProfile.businessName || ""}
                onChange={handleInputChange}
                required
                w="100%"
              />
            </GridItem>

            {/* Business Industry */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Business Industry
              </FormLabel>
              <Select
                name="businessIndustry"
                value={businessProfile.businessIndustry || ""}
                onChange={handleInputChange}
              >
                <option value="">Select an industry</option>
                <option value="Technology">Technology</option>
                <option value="Finance">Finance</option>
                <option value="Healthcare">Healthcare</option>
                <option value="Education">Education</option>
                <option value="Retail">Retail</option>
                <option value="Manufacturing">Manufacturing</option>
                <option value="Construction">Construction</option>
                <option value="Transportation">Transportation</option>
                <option value="Hospitality">Hospitality</option>
                <option value="Entertainment">Entertainment</option>
                <option value="Agriculture">Agriculture</option>
                <option value="Mining">Mining</option>
                <option value="Utilities">Utilities</option>
                <option value="Real Estate">Real Estate</option>
                <option value="Energy">Energy</option>
                <option value="Food & Beverages">Food & Beverages</option>
                <option value="Pharmaceuticals">Pharmaceuticals</option>
                <option value="Telecommunications">Telecommunications</option>
                <option value="Automotive">Automotive</option>
                <option value="Aerospace & Defense">Aerospace & Defense</option>
                <option value="Textiles">Textiles</option>
                <option value="Consumer Goods">Consumer Goods</option>
                <option value="Publishing & Broadcasting">
                  Publishing & Broadcasting
                </option>
                <option value="Travel & Tourism">Travel & Tourism</option>
                <option value="Legal">Legal</option>
                <option value="Consulting">Consulting</option>
                <option value="Environmental Services">
                  Environmental Services
                </option>
                <option value="Government & Public Administration">
                  Government & Public Administration
                </option>
                <option value="Nonprofit">Nonprofit</option>
                <option value="Sports & Recreation">Sports & Recreation</option>
                <option value="Arts & Crafts">Arts & Crafts</option>
                <option value="Other">Other</option>
              </Select>

              {businessProfile.businessIndustry === "Other" && (
                <Input
                  name="otherIndustry"
                  type="text"
                  placeholder="Please specify"
                  value={otherIndustry}
                  onChange={handleOtherIndustryChange}
                  mt={2}
                />
              )}
            </GridItem>

            {/* Business Description */}
            <GridItem colSpan={2}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Business Description
              </FormLabel>
              <Textarea
                name="businessDescription"
                value={businessProfile.businessDescription || ""}
                onChange={handleInputChange}
                height={32}
              />
            </GridItem>

            {/* Business Size */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Business Size
              </FormLabel>
              <Select
                name="businessSize"
                value={businessProfile.businessSize || ""}
                onChange={handleInputChange}
              >
                <option value="">Select a size</option>
                <option value="Small">Small (1-10 employees)</option>
                <option value="Medium">Medium (11-50 employees)</option>
                <option value="Large">Large (51+ employees)</option>
              </Select>
            </GridItem>

            {/* Website */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Website
              </FormLabel>
              <InputGroup size="md">
                <InputLeftAddon children="https://" />
                <Input
                  name="website"
                  type="text"
                  value={businessProfile.website || ""}
                  onChange={handleInputChange}
                  required
                  pattern="https?://.+"
                  autoComplete="off"
                />
              </InputGroup>
            </GridItem>

            {/* Company Number */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                Company Number
              </FormLabel>
              <Input
                name="companyNumber"
                type="text"
                value={businessProfile.companyNumber || ""}
                onChange={handleInputChange}
                required
                w="100%"
              />
            </GridItem>

            {/* VAT Registered */}
            <GridItem colSpan={{ base: 2, lg: 1 }}>
              <FormLabel fontWeight="semibold" fontSize="sm" mb="10px">
                VAT Registered
              </FormLabel>
              <Checkbox
                name="vatRegistered"
                isChecked={businessProfile.vatRegistered || false}
                onChange={handleCheckboxChange}
                mt={2}
              >
                Are you VAT registered?
              </Checkbox>
            </GridItem>
          </Grid>
          <Flex mt={8}>
            <Spacer />
            <Button
              onClick={handleSave}
              mr={2}
              size={"sm"}
              variant={"ExperienceBankGreen"}
              disabled={!isProfileChanged && Object.keys(businessProfile).length > 0}
              isLoading={isLoading}
            >
              {Object.keys(businessProfile).length === 0 ? "Create Profile" : "Save Changes"}
            </Button>
          </Flex>
        </Box>
      </Flex>
    </>
  );
};

export default BusinessProfile;
