import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box, Center, Image, VStack, Tag } from "@chakra-ui/react";
import Wallpaper from "../assets/images/payment-background.jpg";

const AuthLayout = ({ children }) => {
  const [background, setBackground] = useState({
    wallpaperURL: "",
    photographer: "",
    location: ""
  });
  const [loading, setLoading] = useState(true);

  const API_URL = process.env.REACT_APP_API_URL;

  useEffect(() => {

    const fetchWallpaper = async () => {
      try {
        const response = await axios.get(API_URL + "/background-wallpaper");
        if (!didCancel) { // Only set state if the component is still mounted
          setBackground({
            wallpaperURL: response.data.image_url,
            photographer: response.data.photographer,
            location: response.data.location
          });
          setLoading(false);
        }
      } catch (error) {
        if (!didCancel) { // Only set state if the component is still mounted
          console.error("Error fetching wallpaper:", error);
          setLoading(false);
          setBackground({
            wallpaperURL: Wallpaper,
            photographer: "Unlocked Expertise",
            location: "Newcastle"
          });
        }
      }
    };

    let didCancel = false;
    fetchWallpaper();

    return () => {
      didCancel = true;
    }

  }, [API_URL]);


  return (
    <VStack h={{ base: "100vh", md: "100vh" }} w="100%" maxW="100%" position="relative">
      <Image
        src={background.wallpaperURL}
        alt="Background Image"
        objectFit="cover"
        w="100%"
        h="100%"
        position="absolute"
        zIndex="1"
        opacity={loading ? 0 : 1}
        transition="opacity 1s ease-in-out"
      />
      <Box
        w="100%"
        h="100%"
        position="absolute"
        zIndex="2"
        bg="black"
        opacity={background.wallpaperURL ? 0 : 1} // Show the black box with some transparency if wallpaperURL is not set
        transition="opacity 1s ease-in-out"
      />
      {/* Tag for photographer and location */}
      {background.photographer && (
        <Tag
          size="md"
          variant="solid"
          colorScheme="black"
          position="absolute"
          bottom="3"
          left="3"
          zIndex="3"
        >
          Photo by {background.photographer}
          {background.location && background.location !== "Not specified" ? `, ${background.location}` : ''}
        </Tag>
      )}
      <Center zIndex={3} w="100%" h="100%" position="absolute">
        {children}
      </Center>
    </VStack>
  );
};

export default AuthLayout;
