import {
  Box,
  Button,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Tag,
  Text,
  VStack,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";
import React, { useState } from "react";
import ReactPlayer from "react-player";
import { useNavigate } from "react-router-dom";
import heroImage from "../../../assets/images/background-features.jpg";

const Hero = () => {
  const navigate = useNavigate();
  const textColor = useColorModeValue("oleniumBlack.600", "white");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [scrollPosition] = useState(0);

  const overlayOpacity = 0.4;

  return (
    <Box
      width="100%"
      align="center"
      height={{ base: "70vh", md: "90vh" }}
      justifyContent={"center"}
      alignItems={"center"}
      display={"flex"}
      position="relative"
      overflow="hidden"
      backgroundImage={`url(${heroImage})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundClip={"padding-box"}
    >
      <Box
        width="100%"
        height="100%"
        position="absolute"
        top={0}
        left={0}
        zIndex={1}
        background={`linear-gradient(rgba(0, 0, 0, ${overlayOpacity}), rgba(0, 0, 0, ${overlayOpacity}))`}
      />
      <VStack
        spacing={{ base: 6, md: 8 }}
        align="center"
        w={"100%"}
        maxW={"1000px"}
        mx={"auto"}
        lineHeight={"1"}
        zIndex={5}
      >
        <Tag
          size={{ base: "sm", md: "lg" }}
          variant="outline"
          borderRadius="full"
          px={4}
          py={1}
          fontWeight="bold"
          fontSize="md"
          letterSpacing="wide"
          color={textColor}
          background={"whiteAlpha.300"}
        >
          Become a mentor
        </Tag>

        <Text
          fontSize={{ base: "42px", md: "2xl", lg: "4xl", xl: "7xl" }}
          fontWeight="bold"
          textAlign={"center"}
          lineHeight={"1.1"}
          overflow="visible"
          textShadow="-2px -2px -2px rgba(0, 0, 0, 0.2)"
        >
          Join a network of <Text as="span" bgGradient="linear(to-r, #A0B100, #6A7500)" backgroundClip="text">experienced professionals</Text> and help shape the future of ambitious business owners.
        </Text>

        <HStack spacing={4} align="center" mx={"auto"} justify={"center"}>
          <Button
            size={{ base: "sm", md: "md" }}
            variant={"ExperienceBankGreen"}
            onClick={() => navigate("/mentor-onboarding")}
          >
            Get Started Now
          </Button>
        </HStack>
      </VStack>

      <Modal isOpen={isOpen} onClose={onClose} size={"6xl"}>
        <ModalOverlay />
        <ModalContent
          bg={"oleniumBlack.600"}
          color={"white"}
          pb={4}
          rounded={"2xl"}
        >
          <ModalHeader>Connecting the dots</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Box borderRadius="10px" overflow="hidden">
              <ReactPlayer
                url={
                  "https://olenium-public-resources.s3.eu-west-1.amazonaws.com/connecting-the-dots.mp4"
                }
                controls
                playing={isOpen}
                width="100%"
                height="auto"
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Hero;
