// BookingComponent.js
import React, { useState, useEffect } from "react";
import {
  Box,
  Button,
  Flex,
  useColorModeValue,
  useToast,
  Text,
  HStack,
  Tag,
  Select,
  VStack,
  Icon,
  Heading,
  useBreakpointValue,
  Tooltip,
  Hide,
  useDisclosure,
  FormControl,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  Spinner,
  ModalFooter,
} from "@chakra-ui/react";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import moment from "moment";
import { loadStripe } from "@stripe/stripe-js";
import { BsCalendarX } from "react-icons/bs";
import { FaCalendarCheck } from "react-icons/fa";
import MeetingModal from "./MeetingModal";
import AvailableTimesModal from "./AvailableTimesModal";
import {
  MdOutlineRadioButtonChecked,
  MdRadioButtonUnchecked,
  MdShoppingCart,
} from "react-icons/md";
import { useNavigate } from "react-router-dom";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
const API_URL = process.env.REACT_APP_API_URL;

const Overlay = ({ isActive, project_id, onClose }) => {
  const navigate = useNavigate();

  const handleNavigate = () => {
    onClose();
    navigate(`/project/${project_id}/membership`);
  };

  return isActive ? (
    <Box
      position="absolute"
      top="0"
      left="0"
      width="100%"
      height="100%"
      bg="rgba(0, 0, 0, 0.5)"
      display="flex"
      alignItems="center"
      justifyContent="center"
      backdropFilter="blur(3px)"
      zIndex="10"
      color="white"
      fontSize="2xl"
      fontWeight="bold"
    >
      <VStack spacing={4}>
        <Text
          fontSize="xl"
          my={4}
          color="white"
          textAlign="center"
          fontWeight="bold"
          maxW={"400px"}
        >
          Enroll in a membership to book meetings or purchase additional hours
        </Text>
        <Button variant={"ExperienceBankGreen"} mt={2} onClick={handleNavigate}>
          Enroll in Membership
        </Button>
      </VStack>
    </Box>
  ) : null;
};

const BookingComponent = ({
  provider_id,
  user_id,
  is_provider,
  project_id,
  provider,
  meeting_hours,
  membership_active,
}) => {
  const [availableTimes, setAvailableTimes] = useState([]);
  const [bookings, setBookings] = useState([]);
  const [selectedSlot, setSelectedSlot] = useState(null);
  const [filter, setFilter] = useState("All Bookings");
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hours, setHours] = useState(1);
  const [isSlotModalOpen, setIsSlotModalOpen] = useState(false);
  const {
    isOpen: isOpenMeeting,
    onOpen: onOpenMeeting,
    onClose: onCloseMeeting,
  } = useDisclosure();
  const [selectedBooking, setSelectedBooking] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const textColor = useColorModeValue("black", "white");
  const bgColor = useColorModeValue("white", "oleniumBlack.700");

  const navigate = useNavigate();

  const fetchTimeSlotsAndBookings = async () => {
    if (!provider_id || provider_id === "N/A") {
      return;
    }

    setIsLoading(true); // Start loading

    try {
      const token = await getIdToken();
      const response = await fetch(
        `${API_URL}/slots/client/project/${project_id}/provider/${provider_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        throw new Error("Failed to fetch time slots and bookings");
      }

      const data = await response.json();
      const now = new Date();

      const futureSlots = data.available_slots.filter(
        (slot) => new Date(slot.date_time) > now
      );

      setAvailableTimes(futureSlots);
      setBookings(data.bookings);
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsLoading(false); // End loading
    }
  };

  const handleTimeSlotClick = (slot) => {
    setSelectedSlot(slot);
  };

  const handleBookSlot = async () => {
    if (!selectedSlot) {
      toast({
        title: "No Slot Selected",
        description: "Please select a time slot to book.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    try {
      const token = await getIdToken();
      const response = await fetch(`${API_URL}/slots/book`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          slot_id: selectedSlot.slot_id,
          provider_id: provider_id,
          project_id: project_id,
        }),
      });

      const responseData = await response.json();

      if (!response.ok) {
        throw new Error(responseData.message || "Failed to book time slot");
      }

      setSelectedSlot(null);

      fetchTimeSlotsAndBookings();

      setIsSlotModalOpen(false);

      toast({
        title: "Success",
        description: "Time slot booked successfully",
        status: "success",
        duration: 5000,
        isClosable: true,
      });

      fetchTimeSlotsAndBookings();
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handlePurchaseHours = async () => {
    try {
      const token = await getIdToken();
      const response = await fetch(
        `${API_URL}/stripe/recharge/${project_id}`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },

          body: JSON.stringify({
            user_id,
            hours,
          }),
        }
      );

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Failed to purchase hours");
      }

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({
        sessionId: data.sessionId,
      });

      if (error) {
        throw new Error(error.message);
      }
    } catch (error) {
      toast({
        title: "Error",
        description: error.message,
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    } finally {
      setIsModalOpen(false);
    }
  };

  const handleOpenSlotModal = () => {
    fetchTimeSlotsAndBookings();
    setIsSlotModalOpen(true);
  };

  useEffect(() => {
    fetchTimeSlotsAndBookings();
  }, [provider_id]);

  const filteredBookings = bookings.filter((booking) => {
    const now = new Date();
    switch (filter) {
      case "All Bookings":
        return true;
      case "available":
        return booking.status === "AVAILABLE";
      case "future":
        return (
          (booking.status === "ACCEPTED" ||
            booking.status === "AWAITING_REVIEW") &&
          new Date(booking.date_time) > now
        );
      case "past":
        return (
          booking.status === "ACCEPTED" && new Date(booking.date_time) < now
        );
      case "cancelled":
        return booking.status === "CANCELLED";
      default:
        return true;
    }
  });

  const renderRemainingHours = () => {
    if (meeting_hours === 0) {
      return (
        <Tag colorScheme="blue" size="lg" mr={2}>
          No Hours Remaining
        </Tag>
      );
    }

    if (meeting_hours === 1) {
      return (
        <Tag colorScheme="blue" size="lg" mr={2}>
          {meeting_hours} Hour Remaining
        </Tag>
      );
    }

    return (
      <Tag colorScheme="blue" size="lg" mr={2}>
        {meeting_hours} Hours Left
      </Tag>
    );
  };

  const renderStatusTag = (status) => {
    if (status === "PAID") {
      status = "COMPLETE";
    }

    const statuses = {
      ACCEPTED: "green",
      AWAITING_REVIEW: "yellow",
      DENIED: "red",
      CANCELLED: "red",
    };

    const capitalizedStatus = status
      .toLowerCase()
      .replace(/\b\w/g, (l) => l.toUpperCase());

    return (
      <Tag variant="outline" colorScheme={statuses[status] || "yellow"}>
        {capitalizedStatus.replace("_", " ")}
      </Tag>
    );
  };

  const actionIcons = useBreakpointValue({ base: true, md: false });

  return (
    <Flex direction="column" w="100%">
      {!provider_id || provider_id === "N/A" ? (
        <Text fontSize="2xl" color={textColor}>
          No provider assigned
        </Text>
      ) : (
        <>
          <HStack
            spacing={4}
            my={1}
            flex="1"
            justify={{ base: "center", md: "space-between" }}
          >
            <Hide below="md">
              <Select
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                w="200px"
                size={"sm"}
                borderRadius={"lg"}
              >
                <option value="All Bookings">All Bookings</option>
                <option value="future">Future Bookings</option>
                <option value="past">Past Bookings</option>
                <option value="cancelled">Cancelled Bookings</option>
              </Select>
            </Hide>

            <Flex>
              {renderRemainingHours()}

              {actionIcons ? (
                <HStack spacing={2}>
                  <Tooltip label="Purchase Hours">
                    <Button onClick={() => setIsModalOpen(true)} size="sm">
                      <Icon as={MdShoppingCart} />
                    </Button>
                  </Tooltip>
                  <Tooltip label="View Available Slots">
                    <Button onClick={handleOpenSlotModal} size="sm">
                      <Icon as={FaCalendarCheck} />
                    </Button>
                  </Tooltip>
                </HStack>
              ) : (
                <>
                  <Button
                    onClick={() => setIsModalOpen(true)}
                    size="sm"
                    mr={2}
                  >
                    Purchase Hours
                  </Button>

                  <Button
                    onClick={handleOpenSlotModal}
                    variant={"ExperienceBankGreen"}
                    size="sm"
                  >
                    View Available Slots
                  </Button>
                </>
              )}
            </Flex>
          </HStack>

          <Box w="100%">
            {isLoading ? (
              // Conditionally render loading spinner
              <VStack
                spacing={4}
                mt={4}
                align="center"
                w="100%"
                h="200px"
                justify="center"
              >
                <Spinner size="xl" color={textColor} />
              </VStack>
            ) : filteredBookings.length === 0 ? (
              <VStack
                spacing={4}
                mt={4}
                align="center"
                w="100%"
                h="200px"
                justify="center"
              >
                <Icon boxSize={8} color={textColor} as={BsCalendarX} />
                <Text fontSize="2xl" color={textColor}>
                  No bookings found
                </Text>
                <Button
                  onClick={handleOpenSlotModal}
                  variant={"ExperienceBankGreen"}
                  size="sm"
                >
                  Book meeting
                </Button>
              </VStack>
            ) : (
              filteredBookings.map((booking) => {
                const bookingDate = moment(booking.date_time);
                const now = moment();
                let scheduleText = "";

                if (bookingDate.isSame(now, "day")) {
                  scheduleText = `Scheduled for Today at ${bookingDate.format(
                    "h:mm A"
                  )}`;
                } else if (bookingDate.isAfter(now, "day")) {
                  scheduleText = `Scheduled for ${bookingDate.format(
                    "MMMM D, YYYY h:mm A"
                  )}`;
                } else {
                  scheduleText = `Occurred on ${bookingDate.format(
                    "MMMM D, YYYY h:mm A"
                  )}`;
                }

                return (
                  <Box
                    key={booking.booking_id}
                    alignSelf="flex-start"
                    p={5}
                    borderRadius={"xl"}
                    bg={bgColor}
                    borderWidth="2px"
                    width={"100%"}
                    _hover={{
                      shadow: "lg",
                      cursor: "pointer",
                      borderColor: "#A0B100",
                      borderWidth: "2px",
                    }}
                    my={6}
                    maxW={"600px"}
                    mx="auto"
                  >
                    <HStack spacing={4} w="100%">
                      <VStack align="flex-start" spacing={2} w="100%">
                        <Text
                          color={textColor}
                          fontSize="md"
                          fontWeight="bold"
                          mb={2}
                        >
                          Booking with {provider.given_name}{" "}
                          {provider.family_name}
                        </Text>
                        <HStack spacing={4}>
                          <Tag colorScheme="blue">{scheduleText}</Tag>
                          {renderStatusTag(booking.status)}
                        </HStack>
                      </VStack>
                      {booking.status === "ACCEPTED" ? (
                        <Button
                          size="sm"
                          variant={"ExperienceBankGreen"}
                          onClick={() => {
                            setSelectedBooking(booking.slot_id);
                            onOpenMeeting();
                          }}
                          mt={4}
                          px={6}
                        >
                          Join Meeting
                        </Button>
                      ) : null}
                    </HStack>
                  </Box>
                );
              })
            )}
          </Box>

          <AvailableTimesModal
            isOpen={isSlotModalOpen}
            onClose={() => setIsSlotModalOpen(false)}
            availableTimes={availableTimes}
            membership_active={membership_active}
            project_id={project_id}
            handleTimeSlotClick={handleTimeSlotClick}
            handleBookSlot={handleBookSlot}
            selectedSlot={selectedSlot}
            isLoading={isLoading} // Pass isLoading to the modal
          />

          <Modal
            isOpen={isModalOpen}
            onClose={() => setIsModalOpen(false)}
            isCentered
          >
            <ModalOverlay />
            <ModalContent
              bg="oleniumBlack.700"
              borderRadius={8}
              position="relative"
            >
              <Overlay
                isActive={!membership_active}
                onClose={() => setIsModalOpen(false)}
                project_id={project_id}
              />
              <ModalHeader>Purchase Additional Hours</ModalHeader>
              <ModalCloseButton />
              <ModalBody>
                <Text fontSize="md" color={textColor} mb={6}>
                  Purchase additional hours to book more meetings with{" "}
                  {provider.given_name} {provider.family_name}
                </Text>
                <FormControl>
                  <VStack spacing={4}>
                    {[1, 3, 6].map((option) => (
                      <Box
                        key={option}
                        w="100%"
                        border={
                          hours === option
                            ? "2px solid #A0B100"
                            : "2px solid #A0B100"
                        }
                        background={
                          hours === option ? "#A0B100" : "transparent"
                        }
                        borderRadius="lg"
                        p={4}
                        cursor="pointer"
                        onClick={() => setHours(option)}
                      >
                        <HStack spacing={4} justify="space-between">
                          <VStack
                            align="flex-start"
                            spacing={2}
                            color="white"
                            w="100%"
                          >
                            <Heading
                              as="h4"
                              fontSize="lg"
                              fontWeight="semibold"
                              color="white"
                              mb={1}
                            >
                              {option} Hour{option > 1 ? "s" : ""}
                            </Heading>
                            <Heading as="h5" fontSize="sm" color="white">
                              £{option * 150} + VAT
                            </Heading>
                          </VStack>
                          <Icon
                            as={
                              hours === option
                                ? MdOutlineRadioButtonChecked
                                : MdRadioButtonUnchecked
                            }
                            boxSize={8}
                            color="white"
                          />
                        </HStack>
                      </Box>
                    ))}
                  </VStack>
                </FormControl>
              </ModalBody>
              <ModalFooter>
                <Button
                  variant={"ExperienceBankGreen"}
                  onClick={handlePurchaseHours}
                  isDisabled={hours === 0}
                >
                  Purchase
                </Button>
                <Button ml={3} onClick={() => setIsModalOpen(false)}>
                  Cancel
                </Button>
              </ModalFooter>
            </ModalContent>
          </Modal>
          <MeetingModal
            isOpen={isOpenMeeting}
            onClose={onCloseMeeting}
            slot_id={selectedBooking} // Pass the selectedBooking slot_id here
            date_time={
              bookings.find((booking) => booking.slot_id === selectedBooking)
                ?.date_time
            }
          />
        </>
      )}
    </Flex>
  );
};

export default BookingComponent;
