// WhatsNext.js

import {
  Box,
  Checkbox,
  Divider,
  Flex,
  HStack,
  Icon,
  Stack,
  Text,
  VStack,
  useColorModeValue,
  FormControl,
  Button,
  Spinner,
  useBreakpointValue,
} from "@chakra-ui/react";
import React, { useState } from "react";
import {
  BsFillEnvelopeFill,
  BsFillPatchCheckFill,
} from "react-icons/bs";
import { AiFillPoundCircle } from "react-icons/ai";

// Define the PDF URL
const PDF_URL = "https://experience-bank-legal.s3.eu-west-1.amazonaws.com/EBU+Provider+TCs.pdf";

const ourValues = [
  {
    icon: BsFillEnvelopeFill,
    title: "Look out for an email",
    description:
      "Our team will review your account and send you an email when you're approved.",
  },
  {
    icon: AiFillPoundCircle,
    title: "Setup payouts",
    description:
      "Setup payments with Stripe so you can get paid for your consulting work.",
  },
  {
    icon: BsFillPatchCheckFill,
    title: "Get started",
    description:
      "Once you're approved, we'll match you with clients and you can start consulting.",
  },
];

const WhatsNext = ({ agreements, setAgreements }) => {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  
  // State to track if the PDF has been viewed
  const [hasViewedPDF, setHasViewedPDF] = useState(false);
  
  // PDF document state
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [isLoading, setIsLoading] = useState(true);

  // Responsive page width
  const pageWidth = useBreakpointValue({ base: 300, md: 800 });

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setAgreements((prev) => ({
      ...prev,
      [name]: checked,
    }));
  };
  
  // New handler to open PDF in a new window and set hasViewedPDF to true
  const handleViewPDF = () => {
    window.open(PDF_URL, '_blank');
    setHasViewedPDF(true);
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
    setIsLoading(false);
  };

  const goToPrevPage = () =>
    setPageNumber(prevPageNumber => Math.max(prevPageNumber - 1, 1));
  
  const goToNextPage = () =>
    setPageNumber(prevPageNumber => Math.min(prevPageNumber + 1, numPages));

  return (
    <Flex direction="column" align="center" justify="center" w="100%">
      <Box
        bg={boxColor}
        p={10}
        rounded="2xl"
        maxW={"800px"}
        w={"100%"}
        height={"100%"}
        display="flex"
        flexDirection="column"
      >
        <Box w={"100%"} h={"100%"} display="flex" flexDirection="column">
          <Text
            fontSize={{ base: "2xl", md: "4xl" }}
            align={"left"}
            fontWeight="900"
            lineHeight={{ base: "1.2", md: "1.1" }}
            bgGradient="linear(to-r, #A0B101, #7D8B00)"
            backgroundClip="text"
            textAlign={"center"}
          >
            What happens next?
          </Text>
          <Stack
            spacing={10}
            align="center"
            mx="auto"
            py={8}
            mt={8}
            maxW={"450px"}
            justify={"center"}
          >
            {ourValues.map((value, index) => (
              <React.Fragment key={index}>
                {renderValue(value.icon, value.title, value.description)}
                {index < ourValues.length - 1 && <Divider />}
              </React.Fragment>
            ))}
          </Stack>

          {/* Single Checkbox for Agreements */}
          <Box mt={8} maxW={"450px"} mx="auto">
            <VStack align="start" spacing={4}>
              <FormControl>
                <Checkbox
                  name="agree"
                  isChecked={agreements.agree}
                  onChange={handleCheckboxChange}
                  colorScheme="green"
                  isDisabled={!hasViewedPDF}
                >
                  I agree to the <b>Terms of Service</b> of the platform provider and confirm that I have a valid <b>Professional Indemnity Insurance</b> policy before conducting any consulting or mentoring sessions.
                </Checkbox>
              </FormControl>
              
              {/* Button to View Terms */}
              <Button colorScheme="blue" onClick={handleViewPDF}>
                View Terms of Service
              </Button>
            </VStack>
          </Box>
        </Box>
      </Box>

      {/* Optional: If you still want to display the PDF within the page instead of a modal,
          you can uncomment and use the following code. Otherwise, it's removed as per the requirement. */}

      {/* <Document
        file={PDF_URL}
        onLoadSuccess={onDocumentLoadSuccess}
        onLoadError={(error) => {
          console.error("Error while loading PDF:", error);
          setIsLoading(false);
        }}
        loading=""
      >
        <Page
          pageNumber={pageNumber}
          width={pageWidth}
        />
      </Document> */}
    </Flex>
  );
};

const renderValue = (icon, title, description) => (
  <HStack
    spacing={4}
    align="center"
    width="100%"
    flexDirection={{ base: "column", md: "row" }}
  >
    <Box
      w={12}
      h={12}
      minW={12}
      rounded="10px"
      bg="oleniumBlack.100"
      display="flex"
      alignItems="center"
      justifyContent="center"
      marginBottom={{ base: 4, md: 0 }}
      marginRight={{ base: 0, md: 4 }}
      aspectRatio={1}
    >
      <Icon as={icon} boxSize={{ base: 4, md: 6 }} />
    </Box>
    <VStack
      align={{ base: "center", md: "start" }}
      spacing={1}
      h={12}
      justifyContent={"center"}
      textAlign={{ base: "center", md: "left" }}
    >
      <Text
        fontSize={{ base: "lg", md: "xl" }}
        fontWeight="bold"
        textAlign={{ base: "center", md: "left" }}
      >
        {title}
      </Text>
      <Text
        fontSize={{ base: "md", md: "lg" }}
        color={"oleniumGray.700"}
        textAlign={{ base: "center", md: "left" }}
      >
        {description}
      </Text>
    </VStack>
  </HStack>
);

export default WhatsNext;
