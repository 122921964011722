import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    HStack,
    Avatar,
    Grid,
    GridItem,
    Image,
    VStack,
    Heading,
    Tag,
    Flex,
    Stack,
    Divider,
    Button,
    Icon,
    IconButton,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Spinner,
    useBreakpointValue
} from "@chakra-ui/react";
import axios from "axios";
import { getIdToken } from "../../../cognito/cognitoAuth";
import PortfolioItemModal from '../NewGoal/Forms/PortfolioModal';
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import ExperienceBankLogoAnimated from "../../ExperienceBankLogoAnimated";

const API_URL = process.env.REACT_APP_API_URL;

const SingleConsultantCard = ({
    id,
    brief,
    service_name,
    problem_statement,
    service_icon,
    provider_id,
    status,
    created_at,
    start_date,
    deadline,
    profile_picture,
    provider_first_name,
    provider_last_name,
    onDeleteSuccess,
    onEditClick
}) => {
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [isPortfolioItemModalOpen, setIsPortfolioItemModalOpen] = useState(false);
    const [selectedPortfolioItem, setSelectedPortfolioItem] = useState(null);

    const openPortfolioItemModal = (item) => {
        setSelectedPortfolioItem(item);
        setIsPortfolioItemModalOpen(true);
    };

    const link = "/project/" + id;
    const navigate = useNavigate();

    const getProviderDetails = async (providerId) => {
        if (providerId === "N/A") {
            return; // Skip fetching details if provider_id is "N/A"
        }

        const idToken = await getIdToken();

        try {
            const applicationResponse = await axios.get(
                `${API_URL}/provider/${providerId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            );

            const parsedResponse = JSON.parse(applicationResponse.data.body);

            setSelectedApplication(parsedResponse);
        } catch (error) {
            console.error("Error fetching provider details", error);
        }
    };

    useEffect(() => {
        getProviderDetails(provider_id);
    }, [provider_id]);

    const renderStatusTag = (status) => {
        switch (status) {
            case "IN_PROGRESS":
                return <Tag colorScheme="yellow">In Progress</Tag>;
            case "UNASSIGNED":
                return <Tag colorScheme="red">Unassigned</Tag>;
            case "COMPLETED":
                return <Tag colorScheme="green">Completed</Tag>;
            case "CANCELLED":
                return <Tag colorScheme="red">Cancelled</Tag>;
            case "AWAITING_ACCEPTANCE":
                return <Tag colorScheme="yellow">Awaiting Consultant Acceptance</Tag>;
            default:
                return <Tag colorScheme="blue">Pending</Tag>;
        }
    };

    const renderMatchDateasTag = (date) => {
        const matchDate = new Date(date);
        const month = matchDate.toLocaleString('default', { month: 'long' });
        const day = matchDate.getDate();
        const year = matchDate.getFullYear();

        // render as Tag component
        return (
            <Tag>
                {`Created ${month} ${day}, ${year}`}
            </Tag>
        );
    };

    const renderJoinedDate = (date) => {
        const joinedDate = new Date(date);
        const month = joinedDate.toLocaleString('default', { month: 'long' });
        const year = joinedDate.getFullYear();
        return `Joined ${month} ${year}`;
    };

    return (
        <Box
            borderRadius={{ base: "xl", md: "3xl" }}
            overflow="hidden"
            boxShadow="md"
            bg="oleniumBlack.600"
            w="100%"
            p={{ base: 4, md: 8 }}
            mb={4}
        >
            {selectedApplication ? (
                <>
                    <HStack spacing={4} mb={4} justify="space-between" flexDirection={{ base: "column", md: "row" }}>
                        <Flex alignItems="center" flexDirection={{ base: "column", md: "row" }}>
                            <Avatar size="xl" name={`${provider_first_name} ${provider_last_name}`} src={profile_picture} />
                            <VStack alignItems={{ base: "center", md: "flex-start" }} spacing={2} ml={{ base: 0, md: 4 }} mt={{ base: 4, md: 0 }}>
                                <Text color="white" fontSize="xl" fontWeight="bold">
                                    {`${provider_first_name} ${provider_last_name}`}
                                </Text>
                            </VStack>
                        </Flex>
                        {/* Large round button with arrow icon */}
                        <IconButton
                            colorScheme="blue"
                            w={{ base: "50px", md: "70px" }}
                            h={{ base: "50px", md: "70px" }}
                            borderRadius="full"
                            icon={<FaArrowRightLong size={36} />} // Adjust the size as needed
                            aria-label="View profile"
                            bg={"#A0B100"}
                            border={"2px"}
                            borderColor={"#A0B100"}
                            _hover={{
                                bg: "#869500",
                                borderColor: "#A0B100",
                                color: "white"
                            }}
                            onClick={() => navigate(link)}
                            mt={{ base: 4, md: 0 }}
                        />
                    </HStack>

                    <Divider my={8} />

                    <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)" }} gap={4}>
                        <GridItem colSpan={1}>
                            {/* Render status of project */}
                            <HStack spacing={4} mb={4}>
                                {renderStatusTag(status)}
                                <Tag colorScheme="blue">
                                    Verified Consultant
                                </Tag>
                            </HStack>
                            <Text
                                color="gray.400"
                                my={8}
                                mr={{ base: 0, md: 8 }}
                                // wrap text
                                whiteSpace="pre-wrap"
                            >
                                {selectedApplication.profile?.bio || "No bio available."}
                            </Text>
                        </GridItem>
                        <GridItem colSpan={1}>
                            <Box
                                bg="oleniumBlack.700"
                                boxShadow="sm"
                                borderRadius="xl"
                                p={{ base: "4", md: "6" }}
                            >
                                <Stack spacing="5">
                                    <Stack
                                        justify="space-between"
                                        direction={{ base: "column", sm: "row" }}
                                        spacing="5"
                                    >
                                        <Stack spacing="1">
                                            <Heading as="h3" size="md" color="white">
                                                Experience
                                            </Heading>
                                        </Stack>
                                    </Stack>
                                    <Accordion allowToggle>
                                        {selectedApplication.experience && selectedApplication.experience.map((experience, id) => (
                                            <AccordionItem key={id}>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left" color="white" fontWeight="bold" my={2}>
                                                            {experience.title}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={2} color="gray.400">
                                                    <HStack spacing="3" mb={2} pb={2}>
                                                        <Tag colorScheme="blue">{experience.company}</Tag>
                                                        <Tag colorScheme="blue">{experience.location}</Tag>
                                                        <Tag colorScheme="blue">{experience.startDate} - {experience.present ? "Present" : experience.endDate}</Tag>
                                                    </HStack>
                                                    <Text>{experience.description}</Text>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </Stack>
                            </Box>
                        </GridItem>
                    </Grid>

                    {selectedApplication.portfolio_items && selectedApplication.portfolio_items.length > 0 &&
                        <>
                            <Divider my={8} />

                            <Heading as="h3" size="md" color="white" mb={4}>Recent Posts</Heading>
                            <Grid templateColumns={{ base: "repeat(1, 1fr)", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                                {selectedApplication.portfolio_items.map((item, index) => (
                                    <GridItem
                                        colSpan={1}
                                        rowSpan={1}
                                        position="relative"
                                        key={index}
                                        h={"100%"}
                                        onClick={() => openPortfolioItemModal(item)}
                                    >
                                        <Image
                                            src={item.images[0]}
                                            alt="portfolio item"
                                            objectFit="cover"
                                            w={"100%"}
                                            h={"100%"}
                                            borderRadius="xl"
                                            zIndex={1}
                                            overflow={"hidden"}
                                        />
                                        <Box
                                            position="absolute"
                                            bottom="0"
                                            left="0"
                                            w={"100%"}
                                            h={"100%"}
                                            bgGradient="linear(to-b, transparent, rgba(0, 0, 0, 0.5))"
                                            opacity="0"
                                            _hover={{ opacity: "1", cursor: "pointer", borderColor: "#A0B100" }}
                                            zIndex={2}
                                            display="flex"
                                            flexDirection="column"
                                            justifyContent="flex-end"
                                            borderRadius="xl"
                                            borderWidth={"2px"}
                                            borderColor={"oleniumBlack.100"}
                                        >
                                            <Text
                                                fontSize="lg"
                                                color="white"
                                                textAlign={"left"}
                                                mb={4}
                                                ml={4}
                                                fontWeight="bold"
                                            >
                                                {item.title}
                                            </Text>
                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        </>
                    }
                </>
            ) : ( provider_id === "N/A" ? (
                <Box p={{ base: 4, md: 8 }}>
                    <VStack
                        align="start"
                        justify={"center"}
                        spacing={6}
                        my={12}
                    >
                        <ExperienceBankLogoAnimated />
                        <Text
                        fontSize={{ base: "2xl", md: "5xl" }}
                        align={"left"}
                        fontWeight="900"
                        lineHeight={1.2}
                        overflow="visible"
                        color={"#A0B100"}
                        >
                            We're reviewing your requirements <br />before
                            assigning a mentor or consultant.
                        </Text>
                        <Text
                        fontSize={"lg"}
                        align={"left"}
                        maxW={"550px"}
                        fontWeight="400"
                        >
                            Your requirements are being reviewed by
                            our team. We'll send you an email once we've found the right person for you.
                        </Text>
                    </VStack>
                </Box>
            ) : (
                <Box textAlign="center">
                    <Spinner color="white" size="xl" />
                </Box>
            ))}

            {isPortfolioItemModalOpen && (
                <PortfolioItemModal
                    isOpen={isPortfolioItemModalOpen}
                    onClose={() => setIsPortfolioItemModalOpen(false)}
                    selectedPortfolioItem={selectedPortfolioItem}
                />
            )}
        </Box>
    );
};

export default SingleConsultantCard;
