import {
  Button,
  Center,
  Flex,
  Icon,
  Text,
  VStack,
  useColorModeValue
} from "@chakra-ui/react";
import { motion } from "framer-motion";
import React from "react";
import { FiArrowRight } from "react-icons/fi";

import { Link } from "react-router-dom";

function Error() {
  const boxColor = useColorModeValue("white", "oleniumBlack.600");
  const textColor = useColorModeValue("oleniumGray.500", "oleniumGray.400");
  const textColorAlt = useColorModeValue("oleniumGray.400", "oleniumGray.300");

  const textAnimationVariants = {
    hidden: { opacity: 0, y: -20 },
    visible: { opacity: 1, y: 0 },
  };

  return (
    <div>
      <Flex alignItems="center" gap="2" mt={10}>
        <Center w="100%" h="70vh" bg={boxColor} rounded="xl">
          <VStack spacing={4} w="100%" textAlign="center" maxW={"lg"}>
            <motion.div
              initial="hidden"
              animate="visible"
              variants={textAnimationVariants}
              transition={{ delay: 0.3, duration: 0.6 }}
            >
              <Text
                bgGradient="linear(to-l, blue.300, blue.500)"
                bgClip="text"
                fontSize={{ base: "6xl", sm: "7xl", md: "9xl" }}
                fontWeight="extrabold"
              >
                404
              </Text>
              <Text color={textColor} fontSize="2xl" fontWeight="bold">
                Page not found
              </Text>
              <Flex m={4} w={"100%"} textAlign="center" maxW={"lg"}>
                <Text color={textColorAlt} fontSize="md" fontWeight="medium">
                  The page you are looking for might have been removed, had its
                  name changed, or is temporarily unavailable.
                </Text>
              </Flex>
              <Button
                as={Link}
                to="/"
                variant="ExperienceBankGreen"
                rightIcon={<Icon as={FiArrowRight} />}
                size="sm"
              >
                Home
              </Button>
            </motion.div>
          </VStack>
        </Center>
      </Flex>
    </div>
  );
}

export default Error;
