import { Box, FormControl, FormLabel, Textarea, Text, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

const BioSection = ({ bio, setBio }) => {
  const [bioTouched, setBioTouched] = useState(false);

  useEffect(() => {
    const storedBio = localStorage.getItem("bio");
    if (storedBio) {
      setBio(storedBio);
    }
  }, [setBio]);

  return (
    <Box p={6} bg="oleniumBlack.700" rounded="lg" boxShadow="lg">
      <FormControl isRequired>
        <FormLabel fontSize="lg" fontWeight="bold" color="white">
          Tell us about your experience and career
        </FormLabel>
        <Text fontSize="sm" color="white" mb={4}>
          Please provide a brief summary of your professional background, areas of expertise, and key accomplishments in your career. This will help us match you with mentees looking for guidance in your field. (100-1000 characters)
        </Text>
        <Textarea
          value={bio}
          onChange={(e) => {
            setBio(e.target.value);
            setBioTouched(true);
            localStorage.setItem("bio", e.target.value);
          }}
          placeholder="Describe your career journey, expertise, and achievements..."
          height={200}
          rounded="lg"
          p={4}
          bg="oleniumBlack.600"
          border="1px solid"
          borderColor="gray.300"
          focusBorderColor="blue.400"
          mb={4}
        />
        {bioTouched && bio.length < 100 && (
          <Text fontSize="sm" color="red.500">
            Your bio must be at least 100 characters.
          </Text>
        )}
        {bioTouched && bio.length > 1000 && (
          <Text fontSize="sm" color="red.500">
            Your bio must not exceed 1000 characters.
          </Text>
        )}
      </FormControl>
    </Box>
  );
};

export default BioSection;
