import React, { useEffect, useState } from "react";
import {
    Box,
    Text,
    HStack,
    Avatar,
    Grid,
    GridItem,
    VStack,
    Heading,
    Tag,
    Flex,
    Stack,
    Divider,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Spinner,
    Image,
} from "@chakra-ui/react";
import axios from "axios";
import { getIdToken } from "../../../../cognito/cognitoAuth";
import PortfolioItemModal from '../../NewGoal/Forms/PortfolioModal';

const API_URL = process.env.REACT_APP_API_URL;

const ConsultantProfile = ({ id, provider }) => {
    const [selectedApplication, setSelectedApplication] = useState(null);
    const [isPortfolioItemModalOpen, setIsPortfolioItemModalOpen] = useState(false);
    const [selectedPortfolioItem, setSelectedPortfolioItem] = useState(null);

    const openPortfolioItemModal = (item) => {
        setSelectedPortfolioItem(item);
        setIsPortfolioItemModalOpen(true);
    };

    const getProviderDetails = async (providerId) => {
        if (providerId === "N/A") return;

        const idToken = await getIdToken();

        try {
            const applicationResponse = await axios.get(
                `${API_URL}/provider/${providerId}`,
                {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${idToken}`,
                    },
                }
            );
            const parsedResponse = JSON.parse(applicationResponse.data.body);
            setSelectedApplication(parsedResponse);
        } catch (error) {
            console.error("Error fetching provider details", error);
        }
    };

    useEffect(() => {
        getProviderDetails(id);
    }, [id]);

    return (
        <Box borderRadius="xl" bg="oleniumBlack.600" w="100%" pt={8} mb={4}>
            {selectedApplication ? (
                <>
                    <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)" }} gap={4}>
                        <GridItem>
                            <HStack spacing={4} mb={4} justify="space-between">
                                <Flex alignItems="center">
                                    <Avatar size="xl" name={`${provider.given_name} ${provider.family_name}`} src={provider.profile_picture_url} />
                                    <VStack alignItems="flex-start" spacing={2} ml={4}>
                                        <Text color="white" fontSize="xl" fontWeight="bold">
                                            {`${provider.given_name} ${provider.family_name}`}
                                        </Text>
                                        <Tag colorScheme="blue">Verified Consultant</Tag>
                                    </VStack>
                                </Flex>
                            </HStack>
                            <Text color="gray.400" my={8}>
                                {selectedApplication.profile?.bio || "No bio available."}
                            </Text>
                        </GridItem>
                        <GridItem>
                            <Box bg="oleniumBlack.700" borderRadius="xl" p={6}>
                                <Stack spacing={5}>
                                    <Heading as="h3" size="md" color="white">
                                        Experience
                                    </Heading>
                                    <Accordion allowToggle>
                                        {selectedApplication.experience && selectedApplication.experience.map((experience, idx) => (
                                            <AccordionItem key={idx}>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex="1" textAlign="left" color="white" fontWeight="bold">
                                                            {experience.title}
                                                        </Box>
                                                        <AccordionIcon />
                                                    </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={2} color="gray.400">
                                                    <HStack spacing={3} mb={2}>
                                                        <Tag colorScheme="blue">{experience.company}</Tag>
                                                        <Tag colorScheme="blue">{experience.location}</Tag>
                                                        <Tag colorScheme="blue">{experience.startDate} - {experience.present ? "Present" : experience.endDate}</Tag>
                                                    </HStack>
                                                    <Text>{experience.description}</Text>
                                                </AccordionPanel>
                                            </AccordionItem>
                                        ))}
                                    </Accordion>
                                </Stack>
                            </Box>
                        </GridItem>
                    </Grid>
                    {selectedApplication.portfolio_items?.length > 0 && (
                        <>
                            <Divider my={8} />
                            <Heading as="h3" size="md" color="white" mb={4}>Portfolio</Heading>
                            <Grid templateColumns={{ base: "1fr", md: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4}>
                                {selectedApplication.portfolio_items.map((item, idx) => (
                                    <GridItem key={idx} onClick={() => openPortfolioItemModal(item)}>
                                        <Box borderRadius="xl" overflow="hidden" cursor="pointer" position="relative">
                                            <Image src={item.images[0]} alt="portfolio item" objectFit="cover" w="100%" h="100%" />
                                            <Box position="absolute" bottom="0" w="100%" p={2} bgGradient="linear(to-b, transparent, rgba(0,0,0,0.7))">
                                                <Text fontSize="lg" color="white" fontWeight="bold">{item.title}</Text>
                                            </Box>
                                        </Box>
                                    </GridItem>
                                ))}
                            </Grid>
                        </>
                    )}
                </>
            ) : (
                <Box textAlign="center">
                    <Spinner color="white" size="xl" />
                </Box>
            )}

            {isPortfolioItemModalOpen && (
                <PortfolioItemModal
                    isOpen={isPortfolioItemModalOpen}
                    onClose={() => setIsPortfolioItemModalOpen(false)}
                    selectedPortfolioItem={selectedPortfolioItem}
                />
            )}
        </Box>
    );
};

export default ConsultantProfile;
