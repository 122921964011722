import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonGroup,
  Center,
  Divider,
  Flex,
  HStack,
  Heading,
  IconButton,
  Image,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tag,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  VStack,
  useDisclosure,
  Wrap,
  WrapItem,
  Grid,
  GridItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  ModalFooter,
} from "@chakra-ui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { useNavigate } from "react-router-dom";
import { getIdToken } from "../../../../cognito/cognitoAuth";

const API_URL = process.env.REACT_APP_API_URL;

const ProviderManager = () => {
  const navigate = useNavigate();
  const onBack = () => {
    navigate("/admin-panel");
  };

  const [applications, setApplications] = useState([]);
  const [selectedApplication, setSelectedApplication] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { isOpen: isApproveOpen, onOpen: onApproveOpen, onClose: onApproveClose } = useDisclosure();
  const { isOpen: isRejectOpen, onOpen: onRejectOpen, onClose: onRejectClose } = useDisclosure();
  const [actionLoading, setActionLoading] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serviceIdToNameMapping, setServiceIdToNameMapping] = useState({});
  const [pendingAction, setPendingAction] = useState(null);

  const fetchApplications = async () => {
    try {
      setLoading(true);
      const authentication = await getIdToken();
      const response = await axios.get(`${API_URL}/provider/all`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${authentication}`,
        },
      });

      console.log(response.data.body);


      if (response.data.statusCode === 200) {
        const parsedBody = JSON.parse(response.data.body);
        const parsedApplications = parsedBody.applications;

        if (Array.isArray(parsedApplications)) {
          const applicationsWithDetails = await Promise.all(
            parsedApplications.map(async (app) => {
              const userResponse = await fetch(
                `${API_URL}/users/info/${app.provider_id}`,
                {
                  method: "GET",
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${authentication}`,
                  },
                }
              );

              const userData = await userResponse.json();
              if (userData.statusCode === 200) {
                app.given_name = userData.body.given_name;
                app.family_name = userData.body.family_name;
                app.profile_picture_url = userData.body.profile_picture_url;
              }
              return app;
            })
          );
          setLoading(false);
          setApplications(applicationsWithDetails);
        }
      } else {
        setLoading(false);
        console.error("Error status code:", response.data.statusCode);
        setApplications([]);
      }
    } catch (error) {
      console.error("Error fetching applications:", error);
    }
    setLoading(false);
  };

  const fetchServices = async () => {
    try {
      const IdToken = await getIdToken();
      const response = await axios.get(`${API_URL}/services`, {
        headers: {
          Authorization: `Bearer ${IdToken}`,
          "Content-Type": "application/json",
        },
      });

      if (response.status === 200 && response.data.statusCode === 200) {
        const servicesData = JSON.parse(response.data.body);
        if (Array.isArray(servicesData)) {
          const idToName = {};
          servicesData.forEach((service) => {
            idToName[service.id] = service.name;
          });
          setServiceIdToNameMapping(idToName);
        } else {
          throw new Error("Failed to fetch services.");
        }
      } else {
        throw new Error("Failed to fetch services.");
      }
    } catch (error) {
      console.error("Error fetching services", error);
    }
  };

  useEffect(() => {
    fetchApplications();
    fetchServices();
  }, []);

  const viewApplication = (app) => {
    setSelectedApplication(app);
    onOpen();
  };

  const handleApprove = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/approve/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
        onApproveClose();
      }
    } catch (error) {
      console.error("Error approving application:", error);
    }
    setActionLoading(false);
  };

  const handleReject = async (userId) => {
    try {
      setActionLoading(true);
      const authentication = await getIdToken();
      const response = await axios.post(
        `${API_URL}/provider/reject/${userId}`,
        {},
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${authentication}`,
          },
        }
      );
      if (response.data.statusCode === 200) {
        setActionLoading(false);
        fetchApplications();
        onRejectClose();
      }
    } catch (error) {
      console.error("Error rejecting application:", error);
    }
    setActionLoading(false);
  };

  const confirmAction = (action, userId) => {
    setPendingAction({ action, userId });
    if (action === "approve") {
      onApproveOpen();
    } else {
      onRejectOpen();
    }
  };

  const convertTimeToDaysOld = (time) => {
    const now = new Date();
    const created = new Date(time);
    const diff = now.getTime() - created.getTime();
    const days = Math.floor(diff / (1000 * 60 * 60 * 24));

    if (days === 0) {
      return "Today";
    }
    if (days === 1) {
      return "Yesterday";
    }

    return days + " days ago";
  };

  const renderStatus = (status) => {
    switch (status) {
      case "APPROVED":
        return <Tag colorScheme="green">Approved</Tag>;
      case "REJECTED":
        return <Tag colorScheme="red">Rejected</Tag>;
      case "PENDING":
        return <Tag colorScheme="yellow">Pending</Tag>;
      default:
        return <Tag colorScheme="yellow">Unknown</Tag>;
    }
  };

  return (
    <Box>
      <Flex justifyContent="flex-start" mb={10}>
        <IconButton aria-label="Back" icon={<FaArrowLeft />} onClick={onBack} />
        <Heading as="h1" size="lg" ml={5} mt={1}>
          Mentors
        </Heading>
      </Flex>
      {!loading ? (
        <Table w={"100%"} variant="simple">
          <Thead>
            <Tr>
              <Th>User</Th>
              <Th>Submitted At</Th>
              <Th>Status</Th>
              <Th>Actions</Th>
            </Tr>
          </Thead>
          <Tbody w={"100%"}>
            {applications.map((app) => (
              <Tr key={app.provider_id}>
                <Td>
                  <HStack spacing={2}>
                    <Avatar
                      size="xs"
                      name={app.given_name + " " + app.family_name}
                      src={app.profile_picture_url}
                    />
                    <Text ml={1} fontSize="sm" fontWeight="bold" align={"left"}>
                      {app.given_name + " " + app.family_name}
                    </Text>
                  </HStack>
                </Td>
                <Td>{convertTimeToDaysOld(app.created_at)}</Td>
                <Td>{renderStatus(app.status)}</Td>
                <Td>
                  <HStack spacing={6}>
                    <ButtonGroup isAttached variant="outline">
                      <Button
                        colorScheme="green"
                        variant="outline"
                        onClick={() => confirmAction("approve", app.provider_id)}
                        isDisabled={app.status === "APPROVED"}
                        isLoading={actionLoading}
                      >
                        Approve
                      </Button>
                      <Button
                        colorScheme="red"
                        variant="outline"
                        onClick={() => confirmAction("reject", app.provider_id)}
                        isDisabled={app.status === "REJECTED"}
                        isLoading={actionLoading}
                      >
                        Reject
                      </Button>
                    </ButtonGroup>
                    <Button
                      variant="ExperienceBankGreen"
                      onClick={() => viewApplication(app)}
                    >
                      View
                    </Button>
                  </HStack>
                </Td>
              </Tr>
            ))}
          </Tbody>
        </Table>
      ) : (
        <Center>
          <Spinner size={"xl"} />
        </Center>
      )}
      <Modal isOpen={isOpen} onClose={onClose} size="6xl" scrollBehavior="inside">
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Application Details</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {selectedApplication && (
              <Grid templateRows="repeat(3, 1fr)" gap={6}>
                {/* Personal Info Row */}
                <GridItem>
                  <VStack spacing={4} alignItems="flex-start">
                    <Box>
                      <Heading size="md" mb={2}>Personal Info</Heading>
                      <Text><strong>Name:</strong> {selectedApplication.given_name} {selectedApplication.family_name}</Text>
                      <Text><strong>Created At:</strong> {new Date(selectedApplication.created_at).toLocaleString()}</Text>
                      <Text><strong>Identity Verified:</strong> {selectedApplication.identity_verified ? "Yes" : "No"}</Text>
                    </Box>
                    <Box>
                      <Heading size="md" mb={2}>Bio</Heading>
                      <Text>{selectedApplication.profile?.bio}</Text>
                    </Box>
                    <Box>
                      <Heading size="md" mb={2}>Selected Services</Heading>
                      <Wrap spacing={4} justify="flex-start">
                        {Object.entries(selectedApplication.selected_services || {}).map(
                          ([serviceId, serviceDetails]) => (
                            <WrapItem key={serviceId}>
                              <Box borderWidth="1px" borderRadius="md" p={3} backgroundColor="gray.700">
                                <Tag colorScheme="green" variant="solid" mb={2}>
                                  {serviceIdToNameMapping[serviceId] || serviceId}
                                </Tag>
                                <Wrap spacing={2} mt={2}>
                                  {serviceDetails.subTags.map((subTag, index) => (
                                    <WrapItem key={index}>
                                      <Tag colorScheme="blue" variant="outline">
                                        {subTag}
                                      </Tag>
                                    </WrapItem>
                                  ))}
                                </Wrap>
                              </Box>
                            </WrapItem>
                          )
                        )}
                      </Wrap>
                    </Box>
                  </VStack>
                </GridItem>

                {/* Experience Row */}
                <GridItem>
                  <VStack spacing={4} alignItems="flex-start">
                    <Heading size="md">Experience</Heading>
                    <Accordion allowMultiple width="100%">
                      {selectedApplication.experience.map((exp, index) => (
                        <AccordionItem key={index}>
                          <h2>
                            <AccordionButton>
                              <Box flex="1" textAlign="left">
                                {exp.title} at {exp.company}
                              </Box>
                              <AccordionIcon />
                            </AccordionButton>
                          </h2>
                          <AccordionPanel pb={4}>
                            <Text><strong>Location:</strong> {exp.location}, {exp.country}</Text>
                            <Text><strong>Duration:</strong> {exp.startDate} - {exp.endDate || "Present"}</Text>
                            <Text><strong>Description:</strong> {exp.description}</Text>
                          </AccordionPanel>
                        </AccordionItem>
                      ))}
                    </Accordion>
                  </VStack>
                </GridItem>

                {/* Portfolio Row */}
                <GridItem>
                  <VStack spacing={4} alignItems="flex-start">
                    <Heading size="md">Portfolio</Heading>
                    {selectedApplication.portfolio.map((item, index) => (
                      <Box key={index} width="100%" borderWidth="1px" borderRadius="lg" overflow="hidden">
                        <Carousel
                          showArrows={true}
                          showStatus={false}
                          infiniteLoop={true}
                          swipeable={true}
                          emulateTouch={true}
                        >
                          {item.images.map((image, imgIndex) => (
                            <Image
                              key={imgIndex}
                              src={image}
                              alt={`Portfolio item ${index + 1}`}
                              objectFit="cover"
                              height="200px"
                            />
                          ))}
                        </Carousel>
                        <Box p={4}>
                          <Heading size="sm" mb={2}>{item.title}</Heading>
                          <Text fontSize="sm" mb={2}>{item.description}</Text>
                          <Wrap>
                            {item.tags.map((tag, tagIndex) => (
                              <WrapItem key={tagIndex}>
                                <Tag size="sm" colorScheme="blue" variant="outline">
                                  {tag}
                                </Tag>
                              </WrapItem>
                            ))}
                          </Wrap>
                        </Box>
                      </Box>
                    ))}
                  </VStack>
                </GridItem>
              </Grid>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal isOpen={isApproveOpen} onClose={onApproveClose} isCentered>
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Confirm Approval</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to approve this application? An email will be sent to the mentor confirming this decision.</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onApproveClose}>
              Cancel
            </Button>
            <Button onClick={() => handleApprove(pendingAction.userId)} variant="ExperienceBankGreen">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>

      <Modal isOpen={isRejectOpen} onClose={onRejectClose}>
        <ModalOverlay />
        <ModalContent background="oleniumBlack.600" color="white">
          <ModalHeader>Confirm Rejection</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Text>Are you sure you want to reject this application? An email will be sent to the mentor confirming this decision.</Text>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onRejectClose}>
              Cancel
            </Button>
            <Button onClick={() => handleReject(pendingAction.userId)} variant="ExperienceBankGreen">
              Confirm
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default ProviderManager;
