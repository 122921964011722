import { Box, Flex, HStack, Text, useColorModeValue } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

function LinkButton(props) {
  let location = useLocation();
  let [currentRoute, setCurrentRoute] = useState(location.pathname);

  useEffect(() => {
    setCurrentRoute(location.pathname);
  }, [location.pathname]);

  let activeBg = useColorModeValue("white", "#A0B100");
  let inactiveBg = useColorModeValue("oleniumGray.100", "oleniumBlack.300");
  let activeColorIcon = useColorModeValue("#A0B100", "white");
  let activeColor = useColorModeValue("white", "white");
  let inactiveColor = useColorModeValue("oleniumGray.600", "oleniumGray.400");
  let sidebarActiveShadow = "0px 7px 11px rgba(0, 0, 0, 0.08)";

  let variantChange = "0.5s easeInOut";

  let buttonBgActive = useColorModeValue("#0071e3", "#303236");
  let buttonText = useColorModeValue("black", "white");
  let projectDetailCheck = false;

  useEffect(() => {
    let path = props.route;
    projectDetailCheck = path.includes("/projects/project/");
  }, [props.route]);


  return (
    <div>
      <NavLink to={props.route}>
        <Box
          p={1}
          bg={props.route === currentRoute ? buttonBgActive : "transparent"}
          shadow={props.route === currentRoute ? sidebarActiveShadow : "none"}
          borderRadius="12px"
          transition={variantChange}
        >
          <HStack spacing="14px" py={2} px="14px">
            <Flex
              bg={props.route === currentRoute ? activeBg : inactiveBg}
              color={
                props.route === window.location.pathname
                  ? activeColorIcon
                  : inactiveColor
              }
              h="30px"
              w="30px"
              transition={variantChange}
              align="center"
              justifyContent="center"
              borderRadius="12px"
              p={2}
            >
              {props.icon}
            </Flex>
            {props.isOpen && (
              <Text
                color={
                  props.route === currentRoute ? activeColor : inactiveColor
                }
                fontWeight={"bold"}
                fontSize="sm"
                py={1}
                whiteSpace="nowrap"
                transition={variantChange}
                transitionDelay="3s" // Add this line for the delay
              >
                {props.name}
              </Text>
            )}
          </HStack>
        </Box>
      </NavLink>
    </div>
  );
}

export default LinkButton;
