import React from "react";
import { useContext } from "react";
import { UserContext } from "../../../../UserContext";
import { Box, Flex, Heading, Image, VStack, HStack, Tag, Divider, Icon, Wrap, WrapItem, Button, Text } from "@chakra-ui/react";
import { IoSparkles } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import image from "../../../../assets/images/eb-logo.png";

const Project = ({ project }) => {
    const textColor = "white";
    const { userDetails } = useContext(UserContext);
    const navigate = useNavigate();

    // Function to render sub-tags as Tag components
    const renderSubTags = (subTags = []) => {
        if (!Array.isArray(subTags) || subTags.length === 0) return null;
        return (
            <Wrap>
                {subTags.map((tag, index) => (
                    <WrapItem key={index}>
                        <Tag colorScheme="blue" size="sm" variant="solid" borderRadius="full">
                            {tag}
                        </Tag>
                    </WrapItem>
                ))}
            </Wrap>
        );
    };

    // Check if the project is associated with an enterprise account
    const checkIfEnterpriseUser = () => {
        const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}$/i;
        const enterpriseId = project.enterprise_id;
        return uuidRegex.test(enterpriseId) && enterpriseId !== "N/A";
    };

    // Check if the current user is the client
    const checkIfUserIsClient = () => {
        return project.user_id === userDetails.id;
    };

    // Navigate to the booking tab
    const linkToBookingTab = () => {
        navigate(`/project/${project.id}/meetings`);
    };

    // Render all services with their icons, descriptions, and sub-tags
    const renderServices = () => {
        if (!Array.isArray(project.services) || project.services.length === 0) return null;
    
        return project.services.map((service, index) => {
    
            return (
                <Flex key={index} mb={6} bg="oleniumBlack.700" py={5} px={2} borderRadius="xl" alignItems="center">
                    <VStack align="start" spacing={2} ml={6} flex={1}>
                        <Heading size="md">{service.name}</Heading>
                        {renderSubTags(service.sub_tags)}
                        <Text fontSize="sm" color="gray.400">{service.description}</Text>
                    </VStack>
                </Flex>
            );
        });
    };
    

    return (
        <Box maxW="2xl" bg="oleniumBlack.600" p={6} borderRadius="xl" mx={"auto"}>

            {/* Render all services */}
            {renderServices()}

            {checkIfEnterpriseUser() && (
                <Box bg="oleniumBlack.700" p={4} borderRadius="lg" mt={6}>
                    <Text color="white" mb={2} fontSize="lg" fontWeight="bold">Enterprise Account</Text>
                    <Text color="white" fontSize="sm">This project has been associated with an enterprise account and has been granted meeting hours to use for this project.</Text>
                    {checkIfUserIsClient() && project.status === "IN_PROGRESS" && (
                        <Button mt={4} onClick={linkToBookingTab} variant={"ExperienceBankGreen"} size={"sm"}>
                            Book a Meeting
                        </Button>
                    )}
                </Box>
            )}

            <Box bg="oleniumBlack.700" p={{ base: 4, md: 6 }} borderRadius="lg" mt={6}>
                <Text color="white" fontSize="lg" fontWeight="bold">Project Details</Text>
                <Divider my={4} />

                <Text color="white" fontSize="md" mt={4} fontWeight="bold">Reason for Joining</Text>
                <Text color="white" fontSize="sm" mt={2}>{project.reason_for_joining || "No reason provided."}</Text>

                <Divider my={4} />        

                <HStack mt={4}>
                    <Text color="white" fontSize="md" fontWeight="bold">Summary</Text>
                    <Tag
                        size="xs"
                        variant="outline"
                        borderRadius="full"
                        px={4}
                        py={1}
                        fontWeight="bold"
                        fontSize="xs"
                        letterSpacing="wide"
                        color={textColor}
                        background={"whiteAlpha.300"}
                        >
                        <Icon as={IoSparkles} color="yellow.400" mr={2} />
                        AI Generated
                    </Tag>
                </HStack>
                <Text color="white" fontSize="sm" mt={2}>{project.problem_statement}</Text>
            </Box>
        </Box>
    );
};

export default Project;
