import { extendTheme } from "@chakra-ui/react";
import { StepsTheme as Steps } from "chakra-ui-steps";

const config = {
  initialColorMode: "dark",
  useSystemColorMode: false,
};

const theme = extendTheme({
  components: {
    Steps,
    Button: {
      // Change the color scheme for the primary button
      variants: {
        ExperienceBankGreen: {
          bg: "#A0B100",
          color: "white",
          _hover: {
            bg: "#586100",
          },
          _active: {
            bg: "#A0B100",
          },
        },
        oleniumRed: {
          bg: "#F83E3E",
          color: "white",
          _hover: {
            bg: "#E12020",
          },
          _active: {
            bg: "#F83E3E",
          },
        },
        oleniumOrange: {
          bg: "#FFA500",
          color: "white",
          _hover: {
            bg: "#D38200",
          },
          _active: {
            bg: "#D38200",
          },
        },
      },
    },

    Badge: {
      // Change the color scheme for the primary button
      variants: {
        ExperienceBankGreen: {
          bg: "#A0B100",
          rounded: "full",
          color: "white",
          _hover: {
            bg: "#586100",
          },
          _active: {
            bg: "#A0B100",
          },
        },
        ExperienceBankGreenOutline: {
          bg: "transparent",
          border: "1px solid #A0B100",
          rounded: "full",
          color: "white",
          _hover: {
            bg: "#586100",
          },
          _active: {
            bg: "#A0B100",
          },
        },
      },
    },
    Switch: {
      // Change the color scheme for the primary button
      variants: {
        ExperienceBankGreen: {
          bg: "#A0B100",
          color: "white",
        },
      },
    },
    Tag: {
      variants: {
        ExperienceBankGreen: {
          bg: "#A0B100",
          color: "white",
          rounded: "full",
        },
      },
    },
    Progress: {
      variants: {
        ExperienceBankGreen: {
          bg: "#A0B100",
          rounded: "full",
        },
        oleniumGreen: {
          bg: "#1bef93",
          rounded: "full",
        },
      },
    },
  },
  config,
  colors: {
    // Define custom background colors for dark and light modes
    bg: {
      dark: "#151718",
      light: "#ffffff",
    },
    oleniumBlack: {
      100: "#545454",
      200: "#494949",
      300: "#3F3F3F",
      400: "#343434",
      500: "#2C2C2C",
      600: "#1F1F1F",
      700: "#171717",
      800: "#101010",
      900: "#000000",
    },

    oleniumGray: {
      100: "#FAFAFA",
      200: "#F2F2F2",
      300: "#E8E8E8",
      400: "#DCDCDC",
      500: "#C3C3C3",
      600: "#B0B0B0",
      700: "#9D9D9D",
      800: "#8E8E8E",
      900: "#7C7C7C",
    },

    ExperienceBankGreen: {
      100: "A0B100",
      200: "#3182CE",
      300: "#2B6CB0",
      400: "#2C5282",
      500: "#2A4365",
      600: "#1A365D",
      700: "#153E75",
      800: "#2B6CB0",
      900: "#2C5282",
    },

    gray: {
      100: "#F7FAFC",
      200: "#EDF2F7",
      300: "#E2E8F0",
      400: "#CBD5E0",
      500: "#A0AEC0",
      600: "#718096",
      700: "#4A5568",
      800: "#2D3748",
      900: "#1A202C",
    },
    blue: {
      100: "#EBF8FF",
      200: "#BEE3F8",
      300: "#90CDF4",
      400: "#63B3ED",
      500: "#A0B100",
      600: "#3182CE",
      700: "#2B6CB0",
      800: "#2C5282",
      900: "#2A4365",
    },
    red: {
      100: "#FFF5F5",
      200: "#FED7D7",
      300: "#FEB2B2",
      400: "#FC8181",
      500: "#F56565",
      600: "#E53E3E",
      700: "#C53030",
      800: "#9B2C2C",
      900: "#742A2A",
    },
    orange: {
      100: "#FFFAF0",
      200: "#FEEBC8",
      300: "#FBD38D",
      400: "#F6AD55",
      500: "#ED8936",
      600: "#DD6B20",
      700: "#C05621",
      800: "#9C4221",
      900: "#7B341E",
    },
    yellow: {
      100: "#FFFFF0",
      200: "#FEFCBF",
      300: "#FAF089",
      400: "#F6E05E",
      500: "#ECC94B",
      600: "#D69E2E",
      700: "#B7791F",
      800: "#975A16",
      900: "#744210",
    },
    green: {
      100: "#F0FFF4",
      200: "#C6F6D5",
      300: "#9AE6B4",
      400: "#68D391",
      500: "#48BB78",
      600: "#38A169",
      700: "#2F855A",
      800: "#276749",
      900: "#22543D",
    },
  },

  fonts: {
    // Use Helvetica as the font
    heading: "Helvetica, sans-serif",
    body: "Helvetica, sans-serif",
  },
  styles: {
    global: (props) => ({
      // Set the background color based on the current color mode
      "html, body": {
        backgroundColor: props.colorMode === "dark" ? "#151718" : "#ffffff",
        fontFamily: "body",
      },
      a: {
        _hover: {
          textDecoration: "none",
        },
      },
    }),
  },
});

export default theme;
